import React, { Component } from 'react';
import { Link, NavLink} from 'react-router-dom';
import '../../common/restaurant.scss';
import {getListData} from '../../../services/index';
import $ from 'jquery';
import {Redirect} from 'react-router-dom';
import { Item} from "../../layouts/home/components";
import Carousel from "../../layouts/home/Carousel";
import Slider from 'react-slick';


class Home extends Component {
	constructor(){
		super();
		var storedArray = JSON.parse(localStorage.getItem("userData"));
		this.state = {
			user_name: '',
			mobile: '',
			email: '',
			package: '',
			user_id: storedArray['data']['user_id'],
			
		};
		this.addadress = this.addadress.bind(this);
		this.onChange = this.onChange.bind(this);
		this.handleChange = this.handleChange.bind(this);
	}
	handleChange(event) {
	  this.setState({
		package: event.target.value
	  });
	}
	onChange(e){
		this.setState({[e.target.name]:e.target.value});
	}
	addadress() {
		if(this.state.user_name || this.state.mobile || this.state.email){
			
			getListData('auth/fitness_enquiry',this.state).then((result) => {
			if(result.status == 300){
				document.getElementById('alert').innerHTML='<div class="alert alert-warning" role="alert"><i class="fa fa-info-circle" aria-hidden="true"></i> Please insert all fields</div>';
			}
			else{
				document.getElementById('alert').innerHTML='';
				this.setState({redirectToReferrer: true});
			}
			
			});
		}
		if(this.state.user_name === '' && this.state.mobile === '' && this.state.email === ''){
			document.getElementById('alert').innerHTML='<div class="alert alert-warning" role="alert"><i class="fa fa-info-circle" aria-hidden="true"></i> Please insert all fields </div>';
		}
		else{
			document.getElementById('alert').innerHTML='';
		}
	}
	componentDidMount() {
        document.getElementById("color").setAttribute("href", `#` );		
		document.querySelector(".loader-wrapper").style = "display: block";
		setTimeout(function() {
		document.querySelector(".loader-wrapper").style = "display: none";
	}, 900);
		

			if (localStorage.getItem('userData')){
				var storedArray = JSON.parse(localStorage.getItem("userData"));
			}
    }

	render() {
	   if (this.state.redirectToReferrer){
			return (<Redirect to={`${process.env.PUBLIC_URL}/`}/>)
		}
	
		return (
			<div>
                
				
                 
                {/*Home Section End*/}

                {/*collection banner*/}
                <section className="pb-0 margin-pd mt-45 restro">
					<div className="_2u0oK _1iY8Y">
						<form>
						   <div className="_3TzfO page_head">
							  <div className="_2QN4q">
								 <Link to={`${process.env.PUBLIC_URL}/`}>
								 <img src={`${process.env.PUBLIC_URL}/assets/images/icon/return_white.png`} alt="" /></Link>
							  </div>
							  <div className="_1leRb page_title">
								 Pathology Lab Test
							  </div>
							  <div className="b5NJg"></div>
						   </div>
						   <input type="submit" hidden=""/>
						</form>
					 </div>
				    
                </section>
				
				 {/*collection banner*/}
                <section className="pb-0 margin-pd restro details-pad-top-n">
				    
                    <div className="add-details details-padding gym-pad-bot" role="button" tabIndex="0">
						<div className="res-details-info col-md-12">
							<div class="collection-banner p-left text-left">
								<img src={`${process.env.PUBLIC_URL}/assets/images/labs/neulife.jpg`} alt="" class="img-fluid blur-up lazyload bg-img" />
								<div class="absolute-contain">
									<h3>neulife Healthcare Center</h3>
									<h4>X-Ray-Sonography-Pathology-Polyclinic</h4>
								</div>
							</div>
							
						</div>
					</div>
                </section>

				<section className="pb-0 margin-pd mt-10 restro details-pad-top-n cp">
				    <div class="row">
                    <div class="col-6 col-lg-4 col-xl-3 pr-2">
						<div class="card mb-3 border-0 rounded-0 gradient-primary">
						<Link to={`${process.env.PUBLIC_URL}/all_test`}>
							<div class="card-body">
								<div class="media">
									<div class="media-body">
										<div class="dashboard-img">
											<img src={`${process.env.PUBLIC_URL}/assets/images/icon/all_test.png`} alt="" />
										</div>
										<p class="mb-0">All Tests</p>
									</div>
								</div>
							</div>
							</Link>
						</div>
					</div>					
					<div class="col-6 col-lg-4 col-xl-3 pl-2">
						<div class="card mb-3 border-0 rounded-0 gradient-primary">
						<Link to={`${process.env.PUBLIC_URL}/health_package`}>
							<div class="card-body">
								<div class="media">
									<div class="media-body">
										<div class="dashboard-img"><img src={`${process.env.PUBLIC_URL}/assets/images/icon/health_package.png`} alt="" /></div>
										<p class="mb-0">Health Packages</p>
									</div>
								</div>
							</div>
							</Link>
						</div>
					</div>

					<div class="col-6 col-lg-4 col-xl-3 pr-2">
						<div class="card mb-3 border-0 rounded-0 gradient-primary">	
						<Link to={`${process.env.PUBLIC_URL}/upload_prescription`}>
							<div class="card-body">
								<div class="media">
									<div class="media-body">
										<div class="dashboard-img"><img src={`${process.env.PUBLIC_URL}/assets/images/icon/prescription.png`} alt="" /></div>
										<p class="mb-0">Upload Prescription</p>
									</div>
								</div>
							</div>	
							</Link>							
						</div>
					</div>		

					
				</div>
                </section>
				
				
				


 
			</div>
			
			)

	}
}

export default Home;
