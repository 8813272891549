import React, { Component } from 'react';
import { Link, NavLink} from 'react-router-dom';

class HeaderOne extends Component {
    constructor(props) {
        super(props);
    }
    componentDidMount() {
       
    }	
	render() {

		return (
			<div>
				

			</div>
			)
	}
}

export default HeaderOne;