import React, { Component } from 'react';
import { Link, NavLink} from 'react-router-dom';
import '../../common/restaurant.scss';
import {getListData} from '../../../services/index';
import $ from 'jquery';
import {Redirect} from 'react-router-dom';
import { Item} from "../../layouts/home/components";
import Carousel from "../../layouts/home/Carousel";
import Slider from 'react-slick';
import { Card } from 'antd';
const { Meta } = Card;


class Home extends Component {
	constructor(){
		super();
		var storedArray = JSON.parse(localStorage.getItem("userData"));
		this.state = {
			user_name: '',
			mobile: '',
			email: '',
			package: '',
			ListData: [],
			user_id: storedArray['data']['user_id'],
			
		};
	
		this.onChange = this.onChange.bind(this);
		this.handleChange = this.handleChange.bind(this);
	}
	handleChange(event) {
	  this.setState({
		package: event.target.value
	  });
	}
	onChange(e){
		this.setState({[e.target.name]:e.target.value});
	}
	
	componentDidMount() {
        document.getElementById("color").setAttribute("href", `#` );		
		document.querySelector(".loader-wrapper").style = "display: block";
	setTimeout(function() {
    document.querySelector(".loader-wrapper").style = "display: none";
		}, 900);
		
		

			if (localStorage.getItem('userData')){
				var storedArray = JSON.parse(localStorage.getItem("userData"));
			}
			
			let package_id = this.props.match.params.id;
			this.state.package_id = package_id;
			this.state.user_id=storedArray['data']['user_id'];
			var user_id = storedArray['data']['user_id'];
			window.showAndroidToast(user_id);
			getListData('travel/package_details', this.state).then((result) => {
				let responseJson = result;
				if (responseJson.data) {
					if (result['count'] > 0) {
						this.setState({
							ListData: result['data']
						})
					}
				}
			});
			
			
		
    }

	render() {
	if (this.state.redirectToReferrer){
			return (<Redirect to={`${process.env.PUBLIC_URL}/`}/>)
		}
		const settings = {
			dots: true,
			infinite: true,
			speed: 300,
			slidesToShow: 1,
			centerMode: true,
			variableWidth: true
		  };	
		return (
			<div>
                
				
                 
                {/*Home Section End*/}

                {/*collection banner*/}
				
				{this.state.ListData.map((list) => (
				<div>
                <section className="pb-0 margin-pd mt-45 restro">
					<div className="_2u0oK _1iY8Y">
						<form>
						   <div className="_3TzfO page_head">
							  <div className="_2QN4q">
								 <Link to={`${process.env.PUBLIC_URL}/tours_travels`}>
								 <img src={`${process.env.PUBLIC_URL}/assets/images/icon/return_white.png`} alt="" /></Link>
							  </div>
							  <div className="_1leRb page_title">
								 {list.travel_name}
							  </div>
							  <div className="b5NJg"></div>
						   </div>
						   <input type="submit" hidden=""/>
						</form>
					 </div>
				    
                </section>
				
				
				 {/*collection banner*/}
                <section className="pb-0 margin-pd restro details-pad-top-n">
				    
                    <div className="add-details details-padding gym-pad-bot" role="button" tabIndex="0">
						<div className="res-details-info col-md-12">
							<div class="collection-banner p-left text-left">
								<img src={`${list.package_image}`} alt="" class="img-fluid blur-up lazyload bg-img" />
								<div class="absolute-contain">
									<h3>{list.package_name}</h3>
								</div>
							</div>
							
						</div>
					</div>
                </section>
				<section className="pb-0 margin-pd mt-10 restro details-pad-top-n">
                    <div className="add-details details-padding" role="button" tabIndex="0">
						<div className="res-details-info col-md-12">
							<div className="col-md-12  no-pad">
							<h3 className="nowrap restro _14v1V details-title-col"> Package Details</h3>
								<div className="details-color "> {list.package_details}</div>
							</div>
						</div>
					</div>
                </section>
				<section className="pb-0 margin-pd mt-10 restro details-pad-top-n">
                    <div className="add-details details-padding" role="button" tabIndex="0">
						<div className="res-details-info col-md-12">
							<div className="col-md-12  no-pad">
							 <h3 className="nowrap restro _14v1V details-title-col"> Days</h3> 
								<div className="details-color ">{list.days}</div>
							</div>
						</div>
					</div>
                </section>
				<section className="pb-0 margin-pd mt-10 restro details-pad-top-n mb-110">
                    <div className="add-details details-padding" role="button" tabIndex="0">
						<div className="res-details-info col-md-12">
							<div className="col-md-12  no-pad">
							<h3 className="nowrap restro _14v1V details-title-col"> Price</h3> 
							<div className="details-color fs-26"><b>Rs. {list.price}</b></div> 
							
							</div>
						</div>
					</div>
                </section>
				<div className={`float-cart center ${this.state.openCart}`} id="float-cart" style={{display: "block"}}>
				 <Link className="text-white" to={`${process.env.PUBLIC_URL}/package_enquiry/${list.package_id}`}>
						  <span class="">Send Enquiry <i class="si si-basket"></i></span>
						  <canvas height="0" width="0" class="canvas"></canvas></Link>
				 </div>
				
				
		</div>
))}
 
			</div>
			
			)

	}
}

export default Home;
