import React, {Component} from 'react';
import { Link, NavLink} from 'react-router-dom';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from 'react-loader-spinner';
import {getData} from '../../../services/index';

class InternetPlans extends Component {
	constructor () {
		super() ;
		this.state = {
			modal: false,
			nestedModal: false,
			closeAll: false,
			plans: [],
			ListData1 : []
		};
		this.toggle = this.toggle.bind(this);
		this.toggleNested = this.toggleNested.bind(this);
		this.toggleAll = this.toggleAll.bind(this);	
	    if(!localStorage.getItem('userData')) {
		   this.props.history.push("login");
        }		
	}

	componentDidMount() {
		document.querySelector(".loader-wrapper").style = "display: block";
	setTimeout(function() {
    document.querySelector(".loader-wrapper").style = "display: none";
}, 900);
		fetch('https://liveapi.classicxtra.com/auth/internet_plans')
			.then((response) => response.json())
			.then((data) => {
			this.setState({ 
			plans: data['data']})
			//document.getElementById("loader").style = "display: none";
			//console.log(this.state.plans)
		})
		.catch(console.log)
		
		getData('auth/classic_plan_list', this.state).then((result) => {
			let responseJson = result;
			if (responseJson.data) {
				this.setState({
					ListData1: result['data']
				})
			}
		});
	}
	


	toggle() {
			this.setState(prevState => ({
			modal: !prevState.modal
		}));
	}

	toggleNested() {
		this.setState({
			nestedModal: !this.state.nestedModal,
			closeAll: false
		});
	}

	toggleAll() {
		this.setState({
			nestedModal: !this.state.nestedModal,
			closeAll: true
		});
	}
  
render (){


return (




<div>

   <section className="authentication-page section-b-space margin-pd">
      <div className="_3lldG pt-20">
         <div className="_2u0oK _1iY8Y border-blue">
            <form>
               <div className="_3TzfO page_head">
                  <div className="_2QN4q">
                     <Link to={`${process.env.PUBLIC_URL}/renew_plan`}>
                     <img src={`${process.env.PUBLIC_URL}/assets/images/icon/return_white.png`} alt="" /></Link>
                  </div>
                  <div className="_1leRb page_title">
                     Select Plan
                  </div>
                  <div className="b5NJg"></div>
               </div>
               <input type="submit" hidden=""/>
            </form>
         </div>
         <div className="_3mMtt dashboard m-plan">	
		  
		        <section className="renew-tab tab-product m-0">
                <div className="row">
                    <div className="col-sm-12 col-lg-12">
                        <div className="tab-content nav-material">                     
                            <div className="tab-pane fade mt-4 show active">
							<div className="tab-m-header"> 
								<p>Select Plan from Plan List</p>
							</div>
							
							<div id="plan_list">
							<div id="loader"></div>
							{this.state.ListData1.map((plan) => (							
								<div class="plans_card">
								<div class="card rounded-0 border-0 text-center pricing mb-3">
									<div class="card-body">	

									{(() => {
										if (plan.extra_data !='') {
										return (
											<div>
											<div class="box">
											<div class="ribbon ribbon-bottom-right"><span>{plan.extra_data}</span></div></div>
												
												</div>
											)
										}
									})()}

										<h4 class="plan-title">
										<span class="f-left">{plan.plan_name}</span>
										<span class="f-right"><i class="fa fa-inr" aria-hidden="true"></i> {plan.plan_rate}</span>
										</h4>	
										<table className="table  mb-0 table-plan">
											<tbody> 								
											<tr>
												<th><i className="fa fa-tachometer" aria-hidden="true"></i> Speeds</th>
												<td><i className="fa fa-download" aria-hidden="true"></i> upto {plan.plan_speed}</td>
											</tr>
											<tr>
												<th><i className="fa fa-adjust" aria-hidden="true"></i> Limit</th>
												<td>{plan.plan_limit}</td>
											</tr>
											<tr>
												<th><i className="fa fa-calendar-check-o" aria-hidden="true"></i> Validity</th>
												<td>{plan.plan_validity}</td>
											</tr> 
											<tr>

												{(() => {
													if (plan.plan_note !='' || plan.installation_charges !='') {
													return (
														<td colSpan="2" class="left">Note : {plan.installation_charges} <br/> {plan.plan_note}</td>
														
														)
													}
												})()}
												
											</tr> 
											
											 <tr>												
												<td colSpan="2" class="text-center">
												<button class="btn btn-primary mb-3" onClick={this.toggle}><i className="fa fa-inr" aria-hidden="true"></i>{plan.plan_rate}</button></td>
											</tr> 
											
											</tbody>
										</table>
									</div>
								
								</div>
							</div>
							
							))}
							</div>	


	
        <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className} backdrop={this.state.backdrop}>
          <ModalHeader toggle={this.toggle}>Are You Sure, Want to Change the Plan?</ModalHeader>        
          <ModalFooter>
            <Button color="primary" onClick={this.toggle}>Yes</Button>{' '}
            <Button color="secondary" onClick={this.toggle}>No</Button>
          </ModalFooter>
        </Modal>
							
				               
                            </div>                            
                        </div>
                    </div>
                </div>
            </section>
		 
		 
			
         </div>
      </div>
   </section>
</div>
)
}
}
export default InternetPlans