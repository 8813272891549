import React, { Component } from 'react';
import { Link, NavLink} from 'react-router-dom';
import {getListData} from '../../../services/index';
import '../../common/restaurant.scss';
import $ from 'jquery';
import {Redirect} from 'react-router-dom';
import {connect} from 'react-redux';




class Home extends Component {
	constructor(){
		super();
		var storedArray = JSON.parse(localStorage.getItem("userData"));
		this.state = {
			ListData: [],
			user_name: '',
			mobile: '',
			email: '',
			package: '',
			user_id: storedArray['data']['user_id'],
			openCart: '',
			countItem: 0,
			countTotal: 0,
			qty: 0,
			openQuantity: false,
			quantity: 1,
			
		};
		this.add_cart = this.add_cart.bind(this);
		this.minus_cart = this.minus_cart.bind(this);
		this.onChange = this.onChange.bind(this);
		this.handleChange = this.handleChange.bind(this);
	}
	handleChange(event) {
	  this.setState({
		package: event.target.value
	  });
	}
	onChange(e){
		this.setState({[e.target.name]:e.target.value});
	}
	
	componentDidMount() {
					document.getElementById("color").setAttribute("href", `#` );		
					document.querySelector(".loader-wrapper").style = "display: block";
				setTimeout(function() {
				document.querySelector(".loader-wrapper").style = "display: none";
			}, 900);
		
		
			
			if (localStorage.getItem('userData')){
				var storedArray = JSON.parse(localStorage.getItem("userData"));
			}
			this.getData();
    }
	
	getData = () => {
		var storedArray = JSON.parse(localStorage.getItem("userData"));
		
		this.state.user_id=storedArray['data']['user_id'];
		getListData('beautyparlour/item_list', this.state).then((result) => {
		let responseJson = result;
		if (responseJson.data) {
			if (result['count'] > 0) {
				this.setState({
					ListData: result['data']
				})
			}
		}
		});
    }
	
	minusQty = (id) => {
        if(this.state.quantity > 1) {
            this.setState({quantity: this.state.quantity - 1});
			this.minus_cart(id);
        }else{
            console.log('removefromcart');			
            this.setState({openQuantity:false});		
			this.minus_cart(id);
        }
    }

    plusQty = (id) => {
        this.setState({quantity: this.state.quantity+1});
		this.plus_cart(id);
    }

    openQuantity = (id) => {
        this.setState({openQuantity:true});
		this.add_cart(id);
    }
	
	add_cart(id) {
		var storedArray = JSON.parse(localStorage.getItem("userData"));
        var item_id = document.getElementById("item_id"+id).value;
        var item_price = document.getElementById("item_price"+id).value;
        var item_qty = document.getElementById("item_qty"+id).value;
		var app = {
			user_id: storedArray['data']['user_id'],
			item_id: item_id,
			item_qty: item_qty,
			item_price: item_price
		}
		getListData('beautyparlour/add_cart', app).then((result) => {
			this.getData();
			if (result.data.length>0) {
				for(var i=0;i<result.data.length;i++)
				{
					document.getElementById("float-cart").style = 'display: block';
					if(result.data[i].count>0)
					{
						document.getElementById("float-cart").style = 'display: block';
					}
					else
					{
						document.getElementById("float-cart").style = 'display: none';
					}
					this.state.countItem=result.data[i].count;
					this.state.countTotal=result.data[i].total;
					document.getElementById("count_item").innerHTML = result.data[i].count+' Items';
					document.getElementById("count_total").innerHTML = 'Rs. '+result.data[i].total;
				}				
				document.getElementById("chk_"+item_id).classList.add("open");
			}
			else{
				document.getElementById("count_item").innerHTML = '0 Items';
				document.getElementById("count_total").innerHTML = 'Rs. 0';
				document.getElementById("float-cart").style = 'display: none';
			}
		});
		this.getData();
	}
	
	
	minus_cart(id) {
		var storedArray = JSON.parse(localStorage.getItem("userData"));
			
        var item_id = document.getElementById("item_id"+id).value;
        var item_price = document.getElementById("item_price"+id).value;
        var item_qty = document.getElementById("item_qty"+id).value;
		var app = {
			user_id: storedArray['data']['user_id'],
			item_id: item_id,
			item_qty: parseInt(item_qty)-1,
			item_price: item_price
		}
		getListData('beautyparlour/cart_update', app).then((result) => {
			this.getData();
			if (result.data.length>0) {
				for(var i=0;i<result.data.length;i++)
				{
					document.getElementById("float-cart").style = 'display: block';
					if(result.data[i].count>0)
					{
						document.getElementById("float-cart").style = 'display: block';
					}
					else
					{
						document.getElementById("float-cart").style = 'display: none';
					}
					this.state.countItem=result.data[i].count;
					this.state.countTotal=result.data[i].total;
					document.getElementById("count_item").innerHTML = result.data[i].count+' Items';
					document.getElementById("count_total").innerHTML = 'Rs. '+result.data[i].total;
				}
			}
			else{
				document.getElementById("count_item").innerHTML = '0 Items';
				document.getElementById("count_total").innerHTML = 'Rs. 0';
				document.getElementById("float-cart").style = 'display: none';
			}
		});
		this.getData();
	}

	render() {
	if (this.state.redirectToReferrer){
			return (<Redirect to={`${process.env.PUBLIC_URL}/`}/>)
		}
		const settings = {
			dots: true,
			infinite: true,
			speed: 300,
			slidesToShow: 1,
			centerMode: true,
			variableWidth: true
		  };	
		return (
			<div>
                
			{this.state.ListData.map((lis) => (	
                 
                <div>
				
                <section className="pb-0 margin-pd mt-45 restro">
					<div className="_2u0oK _1iY8Y">
						<form>
						   <div className="_3TzfO page_head">
							  <div className="_2QN4q">
								 <Link to={`${process.env.PUBLIC_URL}/`}>
								 <img src={`${process.env.PUBLIC_URL}/assets/images/icon/return_white.png`} alt="" /></Link>
							  </div>
							  <div className="_1leRb page_title">
								 Beauty Parlour
							  </div>
							  <div className="b5NJg"></div>
						   </div>
						   <input type="submit" hidden=""/>
						</form>
					 </div>
				    
                </section>
				
				
                <section className="pb-0 margin-pd restro details-pad-top-n">
				    
                    <div className="add-details details-padding gym-pad-bot" role="button" tabIndex="0">
						<div className="res-details-info col-md-12">
							<div class="collection-banner p-left text-left">
								<img src="https://classicxtra.com/bucket/banner/WhatsApp Image 2019-11-02 at 4.07.15 PM (1).jpeg" alt="" class="img-fluid blur-up lazyload bg-img" />
								<div class="absolute-contain">
									<h3>Maiza Beauty Studio</h3>
									<h4>Certified By Shabnam Zaveri</h4>
								</div>
							</div>
							
						</div>
					</div>
                </section>
				<section className="pb-0 margin-pd mt-10 restro details-pad-top-n">
				
				 {lis.data.map((list) => (
                    <div className="add-details details-padding" role="button" tabIndex="0">
						<div className="res-details-info col-md-12 product-box ">
							<div className="col-md-12  no-pad addtocart_btn">
							<h3 className="restro details-dish-title details-color">
							{list.name.map(data => 
							<div>
							<span className="restro details-dish-title details-color"><b>{data}</b></span><br/>
							</div>
							)}
							</h3>
								<div className="details-color "><i class="fa fa-inr" aria-hidden="true"></i> {list.price}</div>
								<div>
										  {(() => {
											if (list.status =='1') {
											return (
												 list.is_cart>0?
													  <div>
													  <button className="details-add-btn add_cart"  onClick={this.checkQuantity.bind(this,list.id)}> Add</button>
													  </div>
													  :
													  <div>
													  <button className="details-add-btn add_cart"  onClick={this.openQuantity.bind(this,list.id)}> Add</button>
													  </div>
													  
												)
											}
										})()}
										<div id={`chk_${list.id}`} className={`qty-box cart_qty t-15 ${list.item_is_flag>0?'open':''} `}>
											<input type="hidden" id={`item_id${list.id}`} value={list.id}/>
											  <input type="hidden" id={`item_price${list.id}`} value={list.price}/>
											  <input type="hidden" id={`item_qty${list.id}`} value={list.item_qty} readOnly={true} className="form-control input-number" />
											   <span className="details-add-btn add_cart t-15" onClick={this.minusQty.bind(this,list.id)} > Remove</span>
											
										</div>
							    </div>
							</div>
						</div>
					</div>
					))}
					
					
					
					
					
                </section>
				
				
				
			
	<div className={`float-cart ${lis.total_flag>0?'show-cart':''}`} id="float-cart">
<Link className="text-white" to={`${process.env.PUBLIC_URL}/order_details/`}>
<span id="count_item" className="count_item">{lis.cart_count} items</span>
<span>&nbsp;|&nbsp;</span>
<span id="count_total" className="count_total">Rs. {lis.cart_total}</span>
<span class="pull-right">Book Now <i class="si si-basket"></i></span>
<canvas height="0" width="0" class="canvas"></canvas></Link>

</div>	

</div>	
	
))}				 
				


 
			</div>
			
			)

	}
}

export default Home;
