import React, { Component } from 'react';
import { Link, NavLink} from 'react-router-dom';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from 'react-loader-spinner';
import {getListData} from '../../../services/index';
import '../../common/restaurant.scss';
import {connect} from 'react-redux';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

class RestaurantDetails extends Component {
constructor(props) {
	super(props);
	this.state = {
        openCart: '',
        countItem: 0,
        countTotal: 0,
		ListData: [],
		qty: 0,
		openQuantity: false,
        quantity: 1,
        image: '',
        name: '',
		
	};
	
	if (!localStorage.getItem('userData')) {
		this.props.history.push("login");
	}
}
componentDidMount() {
	document.getElementById("color").setAttribute("href", `#`);
	document.querySelector(".loader-wrapper").style = "display: block";
	setTimeout(function() {
    document.querySelector(".loader-wrapper").style = "display: none";
	}, 900);
	
	var storedArray = JSON.parse(localStorage.getItem("userData"));
	this.state.mobile=storedArray['data']['mobile'];
	
	let id =this.props.match.params.id;
	this.setState({
					id: id,
				})
	this.checkData();
	
	
}


checkData() {
	
	let id =this.props.match.params.id;
	var app = {
		id : id
	}
	getListData('society/SocietyController/society_details', app).then((result) => {
		let responseJson = result;
		if (responseJson.data) {
			if (result['status'] == 200) {
				this.setState({
					ListData: result['data'],
					name: result['data'][0]['name'],
				})
			}
		}
	});
	
}
   

render() {


var lenght = this.state.ListData.length;

return (
<div >
   {this.state.ListData.map((list) => (	
   <div>
   <section className="pb-0 margin-pd mt-38 society-det">
      <div className="_2u0oK _1iY8Y">
         <div className="_3TzfO page_head">
            <div className="_2QN4q">
               <Link to={`${process.env.PUBLIC_URL}/society`}>
               <img src={`${process.env.PUBLIC_URL}/assets/images/icon/return_white.png`} alt="" /></Link>
			   
            </div>
            <div className="_1leRb page_title res-title">
               My Society
            </div>
            <div className="b5NJg"></div>
         </div>
      </div>
      <div className="" >
			  
			<div className="society-dash pt-20" style={{background: "#3e3e98"}}>  
			  <h3 class="society-details-title">{list.society_name}</h3>
			  <h3 class="society-details-title-welcome font-s-19"><b>{list.name}</b></h3>
			  <h3 class="society-details-title-welcome mt-28">{list.society_unit} </h3>
			</div >  
		</div>
		
</section>

 <section className="pb-0 margin-pd no-pad restro mt-5 society-det">
	<div class="row marginlr">	
		
						<div class="col-12 col-lg-12 col-xl-12 pr-2">
							<div class="card gradient-primary">
								<Link to={`${process.env.PUBLIC_URL}/bill/${list.owner_id}/${list.unit_id}`}>
									<div class="card-body">
										<div class="media">
											<div class="media-body">
												<div class="dashboard-img col-md-2 no-padd text-left">
													<img src={`${process.env.PUBLIC_URL}/assets/images/icon/invoices.png`} alt="" />
												</div>
												<div class="col-md-9 no-padd">
													<p class="mb-0 text-left soc-mt-0 font-s-16 font-w-550">Latest Maintenance bill</p>
													<p class="mb-0 text-left font-w-550">Rs. {list.total_amount}/- <span class="font-s-14">{list.till_date}</span></p>
												</div>
												<div class="col-md-1 no-padd"><img src={`${process.env.PUBLIC_URL}/assets/images/icon/black-right-arrow.png`} alt="" class="black-right-img"/></div>
											</div>
										</div>
									</div>
								</Link>
							</div>
						</div>
						
						<div class="col-6 col-lg-6 col-xl-6 pr-2 mt-5">
							<div class="card gradient-primary">
							<Link to={`${process.env.PUBLIC_URL}/payment-history/${list.owner_id}/${list.unit_id}`}>
									<div class="card-body">
										<div class="media">
											<div class="media-body">
												<div class="dashboard-img">
													<img src={`${process.env.PUBLIC_URL}/assets/images/icon/payment-method.png`} alt="" />
												</div>
												<p class="mb-0">Payment Histotry</p>
											</div>
										</div>
									</div>
								</Link>
							</div>
						</div>	
						
						<div class="col-6 col-lg-6 col-xl-6 pr-2 pl-0 mt-5">
							<div class="card gradient-primary">
								<Link to={`${process.env.PUBLIC_URL}/complaints/${list.owner_id}`}>
									<div class="card-body">
										<div class="media">
											<div class="media-body">
												<div class="dashboard-img">
													<img src={`${process.env.PUBLIC_URL}/assets/images/icon/bad-review.png`} alt="" />
												</div>
												<p class="mb-0">Complaints</p>
											</div>
										</div>
									</div>
								</Link>
							</div>
						</div>
						
						<div class="col-6 col-lg-6 col-xl-6 pr-2  mt-5">
							<div class="card gradient-primary">
								<Link to={`${process.env.PUBLIC_URL}/notice-board/${list.owner_id}`}>
									<div class="card-body">
										<div class="media">
											<div class="media-body">
												<div class="dashboard-img">
													<img src={`${process.env.PUBLIC_URL}/assets/images/icon/noticeboard.png`} alt="" />
												</div>
												<p class="mb-0">Notice Board</p>
											</div>
										</div>
									</div>
								</Link>
							</div>
						</div>	
						
						<div class="col-6 col-lg-6 col-xl-6 pr-2 pl-0 mt-5">
							<div class="card gradient-primary">
								<Link to={`${process.env.PUBLIC_URL}/parking-details/${this.state.id}`}>
									<div class="card-body">
										<div class="media">
											<div class="media-body">
												<div class="dashboard-img">
													<img src={`${process.env.PUBLIC_URL}/assets/images/icon/parking.png`} alt="" />
												</div>
												<p class="mb-0">Parking Details</p>
											</div>
										</div>
									</div>
								</Link>
							</div>
						</div>
						
						<div class="col-6 col-lg-6 col-xl-6 pr-2  mt-5">
							<div class="card gradient-primary">
								<Link to={`${process.env.PUBLIC_URL}/meetings/${this.state.id}`}>
									<div class="card-body">
										<div class="media">
											<div class="media-body">
												<div class="dashboard-img">
													<img src={`${process.env.PUBLIC_URL}/assets/images/icon/meeting.png`} alt="" />
												</div>
												<p class="mb-0">Meetings</p>
											</div>
										</div>
									</div>
								</Link>
							</div>
						</div>	
						
						<div class="col-6 col-lg-6 col-xl-6 pr-2 pl-0 mt-5">
							<div class="card gradient-primary">
								<Link to={`${process.env.PUBLIC_URL}/society-profile/${this.state.id}`}>
									<div class="card-body">
										<div class="media">
											<div class="media-body">
												<div class="dashboard-img">
													<img src={`${process.env.PUBLIC_URL}/assets/images/icon/personal-information.png`} alt="" />
												</div>
												<p class="mb-0">My Profile</p>
											</div>
										</div>
									</div>
								</Link>
							</div>
						</div>
		
		
		
	</div>
	
 </section>
 
 




</div>

))}


</div>
)
}
}
	
export default RestaurantDetails;