import React, { Component } from 'react';
import { Link, NavLink} from 'react-router-dom';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from 'react-loader-spinner';
import {getListData} from '../../../services/index';
import '../../common/restaurant.scss';
import {connect} from 'react-redux';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

class RestaurantDetails extends Component {
constructor(props) {
	super(props);
	this.state = {
        openCart: '',
        countItem: 0,
        countTotal: 0,
		ListData: [],
		qty: 0,
		openQuantity: false,
        quantity: 1,
        image: ''
	};
	if (!localStorage.getItem('userData')) {
		this.props.history.push("login");
	}
}
componentDidMount() {
	document.getElementById("color").setAttribute("href", `#`);
	document.querySelector(".loader-wrapper").style = "display: block";
	setTimeout(function() {
    document.querySelector(".loader-wrapper").style = "display: none";
}, 900);

}

	

render() {

return (
<div>

   <div>
   <section className="pb-0 margin-pd mt-45 restro">
      <div className="_2u0oK _1iY8Y">
         <div className="_3TzfO page_head">
            <div className="_2QN4q">
               <Link to={`${process.env.PUBLIC_URL}/lab_test`}>
               <img src={`${process.env.PUBLIC_URL}/assets/images/icon/return_white.png`} alt="" /></Link>
            </div>
            <div className="_1leRb page_title res-title">
               Neulife Healthcare Center
            </div>
            <div className="b5NJg"></div>
         </div>
      </div>
     

</section>


<section className="pb-0 margin-pd restro details-pad-top-n cart-bottom">

   <div className="details-padding" role="button" tabIndex="0">
      <div className="res-details-info">
	  
	
	    <div class="_1ugOt">
			<img class="WLgeU" src={`${process.env.PUBLIC_URL}/assets/images/icon/rx.png`} alt="Upload Rx"/>
	    </div>
        <div class="_3-Klh _1lp7v">
			<div class="_1-Hc6">
				<button class="_2FE4Z h1H8I _2Jc-Z _3LBfS">Upload Presciption</button>
			</div>
		</div>
	
      </div>
   </div>
</section>





</div>




</div>
)
}
}
	
export default RestaurantDetails;