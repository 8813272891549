import React, {Component} from 'react';
import { Link, NavLink} from 'react-router-dom';
import {getListData} from '../../services/index';
class Search extends Component {
	
	
    constructor (props) {
        super (props)
		this.state = {
		latitude: '',
		longitude: '',
		place_: '',
		ListData: []
    }
    this.getMyLocation = this.getMyLocation.bind(this);
    this.setLocation = this.setLocation.bind(this);
    }
	
	componentDidMount() {

		document.querySelector(".loader-wrapper").style = "display: block";
		setTimeout(function() {
            document.querySelector(".loader-wrapper").style = "display: none";
        }, 400);
      
        var storedArray  = JSON.parse(localStorage.getItem("userData"));
        this.state.user_id = storedArray['data']['user_id'];	
        getListData('address/address_list',this.state).then((result) => {
        let responseJson = result;
		this.state.count = result['count'];
            if(responseJson.data){
               if(result['count']>0){
                  this.setState({ 
                    ListData: result['data']
                  })
               }else{
			   //document.getElementById('alert').innerHTML='<div class="_2czoF"><div class="_1KevM empty-rest"><img src="assets/images/icon/map-location.png" alt="" /></div><div class="_1KevM empty-rest">Please Seach location!</div></div>';
			   }
            }
        });
		
        const google = window.google;	
		const $ = window.$;  
        initialize();
        function initialize() {
          var input = document.getElementById('searchTextField');
          var autocomplete = new google.maps.places.Autocomplete(input);
            google.maps.event.addListener(autocomplete, 'place_changed', function () {
                var place = autocomplete.getPlace();
                document.getElementById('city2').value = place.name;
                document.getElementById('cityLat').value = place.geometry.location.lat();
                document.getElementById('cityLng').value = place.geometry.location.lng();
                var latitude = place.geometry.location.lat();
                var longitude = place.geometry.location.lng();
				document.getElementById("location_get").click();
				localStorage.setItem('user_lat', place.geometry.location.lat());
				localStorage.setItem('user_lng', place.geometry.location.lng());
				localStorage.setItem('user_location_name', place.name);
				localStorage.setItem('user_location_address', place.vicinity);
            });
        }
        google.maps.event.addDomListener(window, 'load', initialize);	
    }	
	
	getCurrentLocation(){
		window.AskLocation();	
		const google = window.google;	
		const $ = window.$; 
		getLocation();
		function getLocation() {
			if(navigator.geolocation) {
				navigator.geolocation.getCurrentPosition(showPosition);
				navigator.geolocation.watchPosition(function(position) {
					codeLatLng(position.coords.latitude,position.coords.longitude);
				},
				function(error) {
					if (error.code == error.PERMISSION_DENIED){
						window.location.assign("/search");						
					}
				});
			}
		}
		function showPosition(position) {			
			localStorage.setItem("clat",position.coords.latitude);
			localStorage.setItem("clng",position.coords.longitude);			
			codeLatLng(position.coords.latitude,position.coords.longitude);
		}
	
		function codeLatLng(lat, lng) {
			var geocoder = new google.maps.Geocoder();
			var latlng = new google.maps.LatLng(lat, lng);
				geocoder.geocode({'latLng': latlng}, function(results, status) {
				if(status == google.maps.GeocoderStatus.OK) {
					console.log(results)
					if(results[1]) {
						localStorage.setItem('user_lat', lat);
						localStorage.setItem('user_lng', lng);
						localStorage.setItem('user_location_name', results[0].formatted_address);
						localStorage.setItem('user_location_address',results[0].address_components[3].long_name);
						//window.location.assign("/"+type);
						if(localStorage.getItem('search_redirect')) {
							var search_page=localStorage.getItem("search_redirect");							
							window.location.assign("/"+search_page);
						}
						else{
							window.location.assign("/");
						}
						//this.setRedirect();
						
					}
				}
			});
		}
	}
	
	setRedirect() {
		alert('hello');
    }

	getMyLocation() {
		this.setState({
          latitude: document.getElementById("cityLat").value,
          longitude: document.getElementById("cityLng").value,
          place_: document.getElementById("city2").value,
        });
        var loc = this.props.history.goBack();    
    }	
	
	setLocation(addressid,name,address,lat,lng) {		
		if (localStorage.getItem('userData')) {
			var storedArray = JSON.parse(localStorage.getItem("userData"));
			var user_id = storedArray['data']['user_id'];
			var app = {
				user_id:user_id,
				address_id:addressid
			}
			getListData('address/make_default', app).then((result) => {
				let responseJson = result;
				if (responseJson) {	
					if (result['status']==200) {
						localStorage.setItem('user_lat', lat);
						localStorage.setItem('user_lng', lng);
						localStorage.setItem('user_location_name', name);
						localStorage.setItem('user_location_address', address);
					}
				}
			});
		}
    }

    
    render (){
        const { latitude, longitude, place_ } = this.state;
		
        
		if(latitude>0 && longitude>0){
			this.props.history.push({
             pathname:"/{loc}",
             state:{
                 lat:latitude,
                 lon:longitude,
                 plc:place_
              }
            });
		}
		
		
        return (
            <div>
                
                
                {/*Search section*/}
                <section className="authentication-page section-b-space margin-pd">
				<span id="loader"></span>
<div className="_3lldG" >
   <div className="_2u0oK _1iY8Y">
      <form>
         <div className="_3TzfO">
            <div className="_2QN4q">
               <Link to={`${process.env.PUBLIC_URL}/`}><img className="cross_icon" src={`${process.env.PUBLIC_URL}/assets/images/icon/cross.png`} alt="" /></Link>
            </div>
            <div className="_1leRb">
			<input type="text" id="searchTextField" className="_3B7Eo location_search" placeholder="Enter area, street name..."/>
			</div>
            <div className="b5NJg"><img src={`${process.env.PUBLIC_URL}/assets/images/icon/search.png`} alt="" /></div>
         </div>
         <input type="submit" hidden=""/>
      </form>
   </div>
   <div class="_1qG6k">
   
   <div class="_3ce3U" onClick={this.getCurrentLocation}>
   
    <div className="_1nPUR"><img src={`${process.env.PUBLIC_URL}/assets/images/icon/gps.png`} alt="" /></div>
   <div class="_3qV1m"><div class="_1kMGJ">Use Current Location</div><div class="_2cGgB">Using GPS</div></div></div></div>
   
   {(() => {
								if (this.state.count > 0) {
								return (
								
   <div className="add-back">
						<div className="_14v1V center">
								<div className="nowrap add-title left">Saved Address</div>
						</div>
	</div> 
	)
	}
							})()}
	<div id="alert" className="m-b-10"></div>
	
	<span id="loader"></span>						
    <div className="_3mMtt"  id="location_get" onClick={this.getMyLocation}><div>
	<input type="hidden" id="city2" name="city2" />
    <input type="hidden" id="cityLat" name="cityLat" />
    <input type="hidden" id="cityLng" name="cityLng" />
	  
    
     <div className="">
     {this.state.ListData.map((list) => (
            <div className="_1qG6k" onClick={this.setLocation.bind(this,list.id,list.location,list.flat_no,list.map_lat,list.map_long)}>
               <div className="_3ce3U">
                  <div className="_1nPUR"><img src={`${process.env.PUBLIC_URL}/assets/images/icon/location.svg`} alt="" /></div>
                  <div className="_3qV1m">
                     <div className="_1kMGJ" >{list.tag}</div>
                     <div className="_2cGgB">{list.flat_no}, {list.location}</div>
                  </div>
               </div>
            </div>
      ))}
            
         </div>
		 
		 
	 
        
      </div>
   </div>
   <div className="">
            <div className="_1qG6k">
              
                 
                     <div className="center" > powered by <img src={`${process.env.PUBLIC_URL}/assets/images/icon/google.png`} alt="" /></div>
                     
                 
               
            </div>
         </div>
   
   
   
</div>
                </section>

            </div>
        )
    }
}

export default Search