import React, { Component } from 'react';
import { Link, NavLink} from 'react-router-dom';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from 'react-loader-spinner';
import {getListData} from '../../../services/index';
import '../../common/restaurant.scss';
import '../../common/antd-mobile.min.css';
import {connect} from 'react-redux';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { Accordion, List } from 'antd-mobile';

class RestaurantDetails extends Component {
constructor(props) {
	super(props);
	this.state = {
        openCart: '',
        countItem: 0,
        countTotal: 0,
		ListData: [],
		qty: 0,
		openQuantity: false,
        quantity: 1,
        image: ''
	};
	if (!localStorage.getItem('userData')) {
		this.props.history.push("login");
	}
}

onChange = (key) => {
    console.log(key);
  }
  
componentDidMount() {
	document.getElementById("color").setAttribute("href", `#`);
	document.querySelector(".loader-wrapper").style = "display: block";
	setTimeout(function() {
    document.querySelector(".loader-wrapper").style = "display: none";
}, 900);
this.getData();
}

getData = () => {
		var storedArray = JSON.parse(localStorage.getItem("userData"));
		let package_id = this.props.match.params.id;
        this.state.package_id = package_id;
		this.state.user_id=storedArray['data']['user_id'];
		getListData('labs/package_details', this.state).then((result) => {
		let responseJson = result;
		if (responseJson.data) {
			if (result['count'] > 0) {
				this.setState({
					ListData: result['data']
				})
			}
		}
		});
    }



render() {

return (
<div>
{this.state.ListData.map((list) => (
   <div>
   <section className="pb-0 margin-pd mt-45 restro">
      <div className="_2u0oK _1iY8Y">
         <div className="_3TzfO page_head">
            <div className="_2QN4q">
               <Link to={`${process.env.PUBLIC_URL}/health_package`}>
               <img src={`${process.env.PUBLIC_URL}/assets/images/icon/return_white.png`} alt="" /></Link>
            </div>
            <div className="_1leRb page_title res-title">
               {list.title}
            </div>
            <div className="b5NJg"></div>
         </div>
      </div>
     

</section>


<section className="pb-0 margin-pd restro details-pad-top-n cart-bottom">

   <div className="res-details-div details-padding" role="button" tabIndex="0">
      <div className="res-details-info">
	
		
	<div>
      <div class="product-box">
         <div class="img-wrapper">
            <div class="details-dish-con addtocart_btn">
               <div class="details-dish-title details-color max-width f-s-24">
			   <img className="order-img-details" src={`${process.env.PUBLIC_URL}/assets/images/icon/all_test.png`} alt="" />
			   {list.title}</div>
               <div class="details-dish-title details-dish-price">Includes {list.product_count} tests</div>
               <div class="details-dish-title details-dish-price"><b><i class="fa fa-inr" aria-hidden="true"></i>{list.price}</b></div>
			   <div>
					
					<button class="details-add-btn add_cart"> Select</button>
				</div>
              
            </div>
			 <div style={{ marginTop: 10, marginBottom: 10 }}>
        <Accordion className="my-accordion" onChange={this.onChange}>
          
          <Accordion.Panel header="Test Include" className="pad">
			<div
			  dangerouslySetInnerHTML={{
				__html: list.package_include
			  }}></div>
		</Accordion.Panel>
          <Accordion.Panel header="Package Requirement" className="pad">
            <div
			  dangerouslySetInnerHTML={{
				__html: list.package_requirement
			  }}></div>
          </Accordion.Panel>
        </Accordion>
      </div>
         </div>
      </div>
    </div>
			
	
	
      </div>
   </div>
</section>



<div className={`float-cart show-cart`} id="float-cart">
<span id="count_item" className="count_item">5 Tests</span>
<span>&nbsp;|&nbsp;</span>
<span id="count_total" className="count_total">Rs. 3785</span>
<span class="pull-right">View Cart <i class="si si-basket"></i></span>
<canvas height="0" width="0" class="canvas"></canvas>

</div>

</div>

))}


</div>
)
}
}
	
export default RestaurantDetails;