import React from "react";
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import Lightbox from "react-image-lightbox"; 
import { Link, NavLink} from 'react-router-dom';
import '../../common/gallery.scss';

class LightboxPage extends React.Component {

    componentDidMount() {
        document.getElementById("color").setAttribute("href", `#` );		
		document.querySelector(".loader-wrapper").style = "display: block";
	setTimeout(function() {
    document.querySelector(".loader-wrapper").style = "display: none";
    }, 900);
    }

state = {
  photoIndex: 0,
  isOpen: false,
  images: [
    'assets/images/gym/1.jpeg',
    'assets/images/gym/2.jpeg',
    'assets/images/gym/3.jpeg',
    'assets/images/gym/4.jpeg',
    'assets/images/gym/5.jpeg',
    'assets/images/gym/6.jpeg',
    'assets/images/gym/7.jpeg',
    'assets/images/gym/8.jpeg',
    'assets/images/gym/9.jpeg',
    'assets/images/gym/10.jpeg',
    'assets/images/gym/11.jpeg',
    'assets/images/gym/12.jpeg',
  ]
}

renderImages = () => {
  let photoIndex = -1;
  const { images } = this.state;

return images.map(imageSrc => {
  photoIndex++;
  const privateKey = photoIndex;
  return (
    <MDBCol md="4 no-pad" key={photoIndex}>
      <figure>
        <img src={imageSrc} alt="Gallery" className="img-fluid" onClick={()=>
        this.setState({ photoIndex: privateKey, isOpen: true })
        }
        />
      </figure>
    </MDBCol>
    );
  })
}

render() {
const { photoIndex, isOpen, images } = this.state;
  return (

        
      <MDBContainer className="gal-pad mt-5">
          <div>
            <section className="pb-0 margin-pd mt-45 restro">
            <div className="_2u0oK _1iY8Y">
            <Link to={`${process.env.PUBLIC_URL}/gym_fitness`}>
                <div className="_3TzfO page_head">
                    <div className="_2QN4q">
                       
                        <img src={`${process.env.PUBLIC_URL}/assets/images/icon/return_white.png`} alt="" />
                    </div>
                    <div className="_1leRb page_title">
                        Gallery
                    </div>
                    <div className="b5NJg"></div>
                </div>
                </Link>
            </div>
        </section>
        </div>
        <div className="mdb-lightbox no-margin">
          <MDBRow>
            {this.renderImages()}
          </MDBRow>
        </div>
        {isOpen && (
        <Lightbox
          mainSrc={images[photoIndex]}
          nextSrc={images[(photoIndex + 1) % images.length]}
          prevSrc={images[(photoIndex + images.length - 1) % images.length]}
          imageTitle={photoIndex + 1 + "/" + images.length}
          onCloseRequest={() => this.setState({ isOpen: false })}
          onMovePrevRequest={() =>
            this.setState({
              photoIndex: (photoIndex + images.length - 1) % images.length
            })
          }
          onMoveNextRequest={() =>
            this.setState({
              photoIndex: (photoIndex + 1) % images.length
            })
            }
          />
        )}
      </MDBContainer>
    );
  }
}

export default LightboxPage;