import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import "../../common/index.scss";
import { getListData } from "../../../services/index";
import Ripples from "react-ripples";
import $ from "jquery";
import firebase from "firebase";
import Slider from "react-slick";
//import { askForPermissioToReceiveNotifications } from '../../../push-notification';
import { Modal } from 'antd';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'antd/dist/antd.css';



class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ListData: [],
      TrackCart: [],
      footer_banner_list: [],
	  show_popup:false,
	  popup_ad:'',
	  popup_url:'',
      clat: "",
      clng: "",
      current_time: "",
      redirectToOrderFoodOnline: false,
      redirectState: "",
      user_location_name: "Set Location",
      user_location_address: "set your current location",
    };

    this.goBack = this.goBack.bind(this);
    if (!localStorage.getItem("userData")) {
      this.props.history.push("login");
    } else {
      //this.getToken();
    }
  }

  componentDidMount() {
    localStorage.setItem("search_redirect", "");
    document.getElementById("color").setAttribute("href", `#`);
    document.querySelector(".loader-wrapper").style = "display: block";
    setTimeout(function () {
      document.querySelector(".loader-wrapper").style = "display: none";
    }, 900);
    var today = new Date();
    var current_time = today.getHours() + "." + today.getMinutes();
    this.setState({
      current_time: current_time,
    });
    if (localStorage.getItem("userData")) {
      var storedArray = JSON.parse(localStorage.getItem("userData"));
      this.state.username = storedArray["data"]["username"];
      getListData("auth/wallet_balance", this.state).then((result) => {
        let responseJson = result;
        if (responseJson.data) {
          //document.getElementById("balance_amount").innerHTML = 'INR '+result['data']['UserData']['BalanceAmount']+'/-';
        }
      });
      var storedArray = JSON.parse(localStorage.getItem("userData"));
      this.state.username = storedArray["data"]["username"];
      this.state.lat = localStorage.getItem("user_lat");
      this.state.long = localStorage.getItem("user_lng");
      if (this.state.lat > 0 && this.state.long > 0) {
        this.state.location = "order_food_online";
        this.state.phar_location = "order_medicine";
      } else {
        this.state.location = "search";
        this.state.phar_location = "search";
      }

      getListData("auth/banner_list", this.state).then((result) => {
        let responseJson = result;
        if (responseJson.data) {
          this.setState({
            ListData: result["data"],
          });
        }
      });

	  getListData("auth/footer_banner_list", this.state).then((result) => {
        let responseJson = result;
        if (responseJson.data) {
          this.setState({
            footer_banner_list: result["data"],
          });
        }
      });

	  getListData("auth/popup_ad", this.state).then((result) => {
        let responseJson = result;
        if (responseJson.data) {
          this.setState({
            popup_ad: result["data"][0]["image"],
			popup_url: result["data"][0]["url"],
			show_popup:true
          });
        }
      });

      this.state.username = storedArray["data"]["username"];
      getListData("auth/check_token", this.state).then((result) => {
        let responseJson = result;
        if (result == "") {
        }
      });

      var app = storedArray["data"]["classic_user"];
      if (app == "1") {
        document.getElementById("classic").classList.add("wallet");
      }

      var user_id = storedArray["data"]["user_id"];
      window.showAndroidToast(user_id);

      this.getData();
      setInterval(this.getData, 50000);
    } else {
      this.props.history.push("login");
    }
    this.getDefaultaddress();
  }

  getDefaultaddress() {
    if (localStorage.getItem("user_lat") > 0) {
      this.setState({
        user_location_name: localStorage.getItem("user_location_name"),
        user_location_address: localStorage.getItem("user_location_address"),
      });
    } else {
      if (localStorage.getItem("userData")) {
        var storedArray = JSON.parse(localStorage.getItem("userData"));
        this.state.user_id = storedArray["data"]["user_id"];
        getListData("address/default_address", this.state).then((result) => {
          let responseJson = result;
          if (responseJson.data) {
            if (result["count"] > 0) {
              localStorage.setItem("user_lat", result["data"][0]["map_lat"]);
              localStorage.setItem("user_lng", result["data"][0]["map_long"]);
              localStorage.setItem(
                "user_location_name",
                result["data"][0]["location"],
              );
              localStorage.setItem(
                "user_location_address",
                result["data"][0]["flat_no"],
              );
              this.setState({
                user_location_name: result["data"][0]["location"],
                user_location_address: result["data"][0]["flat_no"],
              });
            } else {
              this.setState({
                user_location_name: "Set Location",
                user_location_address: "set your current location",
              });
            }
          }
        });
      }
    }
  }

  getData = (user_id, order_id) => {
    if (localStorage.getItem("userData")) {
      var storedArray = JSON.parse(localStorage.getItem("userData"));
      this.state.user_id = storedArray["data"]["user_id"];
      this.state.order_id = localStorage.getItem("order_id");
      getListData("restaurant/track_order_status", this.state).then(
        (result) => {
          let responseJson = result;
          if (responseJson.data) {
            if (result["count"] > 0) {
              this.setState({
                TrackCart: result["data"],
              });
            }
          }
        },
      );
    }
  };

  goBack() {
    this.props.history.goBack();
  }

  whatsapp_redirect() {
    window.open(
      "https://api.whatsapp.com/send?phone=919987877866&text=Hello",
      "_blank",
    );
  }

	page_redirect=(phone,msg)=>{
		window.open(
			"https://api.whatsapp.com/send?phone="+phone+"&text="+msg,
			"_blank",
		);
	}

	handleOk=()=>{
		this.setState({
            show_popup: false
        });
	}

	handleCancel=()=>{
		this.setState({
            show_popup: false
        });
	}

  render() {
    const settings = {
      autoplay: true,
      speed: 1000,
      duration: 4000,
    };
    return (
      <div>
        <header id="sticky" className="sticky margin-pd pd-10">
          <div>
            <img
              src={`${process.env.PUBLIC_URL}/assets/images/icon/logo_icon.png`}
              alt=""
              className="header-logo"
            />
            <div className="_top_location">
              <div className="first_line">
                Classic Xtra
              </div>
            </div>
          </div>
        </header>

		<Modal className="popup_ad_model" visible={this.state.show_popup} onOk={this.handleOk} onCancel={this.handleCancel}>
			<a target="_blank" href={this.state.popup_url}><img src={this.state.popup_ad} class="popup_ad"/></a>
		</Modal>

        {(() => {
          if (localStorage.getItem("userData")) {
            var storedArray = JSON.parse(localStorage.getItem("userData"));
            var apps = storedArray["data"]["classic_user"];
          }
          if (apps == "0") {
            return (
              <div>
                <section className="p-0 wallet-pd">
                  <div class="wallet gradient-primary">
                    <table>
                      <tr>
                        <td>Your Internet Wallet Balance</td>
                        <td class="wallet-right">
                          <img
                            src={`${process.env.PUBLIC_URL}/assets/images/icon/wallet.png`}
                            alt=""
                          />{" "}
                          <span id="balance_amount">INR 0/-</span>
                        </td>
                      </tr>
                    </table>
                  </div>
                </section>
              </div>
            );
          }
        })()}

        <section className="p-0 margin-pd " id="classic">
          <div class="swiper-container">
            <Slider {...settings}>
              {this.state.ListData.map((list) =>
                (() => {
                  if (localStorage.getItem("userData")) {
                    var storedArray = JSON.parse(
                      localStorage.getItem("userData"),
                    );
                    var apps = storedArray["data"]["classic_user"];
                    var url = list.url;
                  }
                  if (apps == "0" && url == "classic_broadband") {
                    return (
                      <div id={`${list.id}`}>
                        <a target="_blank" href={list.url}>
                          <img src={`${list.image}`} />
                        </a>
                      </div>
                    );
                  } else if (apps == "1" && url == "classic_broadband") {
                    return (
                      <div id={`${list.id}`}>
                        <Link to={`${process.env.PUBLIC_URL}/classic_profile`}>
                          <img src={`${list.image}`} />
                        </Link>
                      </div>
                    );
                  } else {
                    return (
                      <div id={`${list.id}`}>
                        <a target="_blank" href={list.url}>
                          <img src={`${list.image}`} />
                        </a>
                      </div>
                    );
                  }
                })(),
              )}
            </Slider>
          </div>
        </section>

        <section className="home-tab pb-0 ">
          <div class="row">
            {(() => {
              if (localStorage.getItem("userData")) {
                var storedArray = JSON.parse(localStorage.getItem("userData"));
                var apps = storedArray["data"]["classic_user"];
              }
              if (apps == "0") {
                return (
                  <div class="col-6 col-lg-6 col-xl-6 pr-2">
                    <div class="card gradient-primary">
                      <Link to={`${process.env.PUBLIC_URL}/classic_broadband`}>
                        <div class="card-body">
                          <div class="media">
                            <div class="media-body">
                              <div class="dashboard-img">
                                <img
                                  src={`${process.env.PUBLIC_URL}/assets/images/icon/router.png`}
                                  alt=""
                                />
                              </div>
                              <p class="mb-0">Classic Broadband & Cable Tv </p>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>
                );
              }
            })()}
            {(() => {
              if (localStorage.getItem("userData")) {
                var storedArray = JSON.parse(localStorage.getItem("userData"));
                var apps = storedArray["data"]["classic_user"];
              }
              if (apps == "1") {
                return (
                  <div class="col-6 col-lg-6 col-xl-6 pr-2">
                    <div class="card gradient-primary">
                      <Link to={`${process.env.PUBLIC_URL}/classic_profile`}>
                        <div class="card-body">
                          <div class="media">
                            <div class="media-body">
                              <div class="dashboard-img">
                                <img
                                  src={`${process.env.PUBLIC_URL}/assets/images/icon/router.png`}
                                  alt=""
                                />
                              </div>
                              <p class="mb-0">Classic Broadband & Cable Tv </p>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>
                );
              }
            })()}

            <div class="col-6 col-lg-6 col-xl-6 pr-2">
              <div class="card gradient-primary">
                <a href="javascript:void(0)" onClick={this.page_redirect.bind(this,'919867974738','CBN - Enquiry about beauty parlour: Please send me the pricing and details.')}>
                  <div class="card-body">
                    <div class="media">
                      <div class="media-body">
                        <div class="dashboard-img">
                          <img
                            src={`${process.env.PUBLIC_URL}/assets/images/icon/makeover.png`}
                            alt=""
                          />
                        </div>
                        <p class="mb-0">Maiza Beauty Studio</p>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </div>

            <div class="col-6 col-lg-6 col-xl-6 pr-2">
              <div class="card gradient-primary">
                <a href="javascript:void(0)" onClick={this.page_redirect.bind(this,'919892560089','CBN - Enquiry about property.')}>
                  <div class="card-body">
                    <div class="media">
                      <div class="media-body">
                        <div class="dashboard-img">
                          <img
                            src={`${process.env.PUBLIC_URL}/assets/images/icon/property.png`}
                            alt=""
                          />
                        </div>
                        <p class="mb-0">Sell or Lease Your Properties</p>
                        <span>Save 50% on commission</span>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </div>

            <div class="col-6 col-lg-6 col-xl-6 pr-2">
              <div class="card gradient-primary">
                <a href="javascript:void(0)" onClick={this.page_redirect.bind(this,'919892560089','CBN - Enquiry about resort.')}>
                  <div class="card-body">
                    <div class="media">
                      <div class="media-body">
                        <div class="dashboard-img">
                          <img
                            src={`${process.env.PUBLIC_URL}/assets/images/icon/classic_villa.png`}
                            alt=""
                          />
                        </div>
                        <p class="mb-0">Classic Villa Palghar</p>
                        <p class="refund-amt">Refundable security deposit Rs. 5,000/-</p>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </div>

            <div class="col-6 col-lg-6 col-xl-6 pr-2">
              <div class="card gradient-primary">
                <a href="javascript:void(0)" onClick={this.page_redirect.bind(this,'919699545850','CBN - Enquiry about chicken and mutton: Please send me the pricing.')}>
                  <div class="card-body">
                    <div class="media">
                      <div class="media-body">
                        <div class="dashboard-img">
                          <img
                            src={`${process.env.PUBLIC_URL}/assets/images/icon/chicken.png`}
                            alt=""
                          />
                        </div>
                        <p class="mb-0">Buy Chicken & Mutton </p>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </div>

            <div class="col-6 col-lg-6 col-xl-6 pr-2">
              <div class="card gradient-primary">
                <a href="javascript:void(0)" onClick={this.page_redirect.bind(this,'917777093666','CBN - Enquiry about cars and bike.')}>
                  <div class="card-body">
                    <div class="media">
                      <div class="media-body">
                        <div class="dashboard-img">
                          <img
                            src={`${process.env.PUBLIC_URL}/assets/images/icon/automobile.png`}
                            alt=""
                          />
                        </div>
                        <p class="mb-0">Buy Cars & Bikes</p>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </div>

            <div class="col-12 col-lg-12 col-xl-12 pr-2">
              <div class="card gradient-primary finddeal">
                <a href="javascript:void(0)" onClick={this.page_redirect.bind(this,'917867862200','CBN - Enquiry about products.')}>
                  <div class="card-body single-tab">
                    <div class="media">
                      <div class="media-body">
                        <div class="col-md-3 dashboard-img no-padd">
                          <img
                            src={`${process.env.PUBLIC_URL}/assets/images/icon/finddeal.png`}
                            alt=""
                            class=""
                          />
                        </div>
                        <div class="col-md-9 text-left">
                          <p class="mb-0">Fine Deal</p>
                          <div className="_3oKxo">Store for all</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </div>

            <div class="col-6 col-lg-6 col-xl-6 pr-2">
              <div class="card gradient-primary">
                <div class="coming_soon_rel">
				  <div class="coming_soon"><div class="coming_soon_p">Coming Soon</div></div>
                  <div class="card-body">
                    <div class="media">
                      <div class="media-body">
                        <div class="dashboard-img">
                          <img
                            src={`${process.env.PUBLIC_URL}/assets/images/icon/sun-umbrella.png`}
                            alt=""
                          />
                        </div>
                        <p class="mb-0">Tours & Travels</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-6 col-lg-6 col-xl-6 pr-2">
              <div class="card gradient-primary">
                <div class="coming_soon_rel">
				  <div class="coming_soon"><div class="coming_soon_p">Coming Soon</div></div>
                  <div class="card-body">
                    <div class="media">
                      <div class="media-body">
                        <div class="dashboard-img">
                          <img
                            src={`${process.env.PUBLIC_URL}/assets/images/icon/food.png`}
                            alt=""
                          />
                        </div>
                        <p class="mb-0">Order Food Online </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-6 col-lg-6 col-xl-6 pr-2">
              <div class="card gradient-primary">
                <div class="coming_soon_rel">
				  <div class="coming_soon"><div class="coming_soon_p">Coming Soon</div></div>
                  <div class="card-body">
                    <div class="media">
                      <div class="media-body">
                        <div class="dashboard-img">
                          <img
                            src={`${process.env.PUBLIC_URL}/assets/images/icon/medicine.png`}
                            alt=""
                          />
                        </div>
                        <p class="mb-0">Orders Medicines</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-6 col-lg-6 col-xl-6 pr-2">
              <div class="card gradient-primary">
                <div class="coming_soon_rel">
				  <div class="coming_soon"><div class="coming_soon_p">Coming Soon</div></div>
                  <div class="card-body">
                    <div class="media">
                      <div class="media-body">
                        <div class="dashboard-img">
                          <img
                            src={`${process.env.PUBLIC_URL}/assets/images/icon/treadmill.png`}
                            alt=""
                          />
                        </div>
                        <p class="mb-0">Gym Fitness</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-6 col-lg-6 col-xl-6 pr-2">
              <div class="card gradient-primary">
                <div class="coming_soon_rel">
				  <div class="coming_soon"><div class="coming_soon_p">Coming Soon</div></div>
                  <div class="card-body">
                    <div class="media">
                      <div class="media-body">
                        <div class="dashboard-img">
                          <img
                            src={`${process.env.PUBLIC_URL}/assets/images/icon/physiotherapy.png`}
                            alt=""
                          />
                        </div>
                        <p class="mb-0">Physiotherapy At Home</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-6 col-lg-6 col-xl-6 pr-2">
              <div class="card gradient-primary">
                <div class="coming_soon_rel">
				  <div class="coming_soon"><div class="coming_soon_p">Coming Soon</div></div>
                  <div class="card-body">
                    <div class="media">
                      <div class="media-body">
                        <div class="dashboard-img">
                          <img
                            src={`${process.env.PUBLIC_URL}/assets/images/icon/apartment.png`}
                            alt=""
                          />
                        </div>
                        <p class="mb-0">My Society</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-6 col-lg-6 col-xl-6 pr-2">
              <div class="card gradient-primary">
                <div class="coming_soon_rel">
				  <div class="coming_soon"><div class="coming_soon_p">Coming Soon</div></div>
                  <div class="card-body">
                    <div class="media">
                      <div class="media-body">
                        <div class="dashboard-img">
                          <img
                            src={`${process.env.PUBLIC_URL}/assets/images/icon/microscope.png`}
                            alt=""
                          />
                        </div>
                        <p class="mb-0">Pathology Lab Test</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-6 col-lg-6 col-xl-6 pr-2">
              <div class="card gradient-primary">
                <div class="coming_soon_rel">
				  <div class="coming_soon"><div class="coming_soon_p">Coming Soon</div></div>
                  <div class="card-body">
                    <div class="media">
                      <div class="media-body">
                        <div class="dashboard-img">
                          <img
                            src={`${process.env.PUBLIC_URL}/assets/images/icon/laundry.png`}
                            alt=""
                          />
                        </div>
                        <p class="mb-0">Clothes & Accessories</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-6 col-lg-6 col-xl-6 pr-2">
              <div class="card gradient-primary">
                <div class="coming_soon_rel">
				  <div class="coming_soon"><div class="coming_soon_p">Coming Soon</div></div>
                  <div class="card-body">
                    <div class="media">
                      <div class="media-body">
                        <div class="dashboard-img">
                          <img
                            src={`${process.env.PUBLIC_URL}/assets/images/icon/groceries.png`}
                            alt=""
                          />
                        </div>
                        <p class="mb-0">Grocery Shopping</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-6 col-lg-6 col-xl-6 pr-2">
              <div class="card gradient-primary">
                <div class="coming_soon_rel">
				  <div class="coming_soon"><div class="coming_soon_p">Coming Soon</div></div>
                  <div class="card-body">
                    <div class="media">
                      <div class="media-body">
                        <div class="dashboard-img">
                          <img
                            src={`${process.env.PUBLIC_URL}/assets/images/icon/vegetables.png`}
                            alt=""
                          />
                        </div>
                        <p class="mb-0">Order Vegetables & Fruits</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-6 col-lg-6 col-xl-6 pr-2">
              <div class="card gradient-primary">
                <div class="coming_soon_rel">
				  <div class="coming_soon"><div class="coming_soon_p">Coming Soon</div></div>
                  <div class="card-body">
                    <div class="media">
                      <div class="media-body">
                        <div class="dashboard-img">
                          <img
                            src={`${process.env.PUBLIC_URL}/assets/images/icon/appliance.png`}
                            alt=""
                          />
                        </div>
                        <p class="mb-0">Appliance Repair</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-6 col-lg-6 col-xl-6 pr-2">
              <div class="card gradient-primary">
                <div class="coming_soon_rel">
				  <div class="coming_soon"><div class="coming_soon_p">Coming Soon</div></div>
                  <div class="card-body">
                    <div class="media">
                      <div class="media-body">
                        <div class="dashboard-img">
                          <img
                            src={`${process.env.PUBLIC_URL}/assets/images/icon/laptop.png`}
                            alt=""
                          />
                        </div>
                        <p class="mb-0">Buy Laptops</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-12 col-lg-12 col-xl-12 pr-2 changu_div">
              {(() => {
                if (
                  this.state.current_time >= 18 &&
                  this.state.current_time <= 21
                ) {
                  return (
                    <div onClick={this.whatsapp_redirect}>
                      <div
                        className="_3C_gI gradient-primary"
                        role="button"
                        tabIndex="0"
                      >
                        <div className="Cxph3">
                          <img
                            src={`${process.env.PUBLIC_URL}/assets/images/changez_multani.png`}
                            className="changu"
                            alt=""
                          />
                        </div>
                        <div className="NXGft">
                          <div className="_14v1V">Talk To Changez Multani</div>
                          <div className="_3oKxo">
                            Social Worker and Ex-corporator
                          </div>
                          <div className="_3oKxo">
                            Chat Between (6.00 PM to 9.00 PM Daily)
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                } else {
                  return (
                    <div>
                      <div
                        className="_3C_gI gradient-primary"
                        role="button"
                        tabIndex="0"
                      >
                        <div className="Cxph3">
                          <img
                            src={`${process.env.PUBLIC_URL}/assets/images/changez_multani.png`}
                            className="changu"
                            alt=""
                          />
                        </div>
                        <div className="NXGft">
                          <div className="_14v1V">Talk To Changez Multani</div>
                          <div className="_3oKxo">
                            Social Worker and Ex-corporator
                          </div>
                          <div className="_3oKxo">
                            Chat Between (6.00 PM to 9.00 PM Daily)
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                }
              })()}
            </div>
          </div>
        </section>

		 <section className="p-0 margin-pd " id="classic">
          <div class="swiper-container swiper-container2">
            <Slider {...settings}>
              {this.state.footer_banner_list.map((list) =>
                (() => {
                  if (localStorage.getItem("userData")) {
                    var storedArray = JSON.parse(
                      localStorage.getItem("userData"),
                    );
                    var apps = storedArray["data"]["classic_user"];
                    var url = list.url;
                  }
                  if (apps == "0" && url == "classic_broadband") {
                    return (
                      <div id={`${list.id}`}>
                        <a target="_blank" href={list.url}>
                          <img src={`${list.image}`} />
                        </a>
                      </div>
                    );
                  } else if (apps == "1" && url == "classic_broadband") {
                    return (
                      <div id={`${list.id}`}>
                        <Link to={`${process.env.PUBLIC_URL}/classic_profile`}>
                          <img src={`${list.image}`} />
                        </Link>
                      </div>
                    );
                  } else {
                    return (
                      <div id={`${list.id}`}>
                        <a target="_blank" href={list.url}>
                          <img src={`${list.image}`} />
                        </a>
                      </div>
                    );
                  }
                })(),
              )}
            </Slider>
          </div>
        </section>

        {this.state.TrackCart.map((track) => (
          <div>
            {(() => {
              if (track.status != "Delivered" && track.status != "Cancelled") {
                return (
                  <div>
                    <div className="float-cart home-cart">
                      <Link
                        className=""
                        to={`${process.env.PUBLIC_URL}/track_order/}`}
                      >
                        <span className="count_item">
                          {(() => {
                            if (track.status == "Order Placed") {
                              return (
                                <div className="home_track_img">
                                  <img
                                    src={`${process.env.PUBLIC_URL}/assets/images/icon/order-placed2.gif`}
                                  />
                                  <div class="pull-right">
                                    Order Placed
                                    <br />
                                    <span className="res_name">
                                      {track.restaurant_name}
                                    </span>
                                  </div>
                                </div>
                              );
                            } else if (
                              track.status == "Confirm By Restaurant"
                            ) {
                              return (
                                <div className="home_track_img">
                                  <img
                                    src={`${process.env.PUBLIC_URL}/assets/images/icon/order-preparing.gif`}
                                  />
                                  <div class="pull-right">
                                    Confirm By Restaurant
                                    <br />
                                    <span className="res_name">
                                      {track.restaurant_name}
                                    </span>
                                  </div>
                                </div>
                              );
                            } else if (track.status == "Out For Delivery") {
                              return (
                                <div className="home_track_img">
                                  <img
                                    src={`${process.env.PUBLIC_URL}/assets/images/icon/order-onway.gif`}
                                  />
                                  <div class="pull-right">
                                    Out For Delivery
                                    <br />
                                    <span className="res_name">
                                      {track.restaurant_name}
                                    </span>
                                  </div>
                                </div>
                              );
                            }
                          })()}
                        </span>
                        <span class="pull-right track-text">
                          Track Order <i class="si si-basket"></i>
                        </span>
                        <canvas height="0" width="0" class="canvas"></canvas>
                      </Link>
                    </div>
                    <div>
                      <br />
                      <br />
                    </div>
                  </div>
                );
              }
            })()}
          </div>
        ))}
      </div>
    );
  }
}

export default Home;
