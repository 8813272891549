import React, { Component } from 'react';
import { Link, NavLink} from 'react-router-dom';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from 'react-loader-spinner';
import {getListData} from '../../../services/index';
import '../../common/restaurant.scss';
import {connect} from 'react-redux';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

class RestaurantDetails extends Component {
constructor(props) {
	super(props);
	this.state = {
        openCart: '',
        countItem: 0,
        countTotal: 0,
		ListData: [],
		qty: 0,
		openQuantity: false,
        quantity: 1,
        image: ''
	};
	if (!localStorage.getItem('userData')) {
		this.props.history.push("login");
	}
}
componentDidMount() {
	document.getElementById("color").setAttribute("href", `#`);
	document.querySelector(".loader-wrapper").style = "display: block";
	setTimeout(function() {
    document.querySelector(".loader-wrapper").style = "display: none";
}, 900);
this.getData();
}

getData = () => {
		var storedArray = JSON.parse(localStorage.getItem("userData"));
		this.state.user_id=storedArray['data']['user_id'];
		getListData('labs/all_package', this.state).then((result) => {
		let responseJson = result;
		if (responseJson.data) {
			if (result['count'] > 0) {
				this.setState({
					ListData: result['data']
				})
			}
		}
		});
    }



render() {

return (
<div>

   <div>
   <section className="pb-0 margin-pd mt-45 restro">
      <div className="_2u0oK _1iY8Y">
         <div className="_3TzfO page_head">
            <div className="_2QN4q">
               <Link to={`${process.env.PUBLIC_URL}/lab_test`}>
               <img src={`${process.env.PUBLIC_URL}/assets/images/icon/return_white.png`} alt="" /></Link>
            </div>
            <div className="_1leRb page_title res-title">
               Neulife Healthcare Center
            </div>
            <div className="b5NJg"></div>
         </div>
      </div>
     

</section>


<section className="pb-0 margin-pd restro details-pad-top-n cart-bottom">

   <div className="res-details-div details-padding" role="button" tabIndex="0">
      <div className="res-details-info">
	
	{this.state.ListData.map((list) => (	
	<div>
      <div class="product-box">
         <div class="img-wrapper">
            <div class="details-dish-con addtocart_btn border-bottom">
               <div class="details-dish-title details-color max-width">
			   <img className="order-img" src={`${process.env.PUBLIC_URL}/assets/images/icon/all_test.png`} alt="" />
			   {list.title}</div>
               <div class="details-dish-title details-dish-price">Includes {list.product_count} tests</div>
               <div class="details-dish-title details-dish-price"><i class="fa fa-inr" aria-hidden="true"></i>{list.price}</div>
               <div>
					<Link to={`${process.env.PUBLIC_URL}/labs_package_details/${list.id}`}>
					<span class="details-span"> View Details</span>
					</Link>
					<button class="details-add-btn add_cart"> Select</button>
				</div>
            </div>
         </div>
      </div>
    </div>
			))}
	
	
      </div>
   </div>
</section>



<div className={`float-cart show-cart`} id="float-cart">
<span id="count_item" className="count_item">5 Tests</span>
<span>&nbsp;|&nbsp;</span>
<span id="count_total" className="count_total">Rs. 3785</span>
<span class="pull-right">View Cart <i class="si si-basket"></i></span>
<canvas height="0" width="0" class="canvas"></canvas>

</div>

</div>




</div>
)
}
}
	
export default RestaurantDetails;