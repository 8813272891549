import React, { Component } from 'react';
import { Link, NavLink} from 'react-router-dom';
import '../../common/restaurant.scss';
import {getListData} from '../../../services/index';
import $ from 'jquery';
import {Redirect} from 'react-router-dom';
import { Item} from "../../layouts/home/components";
import Carousel from "../../layouts/home/Carousel";
import Slider from 'react-slick';


class Home extends Component {
	constructor(){
		super();
		var storedArray = JSON.parse(localStorage.getItem("userData"));
		this.state = {
			user_name: '',
			mobile: '',
			email: '',
			package: '',
			user_id: storedArray['data']['user_id'],
			
		};
		this.addadress = this.addadress.bind(this);
		this.onChange = this.onChange.bind(this);
		this.handleChange = this.handleChange.bind(this);
	}
	handleChange(event) {
	  this.setState({
		package: event.target.value
	  });
	}
	onChange(e){
		this.setState({[e.target.name]:e.target.value});
	}
	addadress() {
		if(this.state.user_name || this.state.mobile || this.state.email){
			
			getListData('auth/fitness_enquiry',this.state).then((result) => {
			if(result.status == 300){
				document.getElementById('alert').innerHTML='<div class="alert alert-warning" role="alert"><i class="fa fa-info-circle" aria-hidden="true"></i> Please insert all fields</div>';
			}
			else{
				document.getElementById('alert').innerHTML='';
				this.setState({redirectToReferrer: true});
			}
			
			});
		}
		if(this.state.user_name === '' && this.state.mobile === '' && this.state.email === ''){
			document.getElementById('alert').innerHTML='<div class="alert alert-warning" role="alert"><i class="fa fa-info-circle" aria-hidden="true"></i> Please insert all fields </div>';
		}
		else{
			document.getElementById('alert').innerHTML='';
		}
	}
	componentDidMount() {
        document.getElementById("color").setAttribute("href", `#` );		
		document.querySelector(".loader-wrapper").style = "display: block";
	setTimeout(function() {
    document.querySelector(".loader-wrapper").style = "display: none";
}, 900);
		
		
			var modal = document.getElementById("myModal");
			var btn = document.getElementById("myBtn");
			var span = document.getElementsByClassName("close")[0];
			btn.onclick = function() {
			  modal.style.display = "block";
			}
			span.onclick = function() {
			  modal.style.display = "none";
			}
			window.onclick = function(event) {
			  if (event.target == modal) {
				modal.style.display = "none";
			  }
			}
			if (localStorage.getItem('userData')){
				var storedArray = JSON.parse(localStorage.getItem("userData"));
			}

			
			
		
    }

	render() {
	if (this.state.redirectToReferrer){
			return (<Redirect to={`${process.env.PUBLIC_URL}/`}/>)
		}
		const settings = {
			dots: true,
			infinite: true,
			speed: 300,
			slidesToShow: 1,
			centerMode: true,
			variableWidth: true
		  };	
		return (
			<div>
                
				
                 
                {/*Home Section End*/}

                {/*collection banner*/}
                <section className="pb-0 margin-pd mt-45 restro">
					<div className="_2u0oK _1iY8Y">
						<form>
						   <div className="_3TzfO page_head">
							  <div className="_2QN4q">
								 <Link to={`${process.env.PUBLIC_URL}/`}>
								 <img src={`${process.env.PUBLIC_URL}/assets/images/icon/return_white.png`} alt="" /></Link>
							  </div>
							  <div className="_1leRb page_title">
								 Gym Fitness
							  </div>
							  <div className="b5NJg"></div>
						   </div>
						   <input type="submit" hidden=""/>
						</form>
					 </div>
				    
                </section>
				
				 {/*collection banner*/}
                <section className="pb-0 margin-pd restro details-pad-top-n">
				    
                    <div className="add-details details-padding gym-pad-bot" role="button" tabIndex="0">
						<div className="res-details-info col-md-12">
							<div class="collection-banner p-left text-left">
								<img src={`${process.env.PUBLIC_URL}/assets/images/gym/gym_fitness.jpg`} alt="" class="img-fluid blur-up lazyload bg-img" />
								<div class="absolute-contain">
									<h3>BODY KRAFT</h3>
									<h4>Muscle & Fitness Center</h4>
									<h4>(fully air condition) unisex</h4>
								</div>
							</div>
							
						</div>
					</div>
                </section>
				<section className="pb-0 margin-pd mt-10 restro details-pad-top-n">
				    
                    <div className="add-details details-padding" role="button" tabIndex="0">
						<div className="res-details-info col-md-12">
							<div className="col-md-12  no-pad">
							<h3 className="nowrap restro _14v1V details-title-col"><i class="fa fa-cog" aria-hidden="true"></i> Offering</h3>
								<div className="details-color "> <i class="fa fa-check gym-green" aria-hidden="true"></i> Gymnasium Cardio</div>
								<div className="details-color "> <i class="fa fa-check gym-green" aria-hidden="true"></i> Nutritionist</div>
								<div className="details-color "> <i class="fa fa-check gym-green" aria-hidden="true"></i> Weight Loss / Gain Programme</div>
								<div className="details-color "> <i class="fa fa-check gym-green" aria-hidden="true"></i> Dietitian</div>
								<div className="details-color " > <i class="fa fa-check gym-green" aria-hidden="true"></i> FatLoss</div>
								<div className="details-color "> <i class="fa fa-check gym-green" aria-hidden="true"></i> Muscle Gain</div>
								<div className="details-color "> <i class="fa fa-check gym-green" aria-hidden="true"></i> Body Massage</div>
								
							</div>
						</div>
					</div>
                </section>
				<section className="pb-0 margin-pd mt-10 restro details-pad-top-n">
				    
                    <div className="add-details details-padding" role="button" tabIndex="0">
						<div className="res-details-info col-md-12 fitness-gal">
							<div className="col-md-12  no-pad">
							<h3 className="nowrap restro _14v1V details-title-col"><i class="fa fa-picture-o" aria-hidden="true"></i> Gallery</h3>
							<Link to={`${process.env.PUBLIC_URL}/gallery`}>
								<div className="">
									<div className="">
										<div className="col-md-4 no-pad gym">
											<img src="assets/images/gym/1.jpeg" alt="" />
										</div>
										<div className="col-md-4 no-pad gym">
											<img src="assets/images/gym/2.jpeg" alt="" />
										</div>
										<div className="col-md-4 no-pad gym">
											<img src="assets/images/gym/3.jpeg" alt="" />
										</div>
									</div>
									<br/>
									<p class="no-margin">&nbsp;</p>
									<div className="col-md-12 no-pad center"><b class="mt-10">View More</b></div>
								</div>	
							</Link>
							</div>
						</div>
					</div>
                </section>
				
				
					
				
				 <section className="pb-0 margin-pd mt-10 restro details-pad-top-n fitness">
                    <div className="add-details details-padding" role="button" tabIndex="0">
						<div className="res-details-info col-md-12 no-pad">
							<div className="col-md-12">
								<h3 className="nowrap restro _14v1V details-title-col"><i class="fa fa-inr" aria-hidden="true"></i> Gym Charges</h3>
							</div>
							
							<Slider {...settings}>
								
								<div>
									<div className="fitness-bg-white"> 
										<div class="product-box">
											<div class="product-detail">
												<div>
													<h6 className="details-title-col mb-5">Quarterly</h6>
													<h4><i class="fa fa-inr" aria-hidden="true"></i> 3000 <del><span class="money"><i class="fa fa-inr" aria-hidden="true"></i> 3200</span></del></h4>
												</div>
											</div>
										</div>
									</div>						
								</div>
								<div>
									<div className="fitness-bg-white"> 
										<div class="product-box">
											<div class="product-detail">
												<div>
													<h6 className="details-title-col mb-5">Half Yearly</h6>
													<h4><i class="fa fa-inr" aria-hidden="true"></i> 5000 <del><span class="money"><i class="fa fa-inr" aria-hidden="true"></i> 5500</span></del></h4>
												</div>
											</div>
										</div>
									</div>						
								</div>
								<div>
									<div className="fitness-bg-white"> 
										<div class="product-box">
											<div class="product-detail">
												<div> 
													<h6 className="details-title-col mb-5" >Yearly</h6>
													<h4><i class="fa fa-inr" aria-hidden="true"></i> 9000 <del><span class="money"><i class="fa fa-inr" aria-hidden="true"></i> 7000</span></del></h4>
												</div>
											</div>
										</div>
									</div>						
								</div>
								<div>
									<div className="fitness-bg-white"> 
										<div class="product-box">
											<div class="product-detail">
												<div>
													<h6 className="details-title-col mb-5">Couple</h6>
													<h4><i class="fa fa-inr" aria-hidden="true"></i> 12000 <del><span class="money"><i class="fa fa-inr" aria-hidden="true"></i> 15000</span></del></h4>
												</div>
											</div>
										</div>
									</div>						
								</div>
								<div>
									<div className="fitness-bg-white"> 
										<div class="product-box">
											<div class="product-detail">
												<div>
													<h6 className="details-title-col mb-5">Monthly</h6>
													<h4><i class="fa fa-inr" aria-hidden="true"></i> 1200 <del><span class="money"><i class="fa fa-inr" aria-hidden="true"></i> 1500</span></del></h4>
												</div>
											</div>
										</div>
									</div>						
								</div>
								
       						 </Slider>
							
						</div>
					</div>
                </section>
				<section className="pb-0 margin-pd mt-10 restro details-pad-top-n">
					<div className="add-details details-padding" role="button" tabIndex="0">
						<div className="res-details-info col-md-12">
							<div className="col-md-12  no-pad">
							<h3 className="nowrap restro _14v1V details-title-col"><i class="fa fa-clock-o" aria-hidden="true"></i> Timings</h3>
							</div>
							<span class="block details-color">Morning</span>
							<span class="block details-fl-left">6:00 AM TO 11:00 AM</span><br/>
							<span class="block details-color">Evening</span>
							<span class="block details-fl-left">4:00 PM TO 11:00 PM</span><br/>
							<span class="block details-color">Only For Ladies</span>
							<span class="block details-fl-left">11:00 AM TO 5:00 PM</span>
														
						</div>
					</div>
					
                </section>
				 <section className="pb-0 margin-pd restro details-pad-top-n">
					
					<div className="order-btn">
					 
						<button className="order-button">
							<span className="order-conf2" id="myBtn">Submit Enquiry</span>
						</button>
						
						<div id="myModal" class="modal">
						<div class="modal-header">
						  <button type="button" class="close" data-dismiss="modal">&times;</button>
						  <h4 class="modal-title">Enquiry Now</h4>
						</div>
						  <div class="modal-content">
							<div className="wrap-login200">
										<form className="login100-form validate-form">
											<div className="form-group-1 float-label-1 validate-input" data-validate="Enter user_name">
												<input type="text" className="form-control-1" name="user_name" autoComplete="off" onChange={this.onChange}/>
												<label className="color lab-top"> User Name</label>
											</div>
											<div className="form-group-1 float-label-1 validate-input" data-validate="Enter mobile">
												<input type="text" className="form-control-1" name="mobile" autoComplete="off" onChange={this.onChange}/>
												<label className="color lab-top"> Mobile No.</label>
											</div>
											<div className="form-group-1 float-label-1 validate-input" data-validate="Enter email">
												<input type="email" className="form-control-1" name="email" autoComplete="off" onChange={this.onChange}/>
												<label className="color lab-top"> Email</label>
											</div>
											<div className="tag text-left mb-20">
											<label className="color"> Package </label>	<br/>
												<div class="select">
												<select name="package" id="package" value={this.state.package} onChange={this.onChange}>
												  <option value="monthly">Monthly</option>
												  <option value="quarterly">Quarterly</option>
												  <option value="half_yearly">Half Yearly</option>
												  <option value="yearly">Yearly</option>
												  <option value="couple">Couple</option>
												</select>
												</div>
											<div id="alert" className="m-b-10"></div>	

											</div>
											<div className="col-md-12 center mb-20">
													<input type="button"  className="login100-form-btn" value="Send" onClick={this.addadress}/>
											</div>
										</form>
									</div>
						  </div>
						  <div class="modal-footer">
						  </div>
						</div>
						
					</div>
                </section>
				


 
			</div>
			
			)

	}
}

export default Home;
