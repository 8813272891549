import React, { Component } from 'react';
import { Link, NavLink} from 'react-router-dom';
import '../../common/restaurant.scss';
import { connect } from 'react-redux'
import {getListData} from '../../../services/index';
import $ from 'jquery';
import moment from 'moment';
import { DatePicker } from 'antd';
//import 'antd/dist/antd.css';
import {Redirect} from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert'; // Import


const { MonthPicker, RangePicker } = DatePicker;


function range(start, end) {
  const result = [];
  for (let i = start; i < end; i++) {
    result.push(i);
  }
  return result;
}

function disabledDate(current) {
  // Can not select days before today and today
  return current && current < moment().endOf('day');
}

function disabledDateTime() {
  return {
    disabledHours: () => range(0, 24).splice(4, 20),
    disabledMinutes: () => range(30, 60),
    disabledSeconds: () => [55, 56],
  };
}

class cartComponent extends Component {
    constructor(props) {
        super(props);
		this.state = {
			ListData: [],
			CartData: [],
			itemData: [],
			date : ''
		};
		this.goBack = this.goBack.bind(this);
		
		this.minus_cart = this.minus_cart.bind(this);
		this.confirmbook = this.confirmbook.bind(this);
    }
    handleChange(event) {
		this.setState({
			payment_method: event.target.value
		});
	}
    componentDidMount() {
        document.getElementById("color").setAttribute("href", `#`);
        document.querySelector(".loader-wrapper").style = "display: block";
		setTimeout(function() {
			document.querySelector(".loader-wrapper").style = "display: none";
		}, 900);

        $('.input-group').on('click', '.button-plus', function(e) {
           
        });
        $('.input-group').on('click', '.button-minus', function(e) {
            
        });
		
		this.getData();
	
    }
	
	 getData = () => {
		let restaurant_id = localStorage.getItem("restaurant_id");	
		let cartItems ='0';	
		var storedArray = JSON.parse(localStorage.getItem("userData"));
		this.state.restaurant_id = restaurant_id;		
		var app = {
			restaurant_id: restaurant_id,
			user_id: storedArray['data']['user_id']
		}
		getListData('beautyparlour/cart_list', app).then((result) => {
			let responseJson = result;
			if (responseJson.data) {
				if (result.data.length > 0) {
					this.setState({
						cartItems:result['count'],
						CartData: result['data'],						
					    itemData: result['data'][0]['items']
					})
				}
				else{
					this.setState({
						cartItems:result['count']
					})
				}
			}
			
		});
    }

    minusQty = (id) => {
        if(this.state.quantity > 1) {
            this.setState({quantity: this.state.quantity - 1});
			this.minus_cart(id);
        }else{
            console.log('removefromcart');			
            this.setState({openQuantity:false});		
			this.minus_cart(id);
        }
    }

    plusQty = (id) => {
        this.setState({quantity: this.state.quantity+1});
		this.plus_cart(id);
    }

    openQuantity = (id) => {
        this.setState({openQuantity:true});
		this.add_cart(id);
    }
	
	minus_cart(id) {
		var storedArray = JSON.parse(localStorage.getItem("userData"));
			
        var item_id = document.getElementById("item_id"+id).value;
        var item_price = document.getElementById("item_price"+id).value;
        var item_qty = document.getElementById("item_qty"+id).value;
		var app = {
			user_id: storedArray['data']['user_id'],
			item_id: item_id,
			item_qty: parseInt(item_qty)-1,
			item_price: item_price
		}
		getListData('beautyparlour/cart_update', app).then((result) => {
			this.getData();
		});
		this.getData();
	}
	
	
	goBack(){
		this.props.history.goBack();
	}
	
	onChange = (date) =>{
	
	this.setState({date: date});
	}
	
	confirmbook = (id) => {
	var order_date = this.state.date;
	if(order_date != ''){
	var storedArray = JSON.parse(localStorage.getItem("userData"));
		
		var item_id = document.getElementsByClassName("item_id");
        var item_name = document.getElementsByClassName("item_name");
        var item_price = document.getElementsByClassName("item_price");
        var item_qty = document.getElementsByClassName("item_qty");
		var item_id_array = '';
		var item_name_array = '';
		var item_price_array = '';
		var item_qty_array = '';
		for(var i=0; i<item_id.length; i++) {
			item_id_array += item_id[i].value+',';
		}
		for(var i=0; i<item_name.length; i++) {
			item_name_array += item_name[i].value+',';
		}
		for(var i=0; i<item_price.length; i++) {
			item_price_array += item_price[i].value+',';
		}
		for(var i=0; i<item_qty.length; i++) {
			item_qty_array += item_qty[i].value+',';
		}
		var no_of_items=item_id.length;
		var app = {
			user_id: storedArray['data']['user_id'],
			no_of_items: '1',
			item_id: item_id_array,
			item_name: item_name_array,
			item_qty: item_qty_array,
			item_cost: item_price_array,
			order_date: order_date,
		}
		getListData('beautyparlour/order_add', app).then((result) => {
		if (result.status == 200) {
			this.setState({redirectToReferrer: true});
		}
		});
		}else{
		confirmAlert({
		  title: 'Please Select Date',
		  message: '',
		  buttons: [
		  {
			  label: 'ok',
			  onClick: () => {}
			}
		  ]
		});
		}
	}

render() {
if (this.state.redirectToReferrer){
			return (<Redirect to={`${process.env.PUBLIC_URL}/physio_thanks`}/>)
		}
var restaurant_id = localStorage.getItem("restaurant_id");
return (
<div class="m-h-830">
	
   <section className="pb-0 margin-pd mt-45 restro cart-head">
      <div className="_2u0oK _1iY8Y">
         <form>
            <div className="_3TzfO page_head">
               <div className="_2QN4q">
                  
                  
                  <Link to={`${process.env.PUBLIC_URL}/beauty_parlour`}>
                     <img src={`${process.env.PUBLIC_URL}/assets/images/icon/return_white.png`} alt="" />
                  </Link>   
                  
               </div>
               <div className="_1leRb page_title">
                  Beaty Parlour Cart
               </div>
               <div className="b5NJg"></div>
            </div>
            <input type="submit" hidden=""/>
         </form>
      </div>
   </section>
   
   
   {this.state.cartItems>0 ?
   <div class="">
   <div class="no-margin-10">
      <div class="bg-white">
         <div class="pt-50 res-img-padding">
            
			{this.state.CartData.map((list) => (	
			<div>
            <div class="block-content block-content-full">
               <img src="https://classicxtra.com/bucket/banner/WhatsApp Image 2019-11-02 at 4.07.15 PM (1).jpeg" class="restaurant-image"/>
            </div>
            <div class="block-content block-content-full restaurant-info">
               <div class="font-w600 mb-5 res-cart-title">Maiza Beauty Studio</div>
               <div class="text-muted truncate-text">Certified By Shabnam Zaveri</div>
            </div></div>
             )
            )}
         </div>
      </div>
      <div class="block-content block-content-full bg-white pt-50 pb-0">
		 {this.state.itemData.map((item) => (	
         <div>
            
            <div class="cart-item-meta pt-15 pb-15">
               <div class="cart-item-name"><img className="order-img2" src={`${process.env.PUBLIC_URL}/assets/images/restaurants/${item.item_type_image}`} alt="" /> {item.item_name}</div>
			   <input type="hidden" class="item_name" name="item_name[]" value={item.item_name} />
			   <input type="hidden" class="item_id" name="item_id[]" value={item.item_id} />
			   <input type="hidden" class="item_price" name="item_price[]" value={item.item_price} />
			   <input type="hidden" class="item_qty" name="item_qty[]" value={item.item_qty} />
               <div className="cart-box ">
                  <div className="qty-box">
                     <div className="input-group">
                        
						<span className="details-add-btn add_cart t-15" onClick={this.minusQty.bind(this,item.item_id)} > Remove</span>
						<input type="hidden" id={`item_id${item.item_id}`} value={item.item_id}/>
						<input type="hidden" id={`item_price${item.item_id}`} value={item.item_price}/>
                        <input type="hidden"   id={`item_qty${item.item_id}`} name="quantity" value={item.item_qty} readOnly={true} className="form-control input-number" />
                        
                     </div>
                  </div>
               </div>
               <div class="cart-item-price">
				    
			   <i className="fa fa-inr" aria-hidden="true"></i>  {item.item_total}</div>
            </div>
         </div>
         ))}
      </div>

   </div>
   
   
   
    <div class="no-margin-10 mt-10">
      <div class="bg-white bill-details mb-20">
         <div class="p-15 center" >
		 <h2 class="bill-detail-text m-0">Select Date For Booking</h2>
		  <DatePicker
		  format="YYYY-MM-DD"
		  disabledDate={disabledDate}
		  disabledTime={disabledDateTime}
		  name = "book_date"
		  id = "book_date"
		  onChange={this.onChange}
			required/>
		 </div>
	  </div>
   </div>
   
 
   
   
   <div class="no-margin-10">
      <div class="bg-white bill-details mb-20">
         <div class="p-15">
            <h2 class="bill-detail-text m-0">Bill Details</h2>
           
			{this.state.CartData.map((list) => (
			<div>
			 <div class="display-flex">
               <div class="flex-auto">Item Total</div>
               <div class="flex-auto text-right"><i className="fa fa-inr" aria-hidden="true"></i> {list.total}</div>
            </div>
            
            <hr/>
            
            <div class="display-flex">
               <div class="flex-auto font-w700">To Pay</div>
               <div class="flex-auto text-right font-w700"><i className="fa fa-inr" aria-hidden="true"></i> {list.grand_total}</div>
            </div>
			</div>
			))}
         </div>
      </div>
	 
   </div>
   
   
   
  
   <div className="order-btn" >
		
      <button className="order-button white" onClick={this.confirmbook.bind(this,1)} >Book Now</button>
   </div>
   
   </div>
   :
    <section className="cart-section">
        <div className="col-sm-12 empty-cart-cls text-center">
            <img className="img-fluid mb-4 empty-cart" src={`${process.env.PUBLIC_URL}/assets/images/icon/empty_cart.png`} alt="" />
        </div>
    </section>
}
  
   
</div>
)
}
}

export default cartComponent;