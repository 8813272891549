import React, {Component} from 'react';
import { Link, NavLink} from 'react-router-dom';
import $ from 'jquery';

class SubmitComplaint extends Component {


componentDidMount() {
document.querySelector(".loader-wrapper").style = "display: block";
	setTimeout(function() {
    document.querySelector(".loader-wrapper").style = "display: none";
}, 900);
	$('.float-label .form-control[placeholder]').each(function () {
		if ($(this).attr('placeholder').length > 0) { $(this).addClass('active');}
	});
	$('.float-label .form-control[value]').each(function () {
		if ($(this).val.length > 0) { $(this).addClass('active');}
	});
	$('.float-label .form-control').on('blur', function () {
		if ($(this).val().length > 0) { $(this).addClass('active');} else {
			if ($(this)[0].hasAttribute('placeholder') === true) {
				if ($(this).attr('placeholder').length > 0) { $(this).addClass('active');} 
				else { $(this).removeClass('active');}
			} else { $(this).removeClass('active');}
		}
	});
	
	function date_format(date){
	var fromDate = new Date(date);
	return new Date(fromDate).toDateString("dd-MM-yyyy");
}
	

}
constructor (props) {
super (props) ;
}


 

render (){
return (
<div>
   <section className="authentication-page section-b-space margin-pd">
      <div className="_3lldG pt-20">
         <div className="_2u0oK _1iY8Y border-blue">
            <form>
               <div className="_3TzfO page_head">
                  <div className="_2QN4q">
                     <Link to={`${process.env.PUBLIC_URL}/classic_broadband`}>
                     <img src={`${process.env.PUBLIC_URL}/assets/images/icon/return_white.png`} alt="" /></Link>
                  </div>
                  <div className="_1leRb page_title">
                     Submit Complaint
					</div>
                  <div className="b5NJg"></div>
               </div>
               <input type="submit" hidden=""/>
            </form>
         </div>
         <div className="_3mMtt dashboard m-plan">	
		  
		        <section className="renew-tab tab-product m-0">
                <div className="row">
                    <div className="col-sm-12 col-lg-12">
                        <div className="tab-content nav-material">                            
                            <div className="tab-pane fade mt-4 show active">
							<div className="tab-m-header"> 
								<p>Create Ticket</p>
								<span>Select a Problem</span>		
							</div>
																									
								<form className="m-form form-mlr">	
									<div className="form-group float-label validate-input" data-validate="Enter username">
										<select className="form-control" name="username" autoComplete="off">
										<option>Select Option</option>
										<option>Authentication issue</option>
										<option>Browsing Related-Few sites not opening</option>
										<option>Browsing Related-No Browsing</option>
										<option>Browsing Related-Slow Browsing</option>
										<option>Error 678/769</option>
										<option>Frequent Disconnection</option>
										<option>Modem Reconfiguration</option>
										<option>Network & Connectivity Related</option>
										<option>Page Can't be displayed</option>
										<option>Router related</option>
										<option>Wi Fi connectivity issues</option>
										<option>Wi Fi Modem Installation</option>
										</select>
									</div>
						
									<div className="container-login100-form-btn">				
										<input type="button"  className="btn btn-primary btn-block ripple" value="Submit"/>
									</div>								
								</form>	
								
	
			  
		        <section className="renew-tab tab-product m-0 bg-purple tickets">
                <div className="row">
                    <div className="col-sm-12 col-lg-12">
						<div className="col-12">
								<p className="text-blue account-usage">Ticket History</p>								
								<p className="text-blue ticket-subtitle">Last Ten Complaints</p>		
							</div>	
						<div className="plans_card">
								<div className="card rounded-0 border-0 text-center pricing mb-3">
									<div className="card-body ticket-open">							
										<h4 className="plan-title">10 Aug, 2019</h4>	
										<table className="table  mb-0 table-plan">
											<tbody> 								
											<tr>
											<th><i className="fa fa-ticket" aria-hidden="true"></i> Problem</th>
												<td> Browsing Related-Slow Browsing</td>
											</tr>
											<tr>
												<th>Ticket No.</th>
												<td>CA870</td>
											</tr>
											<tr>
												<th>Status</th>
												<td class="t_status">Open</td>
											</tr>
											</tbody>
										</table>
									</div>								
								</div>
							</div>		

							<div className="plans_card">
								<div className="card rounded-0 border-0 text-center pricing mb-3">
									<div className="card-body ticket-close">							
										<h4 className="plan-title">10 Aug, 2019</h4>	
										<table className="table  mb-0 table-plan">
											<tbody> 								
											<tr>
											<th><i className="fa fa-ticket" aria-hidden="true"></i> Problem</th>
												<td> Authentication issue</td>
											</tr>
											<tr>
												<th> Ticket No.</th>
												<td>JP500</td>
											</tr>	
											<tr>
												<th>Date Closed</th>
												<td>17 Aug, 2019</td>
											</tr>
											<tr>
												<th> Status</th>
												<td class="t_status">Close</td>
											</tr>
											</tbody>
										</table>
									</div>
								
								</div>
							</div>		
	

	

							
				               
                    </div>
                </div>
            </section>
		 
			
                            </div>
                            
                        </div>
                    </div>
                </div>
            </section>
		 
		 
			
         </div>
      </div>
   </section>
</div>
)
}
}
export default SubmitComplaint