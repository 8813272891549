import React, { Component } from 'react';
import { Link, NavLink} from 'react-router-dom';
import '../common/restaurant.scss';
import {getListData} from '../../services/index';
class Home extends Component {
	constructor(props) {
        super(props);
		this.state = {
			ListData: []
		};
		if(!localStorage.getItem('userData')) {
		   this.props.history.push("login");
        }
    }

    componentDidMount() {
        document.getElementById("color").setAttribute("href", `#` );		
		document.querySelector(".loader-wrapper").style = "display: block";
		setTimeout(function() {
            document.querySelector(".loader-wrapper").style = "display: none";
        }, 900);
		var storedArray  = JSON.parse(localStorage.getItem("userData"));
        this.state.user_id = storedArray['data']['user_id'];
		getListData('restaurant/order_list',this.state).then((result) => {
		let responseJson = result;
			if(responseJson.data){
			    if(result['count']>0){
					this.setState({ 
						ListData: result['data']
					})				
					
				}else{
					//document.getElementById("loader").style = "display: none";
					document.getElementById('alert').innerHTML='<div class="_2czoF"><div class="_1KevM empty-rest">No Order!</div></div>';
					
				}
			}
		});
    }
	

	render() {
		return (
			<div class="my-orders">

                <section className="pb-0 margin-pd mt-45 restro">
					<div className="_2u0oK _1iY8Y">
						<form>
						   <div className="_3TzfO page_head">
							  <div className="_2QN4q">
								 <Link to={`${process.env.PUBLIC_URL}/my_order`}>
								 <img src={`${process.env.PUBLIC_URL}/assets/images/icon/return_white.png`} alt="" /></Link>
							  </div>
							  <div className="_1leRb page_title">
								My Orders
							  </div>
							  <div className="b5NJg"></div>
						   </div>
						   <input type="submit" hidden=""/>
						</form>
					 </div>
				    
                </section>

                <section className="pb-0 margin-pd restro details-pad-top-n">
				<div id="alert" className="m-b-10"></div>
				{this.state.ListData.map((list) => (
				<Link to={`${process.env.PUBLIC_URL}/order_history/${list.order_id}`}>
				    <div className="res-details-div" role="button" tabIndex="0">
						<div className="res-details-info">
							<div className="order-image">
								<div className="bgimg" style={{backgroundImage: `url(${list.restaurant_image})`}}>
								</div>
							</div>
							<div className="_14v1V center">
								<div className="nowrap details-title-col left order-bot-mar">{list.restaurant_name}</div>
								<div class="nowrap order-title left order-bot-mar">{list.restaurant_tag}</div>
							</div>

							
							<table class="table details-dish-con order_details">
    <tbody>
      <tr>
        <td class="order-title">Ordered On</td>
        <td class="details-color">{list.order_date}</td>
      </tr>
      <tr>
        <td class="order-title">Total Amount</td>
        <td class="details-color"><i class="fa fa-inr" aria-hidden="true"></i> {list.total_cost}</td>
      </tr>
      <tr>
        <td class="order-title">Order Status</td>
        <td class="details-color">{list.status}</td>
      </tr>
    </tbody>
  </table>
						</div>
					</div>
				</Link>
					))}
					
                    
					
                </section>
				 
				


 
			</div>
			
			)

	}
}

export default Home;
