import React, { Component } from 'react';
import { Link, NavLink} from 'react-router-dom';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from 'react-loader-spinner';
import {getListData} from '../../../services/index';
import '../../common/restaurant.scss';
import {connect} from 'react-redux';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

class RestaurantDetails extends Component {
constructor(props) {
	super(props);
	this.state = {
        openCart: '',
        countItem: 0,
        countTotal: 0,
		ListData: [],
		qty: 0,
		openQuantity: false,
        quantity: 1,
        image: '',
        name: '',
		
	};
	
	if (!localStorage.getItem('userData')) {
		this.props.history.push("login");
	}
}
componentDidMount() {
	document.getElementById("color").setAttribute("href", `#`);
	document.querySelector(".loader-wrapper").style = "display: block";
	setTimeout(function() {
    document.querySelector(".loader-wrapper").style = "display: none";
	}, 900);
	
	var storedArray = JSON.parse(localStorage.getItem("userData"));
	
	this.state.mobile=storedArray['data']['mobile'];
	this.checkData();
	
	
}


checkData() {
	
	var storedArray = JSON.parse(localStorage.getItem("userData"));
	this.state.mobile=storedArray['data']['mobile'];
	var mobile = storedArray['data']['mobile'];
	var app = {
		phone : mobile
	}
	getListData('society/SocietyController/society_list', app).then((result) => {
		let responseJson = result;
		if (responseJson.data) {
			if (result['status'] == 200) {
				this.setState({
					ListData: result['data'],
					name: result['data'][0]['name'],
				})
			}
		}
	});
	
}
   

   
	
	
	

	
	
	
	
	
	
	
	

render() {


var lenght = this.state.ListData.length;

return (
<div >
   
   <div>
   <section className="pb-0 margin-pd mt-45 restro">
      <div className="_2u0oK _1iY8Y">
         <div className="_3TzfO page_head">
            <div className="_2QN4q">
               <Link to={`${process.env.PUBLIC_URL}/`}>
               <img src={`${process.env.PUBLIC_URL}/assets/images/icon/return_white.png`} alt="" /></Link>
			   
            </div>
            <div className="_1leRb page_title res-title">
               My Society
            </div>
            <div className="b5NJg"></div>
         </div>
      </div>
      <div className="res-bg society-bnr" style={{backgroundImage: "url(http://classicxtra.com/images/download.png)"}}>
			  <img src={`${process.env.PUBLIC_URL}/assets/images/icon/society-banner.jpg`} alt=""/>
			  <h3 class="society-title-welcome">Welcome </h3>
			  <h3 class="society-title">{this.state.name}</h3>
		</div>
		<div className="res-details-div res-round mb-0 mtm-150" role="button" tabIndex="0">
			<div className="res-details-info">
				<div className="_14v1V center"><div className="nowrap details-title-col">Find Your Society</div></div>
				<div className="_3oKxo center width font-s-14">Flat registered on your mobile number</div>
			</div>
		</div>
</section>

 <section className="pb-0 margin-pd no-pad restro bg-white mh-120 bg-img">
	<div class="col-md-12">	
		
		{this.state.ListData.map((list) => (	
			list.society_unit.map((society)=>(
			<Link to={`${process.env.PUBLIC_URL}/society_details/${society.unit_id}`}>
				<div class="society-card">
					<div class="col-md-2 no-padd"><img src={`${process.env.PUBLIC_URL}/assets/images/icon/building-icon.png`} alt="" class=""/></div>
					<div class="col-md-8">
						<h3>{list.society_name}</h3>
						<h5>{society.name}</h5>
					</div>
					<div class="col-md-1 no-padd"><img src={`${process.env.PUBLIC_URL}/assets/images/icon/right-arrow.png`} alt="" class="right-img"/></div>
				
				</div>
			</Link>	
			))	
		))}
		{(() => {  
								if (lenght =='0') {
								return (
								
									<h2 class="no-data-found">No Appartment found on this number</h2>
								
										)
									}
								})()}
		
		
		{/* <div className="res-bg" style={{backgroundImage: "url(http://classicxtra.com/images/download.png)"}}>
		  <img src={`${process.env.PUBLIC_URL}/assets/images/icon/building-bg.jpg`} alt=""/>
		</div> */}
		
	</div>
	
 </section>
 
 <div className={`float-cart show-cart society-cart`} id="float-cart">
	
	
</div>




</div>




</div>
)
}
}
	
export default RestaurantDetails;