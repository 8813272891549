import React, {Component} from 'react';
import Slider from 'react-slick';

import Breadcrumb from "../common/breadcrumb";
import {Slider2, Team4} from "../../services/script"

class Notification extends Component {
componentDidMount() {
		document.querySelector(".loader-wrapper").style = "display: block";
		setTimeout(function() {
            document.querySelector(".loader-wrapper").style = "display: none";
        }, 900);
    }
    constructor (props) {
        super (props);
		this.goBack = this.goBack.bind(this);

    }
	goBack(){
		this.props.history.goBack();
	}

    render (){
        return (
            <div>
						<div className="_2u0oK _1iY8Y">
            <form>
               <div className="_3TzfO page_head">
                  <div className="_2QN4q">
                     <div >
                     <img src={`${process.env.PUBLIC_URL}/assets/images/icon/return_white.png`} onClick={this.goBack} alt="" /></div>
                  </div>
                  <div className="_1leRb page_title">
                   Notifications
                  </div>
                  <div className="b5NJg"></div>
               </div>
               <input type="submit" hidden=""/>
            </form>
         </div>
			<style>{'body { background-color: white !important; }'}</style>
   <div class="_1bhn9 margin-pd">
      <div class="_2czoF">
	  
		 <div class="_1KevM empty-cart">			 
		    <img src={`${process.env.PUBLIC_URL}/assets/images/icon/notification.png`} alt="" />
		 </div>    
         <div class="_1KevM empty-cart">			 
			<div>No Notification Found!</div>
		 </div>
	  
   </div>
</div>

            </div>
        )
    }
}

export default Notification