import React, { Component } from 'react';
import { Link, NavLink} from 'react-router-dom';
import '../../common/restaurant.scss';
import {getListData} from '../../../services/index';
import $ from 'jquery';
import {Redirect} from 'react-router-dom';
import { Item} from "../../layouts/home/components";
import Carousel from "../../layouts/home/Carousel";
import Slider from 'react-slick';
import { Card } from 'antd';
const { Meta } = Card;


class Home extends Component {
	constructor(){
		super();
		var storedArray = JSON.parse(localStorage.getItem("userData"));
		this.state = {
			user_name: '',
			mobile: '',
			email: '',
			package: '',
			ListData: [],
			user_id: storedArray['data']['user_id'],
			
		};
		this.addadress = this.addadress.bind(this);
		this.onChange = this.onChange.bind(this);
		this.handleChange = this.handleChange.bind(this);
	}
	handleChange(event) {
	  this.setState({
		package: event.target.value
	  });
	}
	onChange(e){
		this.setState({[e.target.name]:e.target.value});
	}
	addadress() {
		if(this.state.user_name || this.state.mobile || this.state.email){
			
			getListData('auth/fitness_enquiry',this.state).then((result) => {
			if(result.status == 300){
				document.getElementById('alert').innerHTML='<div class="alert alert-warning" role="alert"><i class="fa fa-info-circle" aria-hidden="true"></i> Please insert all fields</div>';
			}
			else{
				document.getElementById('alert').innerHTML='';
				this.setState({redirectToReferrer: true});
			}
			
			});
		}
		if(this.state.user_name === '' && this.state.mobile === '' && this.state.email === ''){
			document.getElementById('alert').innerHTML='<div class="alert alert-warning" role="alert"><i class="fa fa-info-circle" aria-hidden="true"></i> Please insert all fields </div>';
		}
		else{
			document.getElementById('alert').innerHTML='';
		}
	}
	componentDidMount() {
        document.getElementById("color").setAttribute("href", `#` );		
		document.querySelector(".loader-wrapper").style = "display: block";
	setTimeout(function() {
    document.querySelector(".loader-wrapper").style = "display: none";
		}, 900);
		
		

			if (localStorage.getItem('userData')){
				var storedArray = JSON.parse(localStorage.getItem("userData"));
			}
			
			let travel_id = this.props.match.params.id;
			this.state.travel_id = travel_id;
			this.state.user_id=storedArray['data']['user_id'];
			var user_id = storedArray['data']['user_id'];
			window.showAndroidToast(user_id);
			getListData('travel/travel_details', this.state).then((result) => {
				let responseJson = result;
				if (responseJson.data) {
					if (result['count'] > 0) {
						this.setState({
							ListData: result['data']
						})
					}
				}
			});
			
			
		
    }

	render() {
	if (this.state.redirectToReferrer){
			return (<Redirect to={`${process.env.PUBLIC_URL}/`}/>)
		}
		const settings = {
			dots: true,
			infinite: true,
			speed: 300,
			slidesToShow: 1,
			centerMode: true,
			variableWidth: true
		  };	
		return (
			<div>
                
				
                 
                {/*Home Section End*/}

                {/*collection banner*/}
				
				{this.state.ListData.map((list) => (
				<div>
                <section className="pb-0 margin-pd mt-45 restro">
					<div className="_2u0oK _1iY8Y">
						<form>
						   <div className="_3TzfO page_head">
							  <div className="_2QN4q">
								 <Link to={`${process.env.PUBLIC_URL}/tours_travels`}>
								 <img src={`${process.env.PUBLIC_URL}/assets/images/icon/return_white.png`} alt="" /></Link>
							  </div>
							  <div className="_1leRb page_title">
								 {list.name}
							  </div>
							  <div className="b5NJg"></div>
						   </div>
						   <input type="submit" hidden=""/>
						</form>
					 </div>
				    
                </section>
				
				
				 {/*collection banner*/}
                <section className="pb-0 margin-pd restro details-pad-top-n">
				    
                    <div className="add-details details-padding gym-pad-bot" role="button" tabIndex="0">
						<div className="res-details-info col-md-12">
							<div class="collection-banner p-left text-left">
								<img src={`${list.image}`} alt="" class="img-fluid blur-up lazyload bg-img" />
								<div class="absolute-contain">
									<h3>{list.name}</h3>
								</div>
							</div>
							
						</div>
					</div>
                </section>
				<section className="pb-0 margin-pd mt-10 restro details-pad-top-n">
                    <div className="add-details details-padding" role="button" tabIndex="0">
						<div className="res-details-info col-md-12">
							<div className="col-md-12  no-pad">
							<h3 className="nowrap restro _14v1V details-title-col"> Contact Details</h3>
								<div className="details-color "><i class="fa fa-phone-square" aria-hidden="true"></i> {list.phone}</div>
								<div className="details-color "><i class="fa fa-phone-square" aria-hidden="true"></i> {list.contact_number}</div>
								<div className="details-color "><i class="fa fa-envelope" aria-hidden="true"></i> {list.email}</div>
							</div>
						</div>
					</div>
                </section>
				<section className="pb-0 margin-pd mt-10 restro details-pad-top-n">
                    <div className="add-details details-padding" role="button" tabIndex="0">
						<div className="res-details-info col-md-12">
							<div className="col-md-12  no-pad">
							<h3 className="nowrap restro _14v1V details-title-col"> Address</h3>
								<div className="details-color "><i class="fa fa-map-marker" aria-hidden="true"></i> {list.address}</div>
							</div>
						</div>
					</div>
                </section>
				<section className="pb-0 margin-pd mt-10 restro details-pad-top-n">
                    <div className="add-details details-padding" role="button" tabIndex="0">
						<div className="res-details-info col-md-12 tt">
							<div className="col-md-12  no-pad">
							<h3 className="nowrap restro _14v1V details-title-col"> Packages</h3>
							
							{list.packages.map((packages) =>
							<Link to={`${process.env.PUBLIC_URL}/package_details/${packages.package_id}`}>
							<Card
								hoverable
								style={{ width: 240 }}
								cover={<img alt={packages.package_name} src={`${packages.package_image}`} />}
							  >
								<Meta title={packages.package_name}/>
								<div>
								<div class="col-md-6"><p>{packages.days} </p><p className="p-t-5 tt-p"> Rs. {packages.price} </p></div>
								<div class="col-md-6"><p>&nbsp;</p><p className="p-t-5 text-right " >View More <p className="dash" >&nbsp;</p></p></div>
								</div>
							  </Card>
							  </Link>	
							)}
								
							</div>
						</div>
					</div>
                </section>
				
				
				
		</div>
))}
 
			</div>
			
			)

	}
}

export default Home;
