import React, {Component} from 'react';
import { Link, NavLink} from 'react-router-dom';
import Breadcrumb from "../common/breadcrumb";
import $ from 'jquery';
import Login from './login';
import {LoginAuth} from '../../services/index';
import {Redirect} from 'react-router-dom';

class Register extends Component {

	constructor(){
		super();
		this.state = {
			number: '',
			redirectToReferrer: false
		};
		this.login = this.login.bind(this);
		this.onChange = this.onChange.bind(this);
	}
	componentDidMount() {
		document.querySelector(".loader-wrapper").style = "display: none";
		
	$('.float-label .form-control[placeholder]').each(function () {
		if ($(this).attr('placeholder').length > 0) { $(this).addClass('active');}
	});
	$('.float-label .form-control[value]').each(function () {
		if ($(this).val.length > 0) { $(this).addClass('active');}
	});
	$('.float-label .form-control').on('blur', function () {
		if ($(this).val().length > 0) { $(this).addClass('active');} else {
			if ($(this)[0].hasAttribute('placeholder') === true) {
				if ($(this).attr('placeholder').length > 0) { $(this).addClass('active');} 
				else { $(this).removeClass('active');}
			} else { $(this).removeClass('active');}
		}
	});
	var number = document.getElementById("number");

	}


	login() {
		var phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
		var number = document.getElementById("number");
		

		if(this.state.number == ''){
			document.getElementById('alert').innerHTML='<div class="alert alert-warning" role="alert"><i class="fa fa-info-circle" aria-hidden="true"></i> Please Insert Number</div>';
		}
		else{
			if(number.value.match(phoneno))
			{
				LoginAuth('auth/user_login',this.state).then((result) => {
					if(result.status==300){
						document.getElementById('alert').innerHTML='<div class="alert alert-warning" role="alert"><i class="fa fa-info-circle" aria-hidden="true"></i> Oops !!	Your number is already registered as Classic Net user <br> Please login with your credentials</div>';
					}
					else{
						document.getElementById('alert').innerHTML='';
						let responseJson = result;
						if(responseJson.data){					
							//localStorage.setItem('userData',JSON.stringify(responseJson));
							this.setState({redirectToReferrer: true});
						}
					}		
					
						
					});  
			}
		else
			{
			document.getElementById('alert').innerHTML='<div class="alert alert-warning" role="alert"><i class="fa fa-info-circle" aria-hidden="true"></i> Not a valid Phone number</div>';
			return false;
			}
		}

		

		
		}

	onChange(e){
		this.setState({[e.target.name]:e.target.value});
	}
    render (){
		var users_no = this.state.number;
		var otp_link = '/otp/'.users_no;
		if (this.state.redirectToReferrer){
			var users_no = this.state.number;
			var otp_link = '/otp/'.users_no;
			return (<Redirect to={`/otp/${users_no}`}/>)
		}

        return (
            <div>
                
                
                
                {/*Regsiter section*/}
               
                    <div className="limiter">
					<div className="container-login100">
					
						<div className="wrap-login100">
						<div className="login-fm">
						
							<form className="login100-form validate-form">
								<span className="login100-form-logo"> </span>
								<span className="login101-form-title p-t-27">
									Login / Sign Up
									<p>Please provide your details to Login/Sign Up on Classic Xtra </p>
								</span>
								<div id="alert" className="m-b-10"></div>
								
								<div className="form-group float-label validate-input" data-validate="Enter username">
									<input type="number" pattern="[0-9]*"  className="form-control" name="number" id="number" autoComplete="off" onChange={this.onChange} required/>
									<label><i className="fa fa-phone" aria-hidden="true"></i> Enter Mobile Number</label>
								</div>
								
									
								<div className="container-login100-form-btn">			
									<input type="hidden" name="token" id="token-input" value='null' onChange={this.onChange}/>
									<input type="button"  className="login100-form-btn" value="Continue" onClick={this.login}/>
								</div>								
							</form>
						</div>
						<div class="width">
						 <Link to={`${process.env.PUBLIC_URL}/login`}>
						<button class="register-btn">Classicnet User Login</button>
						</Link>
						</div>
						</div>
					</div>
					
				</div>
               

            </div>
        )
    }
}

export default Register