import React, { Component } from 'react';
import { Link, NavLink} from 'react-router-dom';


import '../common/index.scss';

class chat extends Component {
	constructor(props) {
        super(props);
		this.state = {
			ListData: []
		};
		if(!localStorage.getItem('userData')) {
		   this.props.history.push("login");
        }
    }

    componentDidMount() {	
		document.querySelector(".loader-wrapper").style = "display: block";
		setTimeout(function() {
			document.querySelector(".loader-wrapper").style = "display: none";
		}, 900);		
		var storedArray  = JSON.parse(localStorage.getItem("userData"));
		this.state.user_id = storedArray['data']['user_id'];

    }

	
	render() {
		return (
		<div>

			<div className="row">
				
            </div>
		</div>
			)
	}
}

export default chat;
