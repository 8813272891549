import React, { Component } from 'react';
import { Link, NavLink} from 'react-router-dom';
import '../../common/restaurant.scss';
import {getListData} from '../../../services/index';
import $ from 'jquery';
import {Redirect} from 'react-router-dom';
import { Item} from "../../layouts/home/components";
import Carousel from "../../layouts/home/Carousel";
import Slider from 'react-slick';


class ClassicProfile extends Component {
	constructor(){
		super();
		if(!localStorage.getItem('userData')) {
		   this.props.history.push("login");
        }	
	}

	componentDidMount() {
        document.getElementById("color").setAttribute("href", `#` );		
		document.querySelector(".loader-wrapper").style = "display: block";
		setTimeout(function() {
			document.querySelector(".loader-wrapper").style = "display: none";
		}, 900);

    }

	render() {

		return (
			<div>

                <section className="pb-0 margin-pd mt-45 restro">
					<div className="_2u0oK _1iY8Y">
						<form>
						   <div className="_3TzfO page_head">
							  <div className="_2QN4q">
								 <Link to={`${process.env.PUBLIC_URL}/`}>
								 <img src={`${process.env.PUBLIC_URL}/assets/images/icon/return_white.png`} alt="" /></Link>
							  </div>
							  <div className="_1leRb page_title">
								 ClassicXtra
							  </div>
							  <div className="b5NJg"></div>
						   </div>
						   <input type="submit" hidden=""/>
						</form>
					 </div>
				    
                </section>
				
				 {/*collection banner*/}
                <section className="pb-0 margin-pd restro details-pad-top-n">
				    
                    <div className="add-details gym-pad-bot classicxtra" role="button" tabIndex="0">
						<div className="res-details-info col-md-12">
							<div class="collection-banner p-left text-left">
								<img src={`${process.env.PUBLIC_URL}/assets/images/classic_bg.jpg`} alt="" class="img-fluid blur-up lazyload bg-img" />
								<div class="absolute-contain">
									
									<h3>Classic Internet & Cable Service Pvt. Ltd.</h3>
								</div>
							</div>
							
						</div>
					</div>
                </section>


				<section className="pb-0 margin-pd mt-10 restro details-pad-top-n">
					<div className="add-details details-padding" role="button" tabIndex="0">
						<div className="res-details-info col-md-12">
							<div className="col-md-12  no-pad">
								<h3 className="nowrap restro _14v1V details-title-col">
									<i class="fa fa-phone" aria-hidden="true"></i> Phone<br/>
									<div className="profile_sub">+91 2226780649</div>
								</h3>
								<h3 className="nowrap restro _14v1V details-title-col">
									<i class="fa fa-envelope" aria-hidden="true"></i> Email<br/>
									<div className="profile_sub">info@classicxtra.com</div>
								</h3>
								<h3 className="nowrap restro _14v1V details-title-col">
									<i class="fa fa-map-marker" aria-hidden="true"></i> Address<br/>
									<div className="profile_sub">B7 Lily Apt Jogeshwari (West)</div>
								</h3>
							</div>

						</div>
					</div>
                </section>
				<div className="col-md-12 see_plans">
				<Link className="text-white" to={`${process.env.PUBLIC_URL}/classic_plans/}`}>
					<button class="btn btn-primary mb-3" onClick=''>See Plans</button> 
				</Link>
				</div>
			</div>
			
			)

	}
}

export default ClassicProfile;
