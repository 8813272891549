import React, { Component } from 'react';
import { Link, NavLink} from 'react-router-dom';
import '../../common/restaurant.scss';

class Home extends Component {
	constructor(props) {
        super(props);
    }

    componentDidMount() {
        document.getElementById("color").setAttribute("href", `#` );		
		setTimeout(function() {
            document.querySelector(".loader-wrapper").style = "display: none";
        }, 2000);
    }
	

	render() {
		return (
			<div>
                
				
                 
                {/*Home Section End*/}

                {/*collection banner*/}
                <section className="pb-0 margin-pd mt-120 restro">
					<div className="_2u0oK _1iY8Y">
						<form>
						   <div className="_3TzfO page_head">
							  <div className="_2QN4q">
							  {/* <Link to={`${process.env.PUBLIC_URL}/physiotherapy`}>
							  <img src={`${process.env.PUBLIC_URL}/assets/images/icon/return_white.png`} alt="" /></Link> */}
							  </div>
							  <div className="_1leRb page_title">
								 Thank You
							  </div>
							  <div className="b5NJg"></div>
						   </div>
						   <input type="submit" hidden=""/>
						</form>
					 </div>
				   
                </section>
				
				 {/*collection banner*/}
                <section className="pb-0 margin-pd restro details-pad-top-n">
				    
                    <div className="add-details details-padding" role="button" tabIndex="0">
						<div className="res-details-info col-md-12">
							<div className="col-md-12 no-pad details-title radio center thank">
							<img src={`${process.env.PUBLIC_URL}/assets/images/restaurants/right_1.png`} alt=""/>
							</div>
							<div className="col-md-12 no-pad">
								<div className="_14v1V">
									<div className="center no-pad thank">Thanks for enquiry.</div>
								</div>
								<div className="details-dish-con">
									<div className="">
										<div className="details-dish-title details-color center bold">Your enquiry has been submited. One of our colleagues will get back in touch with you soon!</div>
										
									</div>
								  </div>
							</div>	  
						</div>
					</div>
					
					
                </section>
				<section className="pb-0 margin-pd restro details-pad-top-n">
					
					<div className="order-btn">
					<Link to={`${process.env.PUBLIC_URL}/`}>
						<button className="order-button">
							<span className="order-conf2">Back to Home Page</span>
						</button>
					</Link>	
					</div>
                </section> 


 
			</div>
			
			)

	}
}

export default Home;
