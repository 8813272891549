import React, { Component } from 'react';
import { Link, NavLink} from 'react-router-dom';
import '../../common/restaurant.scss';
import {getListData} from '../../../services/index';

class Home extends Component {
	constructor(props) {
        super(props);
		this.state = {
			ListData: []
		};
    }

    componentDidMount() {
        document.getElementById("color").setAttribute("href", `#` );		
		document.querySelector(".loader-wrapper").style = "display: block";
		setTimeout(function() {
            document.querySelector(".loader-wrapper").style = "display: none";
        }, 900);
		
		var storedArray = JSON.parse(localStorage.getItem("userData"));
		let order_id = this.props.match.params.id;
		this.state.user_id = storedArray['data']['user_id'];
		this.state.order_id = order_id;
		getListData('veggies/order_history', this.state).then((result) => {
		let responseJson = result;
		if (responseJson.data) {
			if (result['count'] > 0) {
				this.setState({
					ListData: result['data']
				})
				localStorage.setItem("order_id",'');
				localStorage.setItem('order_id',order_id);
			}
		}
	});
		
    }
	

	render() {
		return (
			<div>
{this.state.ListData.map((list) => (
<div class="track_main">
                <section className="pb-0 margin-pd mt-45 restro track_order">
					<div className="_2u0oK _1iY8Y">
						<form>
						   <div className="_3TzfO page_head">
							  <div className="_2QN4q">
								 <Link to={`${process.env.PUBLIC_URL}/veggies_my_order`}>
								 <img src={`${process.env.PUBLIC_URL}/assets/images/icon/return_white.png`} alt="" /></Link>
							  </div>
							  <div className="_1leRb page_title">
								 Order History
							  </div>
							  <div className="b5NJg"></div>
						   </div>
						   <input type="submit" hidden=""/>
						</form>
					 </div>
				    <Link to={`${process.env.PUBLIC_URL}/`}>
                    <div className="res-details-div" role="button" tabIndex="0">
						<div className="res-details-info">
							<div className="order-image">
								<div className="bgimg order-bot-mar" style={{backgroundImage: `url(${list.image})`}}>
								</div>
							</div>
							<div className="_14v1V center">
								<div className="nowrap details-title-col left order-bot-mar">
									{list.restaurant_name}
								</div>
								<div className="order-title left order-bot-mar">
									Order placed on : {list.date_created}
								</div>
							</div>
						</div>
						
					</div>
					</Link>	
                </section>
				<section className="pb-0 margin-pd restro details-pad-top-n">
                    <div className="res-details-div details-padding" role="button" tabIndex="0">
						<div className="res-details-info">
							<div className="_14v1V">
								<div className="nowrap details-title">Items</div>
							</div>
							<div className="details-dish-con">
									<div className="details-dish-title details-color col-md-4 no-pad order-his-item">Item Name</div>
									<div className="mh-55">
									<div className="order-price col-md-2 right no-pad order-his-item">Final Cost</div>
									<div className="order-price col-md-2 right no-pad order-his-item">Item Cost</div>
									<div className="order-price col-md-2 right no-pad order-his-item">Qty</div>
									<div className="order-price col-md-2 right no-pad order-his-item">Unit</div>
									</div>
							{list.items.map(items => 
								<div className="">
									
									<div className="details-dish-title details-color col-md-4 no-pad order-his-item"><img src={items.item_image} class="w-20" /> {items.item_name}</div>
									
									<div className="order-price col-md-2 right no-pad order-his-item"><i className="fa fa-inr" aria-hidden="true"></i> {items.final_cost}</div>
									<div className="order-price col-md-2 right no-pad order-his-item"><i className="fa fa-inr" aria-hidden="true"></i>{items.item_cost}</div>
									<div className="order-price col-md-2 right no-pad order-his-item">{items.item_qty}</div>
									<div className="order-price col-md-2 right no-pad order-his-item">{items.item_unit}</div>
								</div>
							)}
							  </div>
						</div>					
					</div>
                </section>
				
                <section className="pb-0 margin-pd restro details-pad-top-n">
                    <div className="res-details-div details-padding" role="button" tabIndex="0">
						<div className="res-details-info">
							<div className="_14v1V">
								<div className="nowrap details-title">Bill Details</div>
							</div>
							<div className="details-dish-con">
								<div className="">
									<div className="details-dish-title details-color col-md-9 no-pad">Item Total</div>
									<div className="order-price col-md-3 right no-pad"><i className="fa fa-inr" aria-hidden="true"></i> {list.total_cost}</div>
								</div>
								
								
								<div className="">
									<div className="details-dish-title col-md-9 no-pad details-color order-border-t">Delivery Fee</div>
									<div className="order-price col-md-3 right no-pad details-color order-border-t"><i className="fa fa-inr" aria-hidden="true"></i> {list.delivery_fee}</div>
								</div>
								<div className="">
									<div className="details-dish-title col-md-9 no-pad details-color bold order-border-t">To Pay</div>
									<div className="order-price col-md-3 right no-pad details-color bold order-border-t" ><i className="fa fa-inr" aria-hidden="true"></i> {list.paid_amount}</div>
								</div>
								<div className="">
									<div className="details-dish-title col-md-6 no-pad details-color bold order-border-t">Status</div>
									<div className="order-price col-md-6 right no-pad details-color bold order-border-t"> {list.status}</div>
								</div>
								
							  </div>
						</div>					
					</div>
                </section>
				 {(() => {
        if (list.status!='Delivered' && list.status!='Cancelled') {
          return (
		  <div>
				<section class="pb-0 margin-pd restro details-pad-top-n">
				<div class="order-btn">
				<Link to={`${process.env.PUBLIC_URL}/veggies_track_order`}>
				<button class="order-button"><span class="order-conf2">Track Your Order</span></button>
				</Link>
				</div>
				</section>
				</div>
          )
        }
      })()}
				
				
				
				
				</div>
))}



 
			</div>
			
			)

	}
}

export default Home;
