import React, {Component} from 'react';
import { Link, NavLink} from 'react-router-dom';

class RenewPlan extends Component {


componentDidMount() {
document.querySelector(".loader-wrapper").style = "display: block";
	setTimeout(function() {
    document.querySelector(".loader-wrapper").style = "display: none";
}, 900);
if (localStorage.getItem('userData')){
function date_format(date){
	var fromDate = new Date(date);
	return new Date(fromDate).toDateString("dd-MM-yyyy");
}
var plan_array = JSON.parse(localStorage.getItem("userData"));
document.getElementById("package").innerHTML = plan_array['plan']['plan_name'];
document.getElementById("expiry_date").innerHTML = plan_array['plan']['expiry_date'];
document.getElementById("download_speed").innerHTML =  plan_array['plan']['download_speed'];
document.getElementById("upload_speed").innerHTML = plan_array['plan']['upload_speed'];
document.getElementById("gb_allocated").innerHTML = plan_array['plan']['gb_allocated'];
document.getElementById("plan_days").innerHTML = plan_array['plan']['plan_days']+' days';
document.getElementById("plan_amt").innerHTML = plan_array['plan']['plan_amt'];
document.getElementById("pay").innerHTML = plan_array['plan']['plan_amt'];

var data_array = JSON.parse(localStorage.getItem("userData"));
document.getElementById("expiry_date").innerHTML = date_format(data_array['data']['expiry_date']);
}
}
constructor(props) {
        super(props);
	    if(!localStorage.getItem('userData')) {
		   this.props.history.push("login");
        }	
    }

render (){
return (
<div>
   <section className="authentication-page section-b-space margin-pd">
      <div className="_3lldG pt-20">
         <div className="_2u0oK _1iY8Y border-blue">
            <form>
               <div className="_3TzfO page_head">
                  <div className="_2QN4q">
                     <Link to={`${process.env.PUBLIC_URL}/classic_broadband`}>
                     <img src={`${process.env.PUBLIC_URL}/assets/images/icon/return_white.png`} alt="" /></Link>
                  </div>
                  <div className="_1leRb page_title">
                     Renew Plan
                  </div>
                  <div className="b5NJg"></div>
               </div>
               <input type="submit" hidden=""/>
            </form>
         </div>
         <div className="_3mMtt dashboard m-plan">	
		  
		        <section className="renew-tab tab-product m-0">
                <div className="row">
                    <div className="col-sm-12 col-lg-12">
                        <div className="tab-content nav-material">                     
                            <div className="tab-pane fade mt-4 show active">
							<div className="tab-m-header"> 
								<p>Renew Current Plan</p>
								<span>or Select Plan from Plan List</span>
							</div>
				                <table className="table table-striped mb-0 table-renew">
								   <thead>  
								    <tr>
                                        <td colSpan="2" className="tr-head" id="package"></td>                                    
                                    </tr>
								   </thead>
								   
                                    <tbody>
                                    <tr>
                                        <th><i className="fa fa-tachometer" aria-hidden="true"></i> Speeds</th>
                                        <td><i className="fa fa-download" aria-hidden="true"></i> <span id="download_speed"></span>  &nbsp; 
										 <i className="fa fa-upload" aria-hidden="true"></i> <span id="upload_speed"></span></td>
                                    </tr>
                                    <tr>
                                        <th><i className="fa fa-adjust" aria-hidden="true"></i> Limit</th>
                                        <td id="gb_allocated"></td>
                                    </tr>
                                    <tr>
                                        <th><i className="fa fa-calendar-check-o" aria-hidden="true"></i> Validity</th>
                                        <td id="plan_days"></td>
                                    </tr>
                                    <tr>
                                        <th><i className="fa fa-credit-card" aria-hidden="true"></i> Cost</th>
                                        <td> <b><i className="fa fa-inr" aria-hidden="true"></i><span id="plan_amt"></span></b></td>
                                    </tr>
                                    <tr>
                                        <th>+ Existing Dues </th>
                                        <td> <i className="fa fa-inr" aria-hidden="true"></i>0</td>
                                    </tr>
                                    <tr>
                                        <td colSpan="2" className="text-center"><button className="btn btn-primary">Pay <i className="fa fa-inr" aria-hidden="true"></i><span id="pay"></span></button></td>
                                    </tr>  	
									<tr>									 
                                     <th> <Link to={`${process.env.PUBLIC_URL}/internet_plans`}>Select Plan</Link>  </th> 
									 <Link to={`${process.env.PUBLIC_URL}/internet_plans`}>
									 <td class="pull-right"> <i className="fa fa-chevron-right" aria-hidden="true"></i></td></Link>
										
                                    </tr>	
									<tr>
                                        <td colSpan="2"></td>
                                    </tr>	
									<tr>
                                        <th>Existing Dues</th>
										<td> <i class="fa fa-inr" aria-hidden="true"></i>0</td>
                                    </tr>
                                    <tr>
                                        <th>Current Expiry Date :</th>
                                        <td id="expiry_date"></td>
                                    </tr>
                                    </tbody>
									
                                    <tfoot>									
									<tr>
                                        <td colSpan="2"  className="text-center">
										<Link to={`${process.env.PUBLIC_URL}/purchase_history`}>View Your payment History</Link></td>
                                    </tr>
                                    </tfoot>	
									
                                </table>
                            </div>                            
                        </div>
                    </div>
                </div>
            </section>
		 
		 
			
         </div>
      </div>
   </section>
</div>
)
}
}
export default RenewPlan