import React, {Component} from 'react';
import { Link, NavLink} from 'react-router-dom';



class Search extends Component {
	constructor (props) {
        super (props)
    }
componentDidMount() {
		document.querySelector(".loader-wrapper").style = "display: block";
		setTimeout(function() {
            document.querySelector(".loader-wrapper").style = "display: none";
        }, 900);
		
		
const $ = window.$;  
const google = window.google;	
		

       var geocoder;
       var map;
       var marker;

       var autocomplete;
	   initialize(19.1179,72.8631,0);

   
       function initialize(lat,lon,plc) {
           var initialLat = document.getElementsByClassName('search_latitude').value;
           var initialLong = document.getElementsByClassName('search_longitude').value;
     
	       if(lat==''){
				initialLat = initialLat?initialLat:19.157811199999998;
				initialLong = initialLong?initialLong:72.84326399999999;
		   }
		   else{
			   initialLat = lat;
			   initialLong = lon;
		   }
   
   
           var latlng = new window.google.maps.LatLng(initialLat, initialLong);
           var options = {
               zoom: 16,
               center: latlng,
               mapTypeId: google.maps.MapTypeId.ROADMAP,
			   mapTypeControl: false,
			   disableDefaultUI: true,
           };
   
           map = new window.google.maps.Map(document.getElementById("geomap"), options);
   
           geocoder = new window.google.maps.Geocoder();
   
           marker = new window.google.maps.Marker({
               map: map,
               draggable: true,
               position: latlng,
			   icon: 'http://kapso.in/classic/assets/images/icon/map.png'
           });
           
           
           var point = marker.getPosition();
               map.panTo(point);
               geocoder.geocode({'latLng': marker.getPosition()}, function (results, status) {
                   if (status == google.maps.GeocoderStatus.OK) {
                       map.setCenter(results[0].geometry.location);
                       marker.setPosition(results[0].geometry.location);
					   document.getElementById('search_addr').value=results[0].formatted_address;	
					   document.getElementById('search_latitude').value=marker.getPosition().lat();					   
					   document.getElementById('search_longitude').value=marker.getPosition().lng();
                   }
               });
   
           window.google.maps.event.addListener(marker, "dragend", function () {
               
               var point = marker.getPosition();
               map.panTo(point);
               geocoder.geocode({'latLng': marker.getPosition()}, function (results, status) {
                   if (status == google.maps.GeocoderStatus.OK) {
                       map.setCenter(results[0].geometry.location);
                       marker.setPosition(results[0].geometry.location);
					   document.getElementById('search_addr').value=results[0].formatted_address;
					   document.getElementById('search_latitude').value=marker.getPosition().lat();					   
					   document.getElementById('search_longitude').value=marker.getPosition().lng();
                   }
               });
           });
   
       }

	
	
    }
    

    render (){

        return (
            <div>
                
                
                {/*Search section*/}
                <section className="authentication-page section-b-space margin-pd">

<div className="_3lldG">
   <div className="_2u0oK _1iY8Y">
      <form>
         <div className="_3TzfO">
            <div className="_2QN4q">
               <Link to={`${process.env.PUBLIC_URL}/search`}><img src={`${process.env.PUBLIC_URL}/assets/images/icon/return.svg`} alt="" /></Link>
            </div>
            <div class="_1jnwd"><div class="anfG9"><div class="_2O4ey">SET ADDRESS</div></div></div>
         </div>
      </form>
   </div>

   
   <div className="_3mMtt">
    <form className="form-horizontal" method="post">

   <div className="form-group">
      <div id="geomap"></div>
   </div>
   <div className="form-group">
      <div className="col-md-12">
         <label for="map_location" className="control-label _1knFu">Set Delivery Location</label>			
         <input type="text" className="search_addr form-control _2eSrj" id="search_addr" name="address" value=""/>
		 <input type="hidden" id="search_latitude" name="geo_lati" value=""/>
		 <input type="hidden" id="search_longitude" name="geo_lang" value=""/>
		 <input type="hidden" id="search_place" name="geo_lang" value=""/>
      </div>
   </div>
   <div className="form-group">
      <div className="col-md-12">
        <input value="Add More Details" className="btn btn-primary2 btn1" name="btn_save_c" type="button"/>
        <input value="Confirm Location" className="btn btn-primary2 btn2 pull-right" name="btn_save_c" type="button"/>
      </div>	  
   </div>
   
	</form>
   </div>
</div>
                </section>

            </div>
        )
    }
	
}

export default Search