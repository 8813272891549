import React, { Component } from 'react';
import { Link, NavLink} from 'react-router-dom';
import '../common/restaurant.scss';
import {getListData} from '../../services/index';
class Home extends Component {
	constructor(props) {
        super(props);
		this.state = {
			ListData: []
		};
		if(!localStorage.getItem('userData')) {
		   this.props.history.push("login");
        }
    }

    componentDidMount() {
        document.getElementById("color").setAttribute("href", `#` );		
		document.querySelector(".loader-wrapper").style = "display: block";
		setTimeout(function() {
            document.querySelector(".loader-wrapper").style = "display: none";
        }, 900);
		var storedArray  = JSON.parse(localStorage.getItem("userData"));
        this.state.user_id = storedArray['data']['user_id'];
		getListData('restaurant/order_list',this.state).then((result) => {
		let responseJson = result;
			if(responseJson.data){
			    if(result['count']>0){
					this.setState({ 
						ListData: result['data']
					});
				}else{
					if(document.getElementById('alert')){
						document.getElementById('alert').innerHTML='<div class="_2czoF"><div class="_1KevM empty-rest">No Order!</div></div>';
					}
				}
			}
		});
    }
	

	render() {
		return (
			<div class="my-orders">

                <section className="pb-0 margin-pd mt-45 restro">
					<div className="_2u0oK _1iY8Y">
						<form>
						   <div className="_3TzfO page_head">
							  <div className="_2QN4q">
								 <Link to={`${process.env.PUBLIC_URL}/`}>
								 <img src={`${process.env.PUBLIC_URL}/assets/images/icon/return_white.png`} alt="" /></Link>
							  </div>
							  <div className="_1leRb page_title">
								My Orders
							  </div>
							  <div className="b5NJg"></div>
						   </div>
						   <input type="submit" hidden=""/>
						</form>
					 </div>
				    
                </section>

                <section className="pb-0 margin-pd restro details-pad-top-n">
				
				
				<div class="col-12 col-lg-12 col-xl-12 pl-2 pr-2 mt-2">
				<Link to={`${process.env.PUBLIC_URL}/my_orders/`}>
							<div className="_3C_gI gradient-primary no-margin" role="button" tabIndex="0">
								
								<img src={`${process.env.PUBLIC_URL}/assets/images/icon/food.png`} class="product-img" alt="" />
								<div className="NXGft ml-0">
									<div className="_14v1V fs-i"><b>Restaurant Orders</b></div>
								</div>
								
								
							</div>
				</Link>			
						</div>
					
					<div class="col-12 col-lg-12 col-xl-12 pl-2 pr-2 mt-2">
					<Link to={`${process.env.PUBLIC_URL}/veggies_my_order/`}>
							<div className="_3C_gI gradient-primary no-margin" role="button" tabIndex="0" >
								<img src={`${process.env.PUBLIC_URL}/assets/images/icon/vegetables.png`} class="product-img" alt="" />
								<div className="NXGft ml-0">
									<div className="_14v1V fs-i"><b>Vegetables & Fruits Orders</b></div>
								</div>
								
							</div>
					</Link>			
						</div>
					
					<div class="col-12 col-lg-12 col-xl-12 pl-2 pr-2 mt-2">
					<Link to={`${process.env.PUBLIC_URL}/grocery_my_order/`}>
							<div className="_3C_gI gradient-primary no-margin" role="button" tabIndex="0" >
								<img src={`${process.env.PUBLIC_URL}/assets/images/icon/groceries.png`} class="product-img" alt="" />
								<div className="NXGft ml-0">
									<div className="_14v1V fs-i"><b>Grocery Shopping</b></div>
								</div>
								
							</div>
					</Link>			
						</div>
					
                    
					
                </section>
				 
				
				

 
			</div>
			
			)

	}
}

export default Home;
