import React, { Component } from 'react';
import {Redirect} from 'react-router-dom';
import { Link, NavLink} from 'react-router-dom';
import '../common/restaurant.scss';
import {getListData} from '../../services/index';

import $ from 'jquery';

class Myprofile extends Component {
	constructor(props) {
        super(props);
		var storedArray = JSON.parse(localStorage.getItem("userData"));
		this.state = {
			name: storedArray['data']['name'],
			email: storedArray['data']['email'],
		};
		if(!localStorage.getItem('userData')) {
		this.props.history.push("login");
		}	
		this.logout = this.logout.bind(this);
		this.addadress = this.addadress.bind(this);
		this.onChange = this.onChange.bind(this);
		this.handleChange = this.handleChange.bind(this);
	}
	handleChange(event) {
	  this.setState({
		tag: event.target.value
	  });
	}
	
	
	addadress() {
		if(this.state.name || this.state.email){
            var storedArray = JSON.parse(localStorage.getItem("userData"));
           
			this.state.user_id = storedArray['data']['user_id'];
			getListData('auth/update_my_profile',this.state).then((result) => {
			if(result.status==400){
				document.getElementById('alert').innerHTML='<div class="alert alert-warning" role="alert"><i class="fa fa-info-circle" aria-hidden="true"></i> 1 Please insert all fields</div>';
			}
			else{
				document.getElementById('alert').innerHTML='';
			}
			
			let responseJson = result;
				if(responseJson.data){					
					localStorage.setItem('userData',JSON.stringify(responseJson));
					this.setState({redirectToReferrer: true});
				}
			});
		}
		
	}

	onChange(e){
		this.setState({[e.target.name]:e.target.value});
	}

    componentDidMount() {
		document.querySelector(".loader-wrapper").style = "display: none";
		
	$('.float-label-1 .form-control-1[placeholder]').each(function () {
		if ($(this).attr('placeholder').length > 0) { $(this).addClass('active');}
	});
	$('.float-label-1 .form-control-1[value]').each(function () {
		if ($(this).val.length > 0) { $(this).addClass('active');}
	});
	$('.float-label-1 .form-control-1').on('blur', function () {
		if ($(this).val().length > 0) { $(this).addClass('active');} else {
			if ($(this)[0].hasAttribute('placeholder') === true) {
				if ($(this).attr('placeholder').length > 0) { $(this).addClass('active');} 
				else { $(this).removeClass('active');}
			} else { $(this).removeClass('active');}
		}
	});
	if (localStorage.getItem('userData')){
	var storedArray = JSON.parse(localStorage.getItem("userData"));
	document.getElementById("mobile").value = storedArray['data']['mobile'];
    document.getElementById("mobile").classList.add("active");
	var name = storedArray['data']['name'];
	var email = storedArray['data']['email'];
    if(name != ''  ){
	document.getElementById("name").value = storedArray['data']['name'];
    document.getElementById("name").classList.add("active");
	}
	if(email != ''  ){
		document.getElementById("email").value = storedArray['data']['email'];
		document.getElementById("email").classList.add("active");
		}
	if(storedArray['data']['name'] == ''){
        document.getElementById("name").value = '';
        document.getElementById("email").value = '';
       
        }
	
	
	
	}
	}
	logout(){
	localStorage.setItem("userData",'');
	localStorage.clear();
	this.props.history.push("login");
	}

	render() {
	
	if (this.state.redirectToReferrer ){
			return (<Redirect to={'/account'}/>)
		}
	
		return (
			<div>
                
				
                 
                {/*Home Section End*/}

                {/*collection banner*/}
                <section className="pb-0 margin-pd mt-100 restro">
					<div className="_2u0oK _1iY8Y">
						
						   <div className="_3TzfO page_head">
							  <div className="_2QN4q">
								 <Link to={`${process.env.PUBLIC_URL}/account`}>
								 <img src={`${process.env.PUBLIC_URL}/assets/images/icon/return_white.png`} alt="" /></Link>
							  </div>
							  <div className="_1leRb page_title">
								Manage Profile
							  </div>
							  <div className="b5NJg"></div>
						   </div>
						   <input type="submit" hidden=""/>
						
					 </div>
				    
                </section>
				
				 {/*collection banner*/}
                <section className="pb-0 margin-pd restro details-pad-top-n">
				    <div className="res-details-div" role="button" tabIndex="0">
						<div className="res-details-info">
							<div className="details-dish-con">
								<div className="col-md-12">
									<div className="wrap-login200">
										<form className="login100-form validate-form">
											<div className="form-group-1 float-label-1 validate-input" data-validate="Enter Mobile">
												<input type="text" className="form-control-1" name="mobile" id="mobile" autoComplete="off" onChange={this.onChange} disabled/>
												<label className="color">mobile</label>
											</div>
											<div className="form-group-1 float-label-1 validate-input" data-validate="Enter Name">
												<input type="text" className="form-control-1" name="name" id="name" autoComplete="off" onChange={this.onChange}/>
												<label className="color">Name</label>
											</div>
											<div className="form-group-1 float-label-1 validate-input" data-validate="Enter Email">
												<input type="email" className="form-control-1" name="email" id="email" autoComplete="off" onChange={this.onChange}/>
												<label className="color">Email</label>
											</div>
											
											
											<div id="alert" className="m-b-10"></div>
											
											<div className="container-login100-form-btn">				
												<input type="button"  className="login100-form-btn" value="Save" onClick={this.addadress}/>
											</div>	
											
										</form>
									</div>
								</div>		
							</div>
						</div>
					</div>
                </section>
				
				
				 
				


 
			</div>
			
			)

	}
}

export default Myprofile;
