import React, { Component } from 'react';
import {Redirect} from 'react-router-dom';
import {Helmet} from 'react-helmet'
import Slider from 'react-slick';
import {Link} from 'react-router-dom';

class Account extends Component {
	
	constructor(props) {
        super(props);
	    if(!localStorage.getItem('userData')) {
		   this.props.history.push("login");
        }	
        this.logout = this.logout.bind(this);
		this.goBack = this.goBack.bind(this);
    }
	
    componentDidMount() {
		document.querySelector(".loader-wrapper").style = "display: block";
		setTimeout(function() {
            document.querySelector(".loader-wrapper").style = "display: none";
        }, 900);
		if (localStorage.getItem('userData')){
			var storedArray = JSON.parse(localStorage.getItem("userData"));
			var app = storedArray['data']['classic_user'];
			if(app == '0'){
				document.getElementById("name").innerHTML = storedArray['data']['name'];
				document.getElementById("email").innerHTML = storedArray['data']['email'];
				document.getElementById("mobile").innerHTML = storedArray['data']['mobile'];
			}
			var app = storedArray['data']['classic_user'];
			var user_name = storedArray['data']['name'];
			if(app == '1'){
				document.getElementById("classic").classList.add("mt-20");
			}
			if(storedArray['data']['name']){
				document.getElementById("name").innerHTML = storedArray['data']['name'];
			} 
			if(storedArray['data']['email']){
				document.getElementById("email").innerHTML = storedArray['data']['email'];
			} 
			if(storedArray['data']['mobile']){
				document.getElementById("mobile").innerHTML = storedArray['data']['mobile'];
			} 
		}
    }
	
	logout(){
			localStorage.setItem("userData",'');
			localStorage.setItem("token",'');
			localStorage.clear();
			this.props.history.push("login");
		}
		
	goBack(){
		
	}
	
    
    render (){
		
		
        return (
            <div>
			<div className="_2u0oK _1iY8Y">
            <form>
            <Link to={`${process.env.PUBLIC_URL}/`}>
               <div className="_3TzfO page_head">
                  <div className="_2QN4q">
				  <span id="loader"></span>
                     <div >
                     <img src={`${process.env.PUBLIC_URL}/assets/images/icon/return_white.png`} onClick={this.goBack} alt="" /></div>
                  </div>
                  <div className="_1leRb page_title">
                   My Account
                  </div>
                  <div className="b5NJg"></div>
               </div>
               <input type="submit" hidden=""/>
              </Link> 
            </form>
         </div>
			
			<div class="_3AFC5">
   <div class="_1bhn9 margin-pd">


            {(() => {
					var storedArray  = JSON.parse(localStorage.getItem("userData"));
					var apps = storedArray['data']['classic_user']; 
					if (apps == '0') {
					return (
						<div>
                     <div class="_2czoF">
                        <div class="_2GMgT">
                           <div class="_2_-Em" id="name"></div>
                        </div>
                        <div class="_1KevM"><span id="mobile"></span> / <span id="email"></span></div>
                     </div>
                  </div>
						)
					}
            })()}
            {(() => {
				var storedArray  = JSON.parse(localStorage.getItem("userData"));
               var apps = storedArray['data']['classic_user']; 
               var user_name = storedArray['data']['name'];
					if (apps == '1' && user_name != '') {
					return (
						<div>
                     <div class="_2czoF">
                        <div class="_2GMgT">
                           <div class="_2_-Em" id="name"></div>
                        </div>
                        <div class="_1KevM"><span id="mobile"></span> / <span id="email"></span></div>
                     </div>
                  </div>
						)
					}
				})()}

      <div>
         <div class="NeDfT">
            <div class="_3tVyU" id="classic">
               <div class="_39p-f _3lD0G">
                  <div class="_1Xqx7">
                     <div class="_32sRE">
					 <Link to={`${process.env.PUBLIC_URL}/my_address`}>
                        <div class="_2AdD- _2jjDQ">
                           <div class="_2gQbw">
                              <div class="Zo88I">
                                 <span class="_33X77"><img src={`${process.env.PUBLIC_URL}/assets/images/icon/home.svg`} alt="" /></span>
                                 <div>Manage Address</div>
                              </div>
                           </div>
                           <span class="icon-downArrow _1gsD2"></span>
                        </div> 
						</Link>
                     </div>


                     {(() => {
                           var storedArray  = JSON.parse(localStorage.getItem("userData"));
                           var apps = storedArray['data']['classic_user']; 
                           if (apps == '0') {
                           return (
                              <div>
                                    <div class="_32sRE">
                                    <Link to={`${process.env.PUBLIC_URL}/account_info`}>
                                          <div class="_2AdD- _2jjDQ">
                                             <div class="_2gQbw">
                                                <div class="Zo88I">
                                                   <span class="_33X77"><img src={`${process.env.PUBLIC_URL}/assets/images/icon/plan.svg`} alt="" /></span>
                                                   <div>My Plan</div>
                                                </div>
                                             </div>
                                             <span class="icon-downArrow _1gsD2"></span>
                                          </div>
                                    </Link>
                                    </div>
                              </div>
                              )
                           }
                        })()}
                        {(() => {
                           var storedArray  = JSON.parse(localStorage.getItem("userData"));
                           var apps = storedArray['data']['classic_user']; 
                           if (apps == '1') {
                           return (
                              <div>
                                    <div class="_32sRE">
                                    <Link to={`${process.env.PUBLIC_URL}/my_profile`}>
                                          <div class="_2AdD- _2jjDQ">
                                             <div class="_2gQbw">
                                                <div class="Zo88I">
                                                   <span class="_33X77"><img src={`${process.env.PUBLIC_URL}/assets/images/icon/plan.svg`} alt="" /></span>
                                                   <div>My Profile</div>
                                                </div>
                                             </div>
                                             <span class="icon-downArrow _1gsD2"></span>
                                          </div>
                                    </Link>
                                    </div>
                              </div>
                              )
                           }
                        })()}
                    


                     <div class="_32sRE">
					 <Link to={`${process.env.PUBLIC_URL}/my_orders`}>
                        <div class="_2AdD- _2jjDQ">
                           <div class="_2gQbw">
                              <div class="Zo88I">
                                 <span class="_33X77"><img src={`${process.env.PUBLIC_URL}/assets/images/icon/cart.png`} alt="" /></span>
                                 <div>My Orders</div>
                              </div>
                           </div>
                           <span class="icon-downArrow _1gsD2"></span>
                        </div>
						</Link>
                     </div>
                     
					 <div class="_32sRE">
					 <Link to={`${process.env.PUBLIC_URL}/feedback`}>
                        <div class="_2AdD- _2jjDQ">
                           <div class="_2gQbw">
                              <div class="Zo88I">
                                 <span class="_33X77"><img src={`${process.env.PUBLIC_URL}/assets/images/icon/write-letter.png`} alt="" /></span>
                                 <div>Feedback</div>
                              </div>
                           </div>
                           <span class="icon-downArrow _1gsD2"></span>
                        </div>
						</Link>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div> 
   </div>
   <section class="_2N4la">
      <div>
         <div class="_1q4Zi" onClick={this.logout}>
            <div>Logout</div>
            <span class="Zo88I"><img src={`${process.env.PUBLIC_URL}/assets/images/icon/logout.svg`} alt="" /></span>
         </div>
      </div>
   </section>
</div>

            </div>
        )
    }
}

export default Account