import React, { Component } from 'react';
import { Link, NavLink} from 'react-router-dom';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from 'react-loader-spinner';
import {getListData} from '../../../services/index';
import '../../common/restaurant.scss';
import {connect} from 'react-redux';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

class RestaurantDetails extends Component {
	static contextTypes = {
        router: () => null
    };
constructor(props) {
	super(props);
	this.state = {
        openCart: '',
        countItem: 0,
        countTotal: 0,
		ListData: [],
		qty: 0,
		openQuantity: false,
        quantity: 1,
        image: '',
        name: '',
		
	};
	
	if (!localStorage.getItem('userData')) {
		this.props.history.push("login");
	}
}
componentDidMount() {
	document.getElementById("color").setAttribute("href", `#`);
	document.querySelector(".loader-wrapper").style = "display: block";
	setTimeout(function() {
    document.querySelector(".loader-wrapper").style = "display: none";
	}, 900);
	
	let notice_id = this.props.match.params.id;
		this.setState({
					notice_id: notice_id,
				})
	this.checkData();
	
	
}


checkData() {
	
	var storedArray = JSON.parse(localStorage.getItem("userData"));
	this.state.mobile=storedArray['data']['mobile'];
	var mobile = storedArray['data']['mobile'];
	var app = {
		notice_id : this.props.match.params.id
	}
	getListData('society/SocietyController/notice_details', app).then((result) => {
		let responseJson = result;
		if (responseJson.data) {
			if (result['status'] == 200) {
				this.setState({
					ListData: result['data'],
				})
			}
		}
	});
	
}
   

   
	
	
	

	
	
	
	
	
	
	
	

render() {


var lenght = this.state.ListData.length;

return (
<div >
   
   <div>
   <div className="_2u0oK _1iY8Y border-blue">
            <form>
               <div className="_3TzfO page_head">
                  <div className="_2QN4q">
                     <a onClick={this.context.router.history.goBack}>
                     <img src={`${process.env.PUBLIC_URL}/assets/images/icon/return_white.png`} alt="" /></a>
                  </div>
                  <div className="_1leRb page_title">
                     Notice Board Details
                  </div>
                  <div className="b5NJg"></div>
               </div>
               <input type="submit" hidden=""/>
            </form>
         </div>
 <section className="pb-0 margin-pd no-pad restro mt-60 society-det">
	<div class="row marginlr mt-20" style={{background: "#f5f5f9"}}>	
		
						
						{this.state.ListData.map((list) => (	
						<div class="col-12 col-lg-12 col-xl-12 pr-2 mt-10">
							<div class="card gradient-primary">
									<div class="card-body">
										<div class="media">
											<div class="media-body">
												<div class="col-md-12 no-padd">
													
													
													<p class="mb-0 text-left soc-mt-0 font-s-16 font-w-550">{list.notice_title}</p>
													<p class="mt-5 mb-0 pt-0 text-left font-w-550"><span class="font-s-14">Date : {list.created_date}</span> </p>
													<br/>
													<p class="mb-0 text-left soc-mt-0 font-s-15 font-w-500 lh-20">Description : {list.notice_description}</p>
												
												</div>
												
											</div>
										</div>
									</div>
								
							</div>
						</div>
						))}
						
						
						
		
		
		
	</div>
	
 </section>
 
 




</div>




</div>
)
}
}
	
export default RestaurantDetails;