import React, {Component} from 'react';
import { Link, NavLink} from 'react-router-dom';
class Index extends Component {
componentDidMount() {
document.querySelector(".loader-wrapper").style = "display: block";
setTimeout(function() {
document.querySelector(".loader-wrapper").style = "display: none";
}, 900);
}
constructor (props) {
super (props)
}
render (){
return (
<div>
<section className="authentication-page section-b-space margin-pd">
   <div className="_3lldG">
   <div className="_2u0oK _1iY8Y">
      <form>
         <div className="_3TzfO page_head">
            <div className="_2QN4q">
               <Link to={`${process.env.PUBLIC_URL}/`}>
               <img src={`${process.env.PUBLIC_URL}/assets/images/icon/return_white.png`} alt="" /></Link>
            </div>
            <div className="_1leRb page_title">
               Appliance Repair
            </div>
            <div className="b5NJg"></div>
         </div>
         <input type="submit" hidden=""/>
      </form>
   </div>
   <div className="_3mMtt dashboard">
		<div class="row">
			<div class="col-12 col-lg-12 col-xl-12 pl-2 mt-2">
				 <div className="_3C_gI gradient-primary no-margin" role="button" tabIndex="0" >
				   <img src={`${process.env.PUBLIC_URL}/assets/images/icon/appliance.png`} class="product-img" alt="" />
				   <div className="NXGft ml-0">
					  <div className="_14v1V"><b>Appliance Repair</b></div>
				   </div>
				   <div className="order_details"> 
					  <a href="tel:+919820995927" class="view_pack_btn width-max"><b>Call Us</b></a>
				   </div>
				</div>
			</div>
			<div class="col-12 col-lg-12 col-xl-12 pl-2 mt-2">
				<div className="_3C_gI gradient-primary no-margin" role="button" tabIndex="0" >
                  <img src={`${process.env.PUBLIC_URL}/assets/images/icon/repair.png`} class="product-img" alt="" />
                  <div className="NXGft ml-0">
                     <div className="_14v1V"><b>Mobile Repair and Sales</b></div>
                  </div>
                  <div className="order_details"> 
					<a href="tel:+919967255471" class="view_pack_btn width-max"><b>Call Us</b></a>
				  </div>
               </div>
			</div>
		</div>
    </div>




	
    </div>
</section>

</div>
)
}
}
export default Index