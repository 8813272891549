import React, { Component } from 'react';
import {Redirect} from 'react-router-dom';
import { Link, NavLink} from 'react-router-dom';
import {getListData} from '../../../services/index';
import $ from 'jquery';

class address extends Component {

	
	
	componentDidMount() {
	document.querySelector(".loader-wrapper").style = "display: block";
	setTimeout(function() {
    document.querySelector(".loader-wrapper").style = "display: none";
}, 900);
		
	$('.float-label-1 .form-control-1[placeholder]').each(function () {
		if ($(this).attr('placeholder').length > 0) { $(this).addClass('active');}
	});
	$('.float-label-1 .form-control-1[value]').each(function () {
		if ($(this).val.length > 0) { $(this).addClass('active');}
	});
	$('.float-label-1 .form-control-1').on('blur', function () {
		if ($(this).val().length > 0) { $(this).addClass('active');} else {
			if ($(this)[0].hasAttribute('placeholder') === true) {
				if ($(this).attr('placeholder').length > 0) { $(this).addClass('active');} 
				else { $(this).removeClass('active');}
			} else { $(this).removeClass('active');}
		}
	});
	var storedArray  = JSON.parse(localStorage.getItem("userData"));
	let address_id =this.props.match.params.id;
	this.state.address_id = address_id;	
	getListData('address/edit_address_list',this.state).then((result) => {
	let responseJson = result;
	if(responseJson.data){
	if(result['count']>0){
	this.setState({ 
	ListData: result['data']
	})
	}
	document.getElementById("searchTextField").value = result['data'][0]['location'];	
	document.getElementById("flat_no").value = result['data'][0]['flat_no'];	
	document.getElementById("land_mark").value = result['data'][0]['land_mark'];	
	if(result['data'][0]['tag'] == 'Home'){
	document.getElementById("Home").checked = true;
	}
	if(result['data'][0]['tag'] == 'Work'){
	document.getElementById("Work").checked = true;
	}	
	if(result['data'][0]['tag'] == 'Other'){
	document.getElementById("Other").checked = true;
	}
	this.state.location = result['data'][0]['location'];
	this.state.flat_no = result['data'][0]['flat_no'];
	this.state.land_mark = result['data'][0]['land_mark'];
	this.state.tag = result['data'][0]['tag'];
	}
	});	
	
	
			const google = window.google;	
		//const $ = window.$;  
        initialize();
        function initialize() {
          var input = document.getElementById('searchTextField');
          var autocomplete = new google.maps.places.Autocomplete(input);
            google.maps.event.addListener(autocomplete, 'place_changed', function () {
                var place = autocomplete.getPlace();
                var latitude = place.geometry.location.lat();
                var longitude = place.geometry.location.lng();
                document.getElementById('cityLat').value = place.geometry.location.lat();
                document.getElementById('cityLng').value = place.geometry.location.lng();
				
            });
        }
        google.maps.event.addDomListener(window, 'load', initialize);
	
	}
	constructor(){
		super();
		var storedArray = JSON.parse(localStorage.getItem("userData"));
		this.state = {
			
			ListData : []
		};
	
		this.addadress = this.addadress.bind(this);
		this.onChange = this.onChange.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.goBack = this.goBack.bind(this);
	}
	
	handleChange(event) {
	  this.setState({
		tag: event.currentTarget.value,
		
	  });
	}
	
	addadress() {
		
			let address_id =this.props.match.params.id;
			this.state.address_id = address_id;
			this.state.cityLat = 	document.getElementById('cityLat').value;	
			this.state.cityLng = 	document.getElementById('cityLng').value;
			var app = {
				address_id : address_id,
				location : this.state.location,
				flat_no : this.state.flat_no,
				land_mark : this.state.land_mark,
				cityLat: this.state.cityLat,
				cityLng: this.state.cityLng,
				tag : this.state.tag
			}
			getListData('address/edit_address',app).then((result) => {
			if(result.status==400){
				document.getElementById('alert').innerHTML='<div class="alert alert-warning" role="alert"><i class="fa fa-info-circle" aria-hidden="true"></i> 1 Please insert all fields</div>';
			}
			else{
				document.getElementById('alert').innerHTML='';
			}
			this.setState({redirectToReferrer: true});
			});
		
		
	}

	onChange(e){
		this.setState({[e.target.name]:e.target.value});
	}
	goBack(){
		this.props.history.goBack();
	}
    
	

	render() {
	if(this.state.redirectToReferrer){		
		this.props.history.goBack();
	}
		return (
			<div>
                
				
                 
                {/*Home Section End*/}

                {/*collection banner*/}
                <section className="pb-0 margin-pd mt-45 restro">
					<div className="_2u0oK _1iY8Y">						
						   <div className="_3TzfO page_head" onClick={this.goBack}>
							  <div className="_2QN4q">
								 
								 <img src={`${process.env.PUBLIC_URL}/assets/images/icon/return_white.png`} alt="" />
							  </div>
							  <div className="_1leRb page_title">
								Update Address
							  </div>
							  <div className="b5NJg"></div>
						   </div>
					</div>
                </section>
				
				 {/*collection banner*/}
				 
                <section className="pb-0 margin-pd restro mt-45 details-pad-top-n">
				    <div className="res-details-div" role="button" tabIndex="0">
						<div className="res-details-info">
							<div className="details-dish-con">
								<div className="col-md-12">
									<div className="wrap-login200">
										 <form className="login100-form validate-form">
								<div className="form-group-1 float-label-1 validate-input" data-validate="Enter location">
												<input type="text" className="form-control-1" name="location" id="searchTextField" autoComplete="off" onChange={this.onChange} placeholder=""/>
												
												<label className="color lab-top"> <i class="fa fa-map-marker" aria-hidden="true"></i> Location on google map</label>
												<input type="hidden" id="cityLat" name="cityLat" />
												<input type="hidden" id="cityLng" name="cityLng" />
											</div>
											<div className="form-group-1 float-label-1 validate-input" data-validate="Enter flat_no">
												<input type="text" className="form-control-1" name="flat_no" id="flat_no" autoComplete="off" onChange={this.onChange} />
												<label className="color lab-top"> House / Flat No.</label>
											</div>
											<div className="form-group-1 float-label-1 validate-input" data-validate="Enter land_mark">
												<input type="text" className="form-control-1" name="land_mark" id="land_mark" autoComplete="off" onChange={this.onChange}/>
												<label className="color lab-top"> Landmark</label>
											</div>
											
											<div className="tag">
											<label className="color"> Tag </label>	<br/>
												<label className="mar-right color"><input type="radio" name="tag" id="Home"  value="Home"  onChange={this.handleChange}/> Home</label>
												<label className="mar-right color"><input type="radio" name="tag" id="Work" value="Work"  onChange={this.handleChange}/> Work</label>
												<label className="mar-right color"><input type="radio" name="tag" id="Other" value="Other"  onChange={this.handleChange}/> Other</label>
											</div>
											
											
											<div id="alert" className="m-b-10"></div>
											
											
											
											
											
								<div className="container-login100-form-btn">				
									<input type="button"  className="login100-form-btn" value="Save" onClick={this.addadress}/>
								</div>								
							</form>
									</div>
								</div>		
							</div>
						</div>
					</div>
                </section>
				
			
				
				


 
			</div>
			
			)

	}
}

export default address;
