import React, {Component} from 'react';
import { Link, NavLink} from 'react-router-dom';

class PayBills extends Component {


componentDidMount() {
document.querySelector(".loader-wrapper").style = "display: block";
	setTimeout(function() {
    document.querySelector(".loader-wrapper").style = "display: none";
}, 900);

if (localStorage.getItem('userData')){
function date_format(date){
	var fromDate = new Date(date);
	return new Date(fromDate).toDateString("dd-MM-yyyy");
}


var storedArray = JSON.parse(localStorage.getItem("userData"));
//document.getElementById("package").innerHTML = storedArray['data']['package'];
//document.getElementById("expiry_date").innerHTML = date_format(storedArray['data']['expiry_date']);
//document.getElementById("renewal_date").innerHTML = date_format(storedArray['data']['renewal_date']);
}
}
constructor (props) {
super (props)
	    if(!localStorage.getItem('userData')) {
		   this.props.history.push("login");
        }	
}

render (){
return (
<div>
   <section className="authentication-page section-b-space margin-pd">
      <div className="_3lldG pt-20">
         <div className="_2u0oK _1iY8Y border-blue">
            <form>
               <div className="_3TzfO page_head">
                  <div className="_2QN4q">
                     <Link to={`${process.env.PUBLIC_URL}/classic_broadband`}>
                     <img src={`${process.env.PUBLIC_URL}/assets/images/icon/return_white.png`} alt="" /></Link>
                  </div>
                  <div className="_1leRb page_title">
                     Renew Plan
                  </div>
                  <div className="b5NJg"></div>
               </div>
               <input type="submit" hidden=""/>
            </form>
         </div>
         <div className="_3mMtt dashboard m-plan">	
		  
		        <section className="renew-tab tab-product m-0">
                <div className="row">
                    <div className="col-sm-12 col-lg-12">
                        <div className="tab-content nav-material">                            
                            <div className="tab-pane fade mt-4 show active">
							<div className="tab-m-header"> 
								<span className="color-white">Bank Details</span>			
							</div>
							
                                <table className="table table-striped mb-0 table-renew">
								   <thead>  
								   {/* <tr>
                                        <td colSpan="2" className="tr-head">
										Your existing dues are <i className="fa fa-inr" aria-hidden="true"></i>0
															
										<form className="form-inline renew-form">	
											<div className="input-group mb-3">
											  <input type="text" className="form-control" placeholder="0"></input>
											  <div className="input-group-append">
												<button className="btn btn-outline-secondary" type="button">Pay</button>
											  </div>
											</div>	
										 </form>	
										
										</td>                                 
                                    </tr>   
									<tr>
                                        <td colSpan="2">Amount is inclusive of taxes and existing dues.</td>
                                    </tr>	
									*/} 
							
								   </thead>
								   
                                    <tbody> 
									<tr>
                                        <th><i className="fa fa-calendar" aria-hidden="true"></i> Bank Name</th>
                                        <td >HDFC Bank</td>
                                    </tr>
									<tr>
                                        <th><i className="fa fa-credit-card" aria-hidden="true"></i> Account Holder Name</th>
                                        <td >CLASSIC INTERNET AND CABLE SERVICES</td>
                                    </tr>
                                    <tr>
                                        <th><i className="fa fa-calendar" aria-hidden="true"></i> Account Number</th>
                                        <td >50200029167170</td>
                                    </tr>
                                    
									<tr>
                                        <th><i className="fa fa-credit-card" aria-hidden="true"></i> IFSC Code</th>
                                        <td >HDFC0000455</td>
                                    </tr>
									<tr>
                                        <td colSpan="2" style={{color:'red'}}><b>Transfer The Payment To Account</b></td>
                                    </tr>
									{/* <tr>
                                        <td colSpan="2"  className="text-center">
										<Link to={`${process.env.PUBLIC_URL}/purchase_history`}>View Your payment History</Link></td>
                                    </tr>									
                                    <tr>
                                        <th><i className="fa fa-calendar" aria-hidden="true"></i> Curren Expiry Date</th>
                                        <td id="expiry_date"></td>
                                    </tr>
                                    <tr>
                                        <th><i className="fa fa-calendar" aria-hidden="true"></i> Last Renew Date</th>
                                        <td id="renewal_date"></td>
                                    </tr>
                                    <tr>
                                        <th><i className="fa fa-credit-card" aria-hidden="true"></i> Current Plan</th>
                                        <td id="package"></td>
                                    </tr>
									
										 <tr>
										<td colSpan="2" className="tr-head" style={{padding:'0'}}>
											<div className="tab-m-header"> 
												<span className="color-white">Bank Details</span>			
											</div>
										</td>
										</tr> */}
									
									
                                    </tbody>
									
                                    <tfoot>									
									
                                    </tfoot>	
									
                                </table>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </section>
		 
		 
			
         </div>
      </div>
   </section>
</div>
)
}
}
export default PayBills