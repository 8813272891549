import React, {Component} from 'react';
import { Link, NavLink} from 'react-router-dom';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from 'react-loader-spinner';
import '../common/restaurant.scss';
import {getListData} from '../../services/index';
import $ from 'jquery';
import {Redirect} from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert'; // Import


class ManageAddress extends Component {

constructor(props) {
        super(props);
		this.state = {
			ListData: []
		};
		if(!localStorage.getItem('userData')) {
		   this.props.history.push("login");
        }
		
    }


	
componentDidMount() {
document.getElementById("color").setAttribute("href", `#` );		
		document.querySelector(".loader-wrapper").style = "display: block";
		setTimeout(function() {
            document.querySelector(".loader-wrapper").style = "display: none";
        }, 900);
		var storedArray  = JSON.parse(localStorage.getItem("userData"));
        this.state.user_id = storedArray['data']['user_id'];	
		getListData('address/address_list',this.state).then((result) => {
		let responseJson = result;
			if(responseJson.data){
			    if(result['count']>0){
					this.setState({ 
						ListData: result['data']
					})				
					//document.getElementById("loader").style = "display: none";
				}else{
					//document.getElementById("loader").style = "display: none";
					document.getElementById('alert').innerHTML='<div class="_2czoF"><div class="_1KevM empty-rest">No Address!</div></div>';
					
				}
			}
		});		
}


handledelete = (id) => {
	confirmAlert({
	 title: 'Are you sure',
	 message: 'are you sure u want to delete this address',
	 buttons: [
	 {
		 label: 'Yes',
		 onClick: () => {		
			var storedArray  = JSON.parse(localStorage.getItem("userData"));
			this.state.user_id = storedArray['data']['user_id'];
			this.state.address_id = id;
			var app = {
			user_id : storedArray['data']['user_id'],
			address_id : this.state.address_id,
			}
			getListData('address/delete_address',app).then((result) => {
				let responseJson = result;
					if(responseJson.data){
						if(result['count']>0){
								window.location = '/my_address'	
						}
					}
				});
		 }
	 },
	 {
	   label: 'No',
	   onClick: () => {}
	 }
   ]
 });
 }


render (){
return (

<div>
 			
   <section className="pb-0 margin-pd mt-45 restro">
   
					<div className="_2u0oK _1iY8Y">
						<form>
						   <div className="_3TzfO page_head">
							  <div className="_2QN4q">
								 <Link to={`${process.env.PUBLIC_URL}/account`}>
								 <img src={`${process.env.PUBLIC_URL}/assets/images/icon/return_white.png`} alt="" /></Link>
							  </div>
							  <div className="_1leRb page_title">
								 My Address
							  </div>
							  
							  <div className="b5NJg"></div>
						   </div>
						   <input type="submit" hidden=""/>
						</form>
					 </div>
				    <div className="add-back">
						<div className="_14v1V center">
								<div className="nowrap add-title left">Saved Address</div>
						</div>
					</div>
					<div id="alert" className="m-b-10"></div>
                </section>
				
				 {/*collection banner*/}
                <section className="pb-0 margin-pd restro details-pad-top-n">
				   
					{this.state.ListData.map((list) => (
                    <div className="add-details details-padding" role="button" tabIndex="0">
						<div className="res-details-info col-md-12">
							
							<div className="col-md-12 no-pad">
								<div className="_14v1V">
									<div className="nowrap details-title">{list.tag}</div>
								</div>
								<div className="details-dish-con">
									<div className="">
										<div className="details-dish-title details-color">{list.flat_no}, {list.location}</div>
										<div className="details-dish-title details-color">{list.land_mark}</div>

										 <Link to={`${process.env.PUBLIC_URL}/edit_address/${list.id}`}><div className="col-md-3 no-pad add-edit bold"> Edit</div></Link>
										<div className="col-md-3 no-pad add-edit bold" onClick={this.handledelete.bind(this,list.id)}> Delete</div>

									</div>
								  </div>
							</div>	  
						</div>
					</div>
					))}
					
                </section>
				
				 <section className="pb-0 margin-pd restro details-pad-top-n">
					<div className="order-btn">
						<Link to={`${process.env.PUBLIC_URL}/add_address/${this.state.user_id}`}>
							<button className="add-button">Add new Address</button>
						</Link>
					</div>
					
                </section>
				
   
</div>
)
}
}
export default ManageAddress