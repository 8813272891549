import React, {Component} from 'react';
import { Link, NavLink} from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import {getData} from '../../../services/index';

class PurchaseHistory extends Component {
	constructor (props) {
		super (props);
		this.state = {
			modal: false,
			nestedModal: false,
			closeAll: false,
			username: '',
			InvoiceData: [],
			ReceiptData: [],
			UserBalance: []
		};
		if(!localStorage.getItem('userData')) {
		   this.props.history.push("login");
		}
	}

	componentDidMount() {
		document.querySelector(".loader-wrapper").style = "display: block";
			setTimeout(function() {
			document.querySelector(".loader-wrapper").style = "display: none";
		}, 900);
		var storedArray  = JSON.parse(localStorage.getItem("userData"));
		this.state.username = storedArray['data']['username'];
		getData('auth/purchase_history',this.state).then((result) => {
		let responseJson = result;
			if(responseJson.data){
				this.setState({
					InvoiceData: result['data']['InvoiceData'],
					ReceiptData: result['data']['ReceiptData'],
					UserBalance: result['data']['UserData']
				})
			}
		});
	}



render (){
return (
<div>
   <section className="authentication-page section-b-space margin-pd">
      <div className="_3lldG">
         <div className="_2u0oK _1iY8Y border-blue">
            <form>
               <div className="_3TzfO page_head">
                  <div className="_2QN4q">
                     <Link to={`${process.env.PUBLIC_URL}/classic_broadband`}>
                     <img src={`${process.env.PUBLIC_URL}/assets/images/icon/return_white.png`} alt="" /></Link>
                  </div>
                  <div className="_1leRb page_title">
                     Payment History
                  </div>
                  <div className="b5NJg"></div>
               </div>
               <input type="submit" hidden=""/>
            </form>
         </div>
         <div className="_3mMtt dashboard m-plan">


		     <section className="renew-tab tab-product mt-20 purchase-tab">
                <div className="row">
                    <div className="col-sm-12 col-lg-12">
                        <Tabs className="tab-content nav-material">
                            <TabList className="nav nav-tabs nav-material">
                                <Tab className="nav-item">
                                    <span className="nav-link active">
                                        <i className="icofont icofont-ui-home"></i>PAYMENTS RECEIVED</span>
                                    <div className="material-border"></div>
                                </Tab>
                                <Tab className="nav-item">
                                    <span className="nav-link" ><i className="icofont icofont-man-in-glasses"></i>INVOICES GENERATED</span>
                                    <div className="material-border"></div>
                                </Tab>
                            </TabList>

						        <table className="table table-purchase">
                                  <tbody>

                                    <tr>
                                        <td> Opening Balance <p class="status"><i className="fa fa-inr" aria-hidden="true"></i>{this.state.UserBalance.OpeningBalanceAmount}</p></td>
                                        <td> Bill Amount <p><i className="fa fa-inr" aria-hidden="true"></i>{this.state.UserBalance.InvoiceAmount}</p></td>
                                        <td> Paid Amount <p><i className="fa fa-inr" aria-hidden="true"></i>{this.state.UserBalance.ReceiptAmount}</p></td>
                                        <td> Current Balance <p class="status-red"><i className="fa fa-inr" aria-hidden="true"></i>{this.state.UserBalance.BalanceAmount}</p></td>

                                    </tr>

								  </tbody>
                                 </table>


                            <TabPanel className="tab-pane fade mt-4 show active">
							<section className="renew-tab tab-product m-0 bg-purple tickets pt-50s">
								<div className="row">
									<div className="col-sm-12 col-lg-12">

									{this.state.ReceiptData.map((receipt) => (
										<div className="plans_card">
												<div className="card rounded-0 border-0 text-center pricing mb-3">
													<div className="card-body">
														<h4 className="plan-title">{receipt.ReceiptDate}</h4>
														<table className="table  mb-0 table-plan">
															<tbody>
															<tr>
															<th><i className="fa fa-credit-card" aria-hidden="true"></i> Payment Method</th>
																<td> {receipt.PaymentMethod}</td>
															</tr>
															<tr>
																<th>Receipt No.</th>
																<td>{receipt.ReceiptNo}</td>
															</tr>
															<tr>
																<th>Amount</th>
																<td><i class="fa fa-inr" aria-hidden="true"></i>{receipt.RecdAmount}</td>
															</tr>
															</tbody>
														</table>
													</div>
												</div>
										</div>
                                    ))}

									</div>
								</div>
							</section>


                            </TabPanel>

                            <TabPanel className="renew-tab2">
                            <section className="renew-tab tab-product m-0 bg-purple tickets ticket2 pt-50s">
								<div className="row">
									<div className="col-sm-12 col-lg-12">

{this.state.InvoiceData.map((invoice) => (

										<div className="plans_card">
												<div className="card rounded-0 border-0 text-center pricing mb-3">
													<div className="card-body">
														<h4 className="plan-title">{invoice.Invoice_Date}</h4>
														<table className="table  mb-0 table-plan">
															<tbody>
															<tr>
																<th><i className="fa fa-file-text" aria-hidden="true"></i> Particulars</th>
																<td>{invoice.InvoiceParticulars}</td>
															</tr>
															<tr>
															<th> Invoice No.</th>
																<td> {invoice.InvoiceNo}</td>
															</tr>
															<tr>
																<th>Amount</th>
																<td><i class="fa fa-inr" aria-hidden="true"></i>{invoice.TotalAmount}</td>
															</tr>

															</tbody>
														</table>
													</div>
												</div>
										</div>
))}

									</div>
								</div>
							</section>

                            </TabPanel>

                        </Tabs>
                    </div>
                </div>
            </section>

         </div>
      </div>
   </section>
</div>
)
}
}
export default PurchaseHistory
