import React, { Component } from 'react';
import { Link, NavLink} from 'react-router-dom';
import '../../common/restaurant.scss';
import {getListData} from '../../../services/index';



class Home extends Component {
	constructor(props) {
        super(props);
		this.state = {
			ListData: []
		};
    }

    componentDidMount() {
        document.getElementById("color").setAttribute("href", `#` );		
		document.querySelector(".loader-wrapper").style = "display: block";
		setTimeout(function() {
			document.querySelector(".loader-wrapper").style = "display: none";
		}, 900);
		
		
	
		this.getData();
		setInterval(this.getData, 5000);
    }
	
	getData = () => {
        var storedArray = JSON.parse(localStorage.getItem("userData"));
		this.state.user_id = storedArray['data']['user_id'];
		this.state.order_id = localStorage.getItem("order_id");
		getListData('restaurant/track_order_status', this.state).then((result) => {
		let responseJson = result;
		if (responseJson.data) {
			if (result['count'] > 0) {
				this.setState({
					ListData: result['data']
				})
			}
		}
	});
    }

	render() {
		return (
			<div>
{this.state.ListData.map((list) => (
<div class="track_main">
                <section className="pb-0 margin-pd mt-45 restro track_order">
					<div className="_2u0oK _1iY8Y">
						<form>
						   <div className="_3TzfO page_head">
							  <div className="_2QN4q">
								 <Link to={`${process.env.PUBLIC_URL}/`}>
								 <img src={`${process.env.PUBLIC_URL}/assets/images/icon/return_white.png`} alt="" /></Link>
							  </div>
							  <div className="_1leRb page_title">
								 Track Your Order
							  </div>
							  <div className="b5NJg"></div>
						   </div>
						   <input type="submit" hidden=""/>
						</form>
					 </div>
				    <Link to={`${process.env.PUBLIC_URL}/`}>
                    <div className="res-details-div" role="button" tabIndex="0">
						<div className="res-details-info">
							<div className="order-image">
								<div className="bgimg order-bot-mar" style={{backgroundImage: `url(${list.image})`}}>
								</div>
							</div>
							<div className="_14v1V center">
								<div className="nowrap details-title-col left order-bot-mar">
									{list.restaurant_name}
								</div>
								<div className="order-title left order-bot-mar">
									Order placed on : {list.date_created}
								</div>
								<div className="order-title left order-bot-mar">
									Payment Mode : Cash on Delivery
								</div>
							</div>
						</div>
						
					</div>
					</Link>	
                </section>
                <section className="pb-0 margin-pd restro details-pad-top-n">
				    
                    <div className="res-details-div details-padding" role="button" tabIndex="0">
						<div className="res-details-info">
							<div className="_14v1V">
								<div className="nowrap details-title">Bill Details</div>
							</div>
							<div className="details-dish-con">
								<div className="">
									<div className="details-dish-title details-color col-md-9 no-pad">Item Total</div>
									<div className="order-price col-md-3 right no-pad"><i className="fa fa-inr" aria-hidden="true"></i> {list.total_cost}</div>
								</div>
								
								<div className="">
									<div className="details-dish-title col-md-9 no-pad details-title-col order-m-b">Total Discount</div>
									<div className="order-price col-md-3 right no-pad details-title-col order-m-b">- <i className="fa fa-inr" aria-hidden="true"></i> {list.discount}</div>
								</div>
								<div className="">
									<div className="details-dish-title col-md-9 no-pad details-color order-border-t">Delivery Fee</div>
									<div className="order-price col-md-3 right no-pad details-color order-border-t"><i className="fa fa-inr" aria-hidden="true"></i> {list.delivery_fee}</div>
								</div>
								<div className="">
									<div className="details-dish-title col-md-9 no-pad details-color bold order-border-t">To Pay</div>
									<div className="order-price col-md-3 right no-pad details-color bold order-border-t" ><i className="fa fa-inr" aria-hidden="true"></i> {list.paid_amount}</div>
								</div>
								
							  </div>
						</div>					
					</div>
                </section>
				
				<section className="pb-0 margin-pd restro details-pad-top-n">
					<div className="res-details-div details-padding" role="button" tabIndex="0">
						<div className="res-details-info">
								<div className="_14v1V">
									<div className="nowrap details-title">Track Details</div>
								</div>
								<div className="details-dish-con">
								   
								   {(() => {
        if (list.status=='Order Placed') {
          return (
		  <div>
				<div className="col-md-12 no-pad">
										<div className="col-md-2 no-pad track-min-height center">
										<img src={`${process.env.PUBLIC_URL}/assets/images/restaurants/dot.png`} />
										<img className="line" src={`${process.env.PUBLIC_URL}/assets/images/restaurants/line_black.png`} />
										</div>
										<div className="col-md-6 track-min-height">Order Placed
											<div className="order-title left order-bot-mar">
												{list.date_created}
											</div>
										</div>
										<div className="col-md-4 track-min-height track_img">
											<img src={`${process.env.PUBLIC_URL}/assets/images/icon/order-placed.gif`} />
										</div>
									</div>
									<div className="col-md-12 no-pad">
										<div className="col-md-2 no-pad track-min-height center">
										<img className=" m_top_m" src={`${process.env.PUBLIC_URL}/assets/images/restaurants/dot_black.png`}/>
										<img className="line" src={`${process.env.PUBLIC_URL}/assets/images/restaurants/line_black.png`}/>
										</div>
										<div className="col-md-10 track-min-height">Confrim by Restaurant
										</div>
									</div>
									<div className="col-md-12 no-pad">
										<div className="col-md-2 no-pad track-min-height center">
										<img  className=" m_top_m" src={`${process.env.PUBLIC_URL}/assets/images/restaurants/dot_black.png`}/>
										<img className="line" src={`${process.env.PUBLIC_URL}/assets/images/restaurants/line_black.png`}/>
										</div>
										<div className="col-md-10 track-min-height">Out For Delivery</div>
									</div>
									<div className="col-md-12 no-pad">
										<div className="col-md-2 no-pad track-min-height-1 center">
										<img className=" m_top_m_1" src={`${process.env.PUBLIC_URL}/assets/images/restaurants/dot_black.png`}/>
										</div>
										<div className="col-md-10">Delivered</div>
									</div>
								</div>
          )
        } else if (list.status=='Confirm By Restaurant') {
          return (
             <div>
				<div className="col-md-12 no-pad">
										<div className="col-md-2 no-pad track-min-height center">
										<img src={`${process.env.PUBLIC_URL}/assets/images/restaurants/dot.png`} />
										<img className="line" src={`${process.env.PUBLIC_URL}/assets/images/restaurants/line.png`} />
										</div>
										<div className="col-md-10 track-min-height">Order Placed
											<div className="order-title left order-bot-mar">
												{list.date_created}
											</div>
										</div>
									</div>
									<div className="col-md-12 no-pad">
										<div className="col-md-2 no-pad track-min-height center">
										<img className=" m_top_m" src={`${process.env.PUBLIC_URL}/assets/images/restaurants/dot.png`}/>
										<img className="line" src={`${process.env.PUBLIC_URL}/assets/images/restaurants/line_black.png`}/>
										</div>
										<div className="col-md-6 track-min-height">Confrim by Restaurant
											<div className="order-title left order-bot-mar">
												{list.last_updated}
											</div>
										</div>
										<div className="col-md-4 track-min-height track_img">
											<img src={`${process.env.PUBLIC_URL}/assets/images/icon/order-preparing.gif`} />
										</div>
									</div>
									<div className="col-md-12 no-pad">
										<div className="col-md-2 no-pad track-min-height center">
										<img  className=" m_top_m" src={`${process.env.PUBLIC_URL}/assets/images/restaurants/dot_black.png`}/>
										<img className="line" src={`${process.env.PUBLIC_URL}/assets/images/restaurants/line_black.png`}/>
										</div>
										<div className="col-md-10 track-min-height">Out For Delivery</div>
									</div>
									<div className="col-md-12 no-pad">
										<div className="col-md-2 no-pad track-min-height-1 center">
										<img className=" m_top_m_1" src={`${process.env.PUBLIC_URL}/assets/images/restaurants/dot_black.png`}/>
										</div>
										<div className="col-md-10">Delivered</div>
									</div>
								</div>
          )
        } else if (list.status=='Out For Delivery') {
          return (
             <div>
				<div className="col-md-12 no-pad">
										<div className="col-md-2 no-pad track-min-height center">
										<img src={`${process.env.PUBLIC_URL}/assets/images/restaurants/dot.png`} />
										<img className="line" src={`${process.env.PUBLIC_URL}/assets/images/restaurants/line.png`} />
										</div>
										<div className="col-md-10 track-min-height">Order Placed
											<div className="order-title left order-bot-mar">
												{list.date_created}
											</div>
										</div>
									</div>
									<div className="col-md-12 no-pad">
										<div className="col-md-2 no-pad track-min-height center">
										<img className=" m_top_m" src={`${process.env.PUBLIC_URL}/assets/images/restaurants/dot.png`}/>
										<img className="line" src={`${process.env.PUBLIC_URL}/assets/images/restaurants/line.png`}/>
										</div>
										<div className="col-md-10 track-min-height">Confrim by Restaurant
										</div>
									</div>
									<div className="col-md-12 no-pad">
										<div className="col-md-2 no-pad track-min-height center">
										<img className=" m_top_m" src={`${process.env.PUBLIC_URL}/assets/images/restaurants/dot.png`}/>
										<img className="line" src={`${process.env.PUBLIC_URL}/assets/images/restaurants/line_black.png`}/>
										</div>
										<div className="col-md-6 track-min-height">Out For Delivery
											<div className="order-title left order-bot-mar">
												{list.last_updated}
											</div>
										</div>
										<div className="col-md-4 track-min-height track_img">
											<img src={`${process.env.PUBLIC_URL}/assets/images/icon/order-onway.gif`} />
										</div>
									</div>
									<div className="col-md-12 no-pad">
										<div className="col-md-2 no-pad track-min-height-1 center">
										<img className=" m_top_m_1" src={`${process.env.PUBLIC_URL}/assets/images/restaurants/dot_black.png`}/>
										</div>
										<div className="col-md-10">Delivered</div>
									</div>
								</div>
          )
        } else if (list.status=='Delivered') {
          return (
             <div>
				<div className="col-md-12 no-pad">
										<div className="col-md-2 no-pad track-min-height center">
										<img src={`${process.env.PUBLIC_URL}/assets/images/restaurants/dot.png`} />
										<img className="line" src={`${process.env.PUBLIC_URL}/assets/images/restaurants/line.png`} />
										</div>
										<div className="col-md-10 track-min-height">Order Placed
											<div className="order-title left order-bot-mar">
												{list.date_created}
											</div>
										</div>
									</div>
									<div className="col-md-12 no-pad">
										<div className="col-md-2 no-pad track-min-height center">
										<img className=" m_top_m" src={`${process.env.PUBLIC_URL}/assets/images/restaurants/dot.png`}/>
										<img className="line" src={`${process.env.PUBLIC_URL}/assets/images/restaurants/line.png`}/>
										</div>
										<div className="col-md-10 track-min-height">Confrim by Restaurant
										</div>
									</div>
									<div className="col-md-12 no-pad">
										<div className="col-md-2 no-pad track-min-height center">
										<img className=" m_top_m" src={`${process.env.PUBLIC_URL}/assets/images/restaurants/dot.png`}/>
										<img className="line" src={`${process.env.PUBLIC_URL}/assets/images/restaurants/line.png`}/>
										</div>
										<div className="col-md-10 track-min-height">Out For Delivery
										</div>
									</div>
									<div className="col-md-12 no-pad">
										<div className="col-md-2 no-pad track-min-height-1 center">
										<img className=" m_top_m deliver_dot" src={`${process.env.PUBLIC_URL}/assets/images/restaurants/dot.png`}/>
										</div>
										<div className="col-md-6">Delivered
											<div className="order-title left order-bot-mar">
												{list.last_updated}
											</div>
											</div>
										<div className="col-md-4 track-min-height track_img">
											<img src={`${process.env.PUBLIC_URL}/assets/images/icon/order-placed.gif`} />
										</div>
									</div>
								</div>
          )
        }else if (list.status=='cancelled') {
          return (
             <div>
				<div className="col-md-12 no-pad order-cancle">
										
										<i class="fa fa-times-circle" aria-hidden="true"></i> Order Cancelled
										
									</div>
								</div>
          )
        }
      })()}

								</div>
						</div>
						
					</div>
                </section>
				 <section className="pb-0 margin-pd restro details-pad-top-n">
					<div className="order-btn">
						<a className="order-button click-call" href={list.click_call}> <i class="fa fa-phone" aria-hidden="true"></i> call restaurant</a>
					</div>
                </section>
				</div>
))}



 
			</div>
			
			)

	}
}

export default Home;
