import React, { Component } from 'react';
import {Redirect} from 'react-router-dom';
import { Link, NavLink} from 'react-router-dom';
import {getListData} from '../../../services/index';
import $ from 'jquery';
import { message,Input } from 'antd';



const { TextArea } = Input;


class address extends Component {

	componentDidMount() {
		document.querySelector(".loader-wrapper").style = "display: block";
	setTimeout(function() {
		document.querySelector(".loader-wrapper").style = "display: none";
    }, 900);
		
		let c_userid = this.props.match.params.id;
		this.setState({
					c_userid: c_userid,
				})
		
	}
	constructor(){
		super();
		var storedArray = JSON.parse(localStorage.getItem("userData"));
		this.state = {
			c_userid: '',
			c_description: '',
			
		};
		this.addComplaint = this.addComplaint.bind(this);
		this.onChange = this.onChange.bind(this);
		this.handleChange = this.handleChange.bind(this);
		
		
		
	}
	handleChange(event) {
		
	  this.setState({
		tag: event.target.value
	  });
	}
	
	addComplaint() {
		if(this.state.c_description != ''){	
			var c_description = 	this.state.c_description;	
			var c_userid = 	this.state.c_userid;
			var app = {
					c_description : c_description,
					c_userid : c_userid
			}
			getListData('society/SocietyController/add_complaint',app).then((result) => {
			if(result.status==400){
				document.getElementById('alert').innerHTML='<div class="alert alert-warning" role="alert"><i class="fa fa-info-circle" aria-hidden="true"></i> Please Insert Complaints</div>';
			}
			else{
				message.config({ top: 150});
				message.success('Complaint Submit Successfull',2);
				document.getElementById('alert').innerHTML='';
				window.location.href = '/complaints/'+c_userid;
			}
			});
		}else{
			document.getElementById('alert').innerHTML='<div class="alert alert-warning" role="alert"><i class="fa fa-info-circle" aria-hidden="true"></i> Please Insert Complaints</div>';
		}
		
		
	}
	onChange(e){
		
		this.setState({[e.target.name]:e.target.value});
	}
	
    
	

	render() {
	
		return (
			<div>
                
				
 
                <section className="pb-0 margin-pd mt-45 restro">
					<div className="_2u0oK _1iY8Y">
						
						   <div className="_3TzfO page_head">
							  <div className="_2QN4q">
								 <Link to={`${process.env.PUBLIC_URL}/complaints/${this.state.c_userid}`}>
									<img src={`${process.env.PUBLIC_URL}/assets/images/icon/return_white.png`} alt="" /></Link>
							  </div>
							  <div className="_1leRb page_title">
								Submit Complaint
							  </div>
							  <div className="b5NJg"></div>
						   </div>
					 </div>
                </section>
				
		
                <section className="pb-0 margin-pd restro details-pad-top-n mgt-60">
				    <div className="res-details-div" role="button" tabIndex="0">
						<div className="res-details-info">
							<div className="details-dish-con">
								<div className="col-md-12">
									<div className="wrap-login200">
										 <form className="login100-form validate-form">
											
											
											
											<div className="col-md-12">
												<TextArea rows={10} name="c_description" placeholder="Write your complaints" onChange={this.onChange}/>
											</div>
											
											<div id="alert" className="m-b-10"></div>
											
											
											
											
											
											
											
								<div className="container-login100-form-btn">				
									<input type="button"  className="login100-form-btn" value="Submit Complaint" onClick={this.addComplaint}/>
								</div>								
							</form>
									</div>
								</div>		
							</div>
						</div>
					</div>
                </section>
				
				
				
				


 
			</div>
			
			)

	}
}

export default address;
