import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { ScrollContext } from 'react-router-scroll-4';
import './index.scss';
//import { initializeFirebase } from './push-notification';
import * as serviceWorker from './serviceWorker';


// Layouts
import chat from './components/pages/chat';
import Home from './components/layouts/home/main';
import Search from './components/pages/search';
import Location from './components/pages/location';
import Account from './components/pages/account';
import Cart from './components/pages/cart';
import Notification from './components/pages/notification';
import Comingsoon from './components/pages/comingsoon';
import Login from './components/pages/login';
import Register from './components/pages/register';
import Otp from './components/pages/otp';
import MyAddress from './components/pages/my_address';
import MyOrders from './components/pages/my_orders';
import MyOrder from './components/pages/my_order';
import OrderHistory from './components/pages/order_history';
import MyProfile from './components/pages/my_profile';
import getUserId from './components/pages/getUserId';

//Restaurant
import OrderFoodOnline from './components/pages/order_food_online/index';
import RestaurantDetails from './components/pages/order_food_online/restaurant_details';
import RestaurantOrder from './components/pages/order_food_online/restaurant_order';
import ManageAddress from './components/pages/order_food_online/manage_address';
import ThankYou from './components/pages/buy_cars_bike/thank_you';
import TrackOrder from './components/pages/order_food_online/track_order';
import AddressAdd from './components/pages/order_food_online/add_address';
import AddressEdit from './components/pages/order_food_online/edit_address';

//Cars And Bike
import BuyCarBike from './components/pages/buy_cars_bike/index';
import DetailsCar from './components/pages/buy_cars_bike/car_details';
import DetailsBike from './components/pages/buy_cars_bike/bike_details';
import EnquiryCar from './components/pages/buy_cars_bike/car_enquiry';
import EnquiryBike from './components/pages/buy_cars_bike/bike_enquiry';
import BikeGallery from './components/pages/buy_cars_bike/bike_gallery';
import CarGallery from './components/pages/buy_cars_bike/car_gallery';

//Medicine
import OrderMedicine from './components/pages/order_medicine/index';
import OrderMedicineChat from './components/pages/order_medicine/chat';

//Gym 
import GymFitness from './components/pages/gym_fitness/index';
import Gallery from './components/pages/gym_fitness/gallery';

//Lab 
import LabTest from './components/pages/lab_test/index';
import AllTest from './components/pages/lab_test/all_test';
import HealthPackage from './components/pages/lab_test/health_package';
import UploadPrescription from './components/pages/lab_test/upload_prescription';
import LabsPackageDetails from './components/pages/lab_test/package_details';

//ads
import Ads from './components/pages/ads/index'; 

//Tours & Travels
import ToursTravels from './components/pages/tours_travels/index'; 
import TravelsDetails from './components/pages/tours_travels/travels_details'; 
import PackageDetails from './components/pages/tours_travels/package_details'; 
import PackageEnquiry from './components/pages/tours_travels/package_enquiry'; 

//Travel
import Travel from './components/pages/travel/index'; 

//Internet
import Internet from './components/pages/internet/index'; 

//Appliance
import Appliance from './components/pages/appliance/index'; 

//clothes
import Clothes from './components/pages/clothes/index'; 

//Property
import Property from './components/pages/property/index'; 

//Veggies
import Veggies from './components/pages/veggies/index'; 
import VeggiesOrder from './components/pages/veggies/veggies_order'; 
import VeggiesAddress from './components/pages/veggies/veggies_address'; 
import VeggiesTrackOrder from './components/pages/veggies/veggies_track_order'; 
import VeggiesMyOrder from './components/pages/veggies/veggies_my_order'; 
import VeggiesOrderHistory from './components/pages/veggies/veggies_order_history'; 

//Grocery
import Grocery from './components/pages/grocery/index'; 
import GroceryOrder from './components/pages/grocery/grocery_order'; 
import GroceryAddress from './components/pages/grocery/grocery_address'; 
import GroceryTrackOrder from './components/pages/grocery/grocery_track_order'; 
import GroceryMyOrder from './components/pages/grocery/grocery_my_order'; 
import GroceryOrderHistory from './components/pages/grocery/grocery_order_history'; 
 


//Beauty Parlour
import BeautyParlour from './components/pages/beauty_parlour/index'; 
import OrderDetails from './components/pages/beauty_parlour/order_details'; 

//Dark Grey Collection
import DarkGreyCollection from './components/pages/dark_grey_collection/index'; 

//Jewellery
import Jewellery from './components/pages/jewellery/index'; 

//Feedback
import Feedback from './components/pages/feedback'; 
import feedbackThank from './components/pages/feedback_thank'; 

//Physiotherapy 
import Physiotherapy from './components/pages/physiotherapy/index';
import PhysioThanks from './components/pages/physiotherapy/physio_thanks';

//society 
import Society from './components/pages/society/index';
import SocietyDetails from './components/pages/society/society_details';
import PaymentHistory from './components/pages/society/payment-history';
import Complaints from './components/pages/society/complaints';
import NoticeBoard from './components/pages/society/notice-board';
import NoticeDetails from './components/pages/society/notice-details';
import ParkingDetails from './components/pages/society/parking-details';
import Meetings from './components/pages/society/meetings';
import MeetingsDetails from './components/pages/society/meetings-details';
import Bill from './components/pages/society/bill';
import BillDetails from './components/pages/society/bill_details';
import SocietyProfile from './components/pages/society/my-profile';
import AddComplaint from './components/pages/society/add-complaint';

//Classic Broadband
import ClassicBroadband from './components/pages/classic_broadband/index';
import AccountInfo from './components/pages/classic_broadband/account_info';
import RenewPlan from './components/pages/classic_broadband/renew_plan';
import PayBills from './components/pages/classic_broadband/pay_bills';
import SubmitComplaint from './components/pages/classic_broadband/submit_complaint';
import InternetPlans from './components/pages/classic_broadband/internet_plans';
import PurchaseHistory from './components/pages/classic_broadband/purchase_history';
import ClassicPlans from './components/pages/classic_broadband/classic_plans';
import EnquiryPlans from './components/pages/classic_broadband/plans_enquiry';
import ClassicProfile from './components/pages/classic_broadband/classic_profile';
import Thanks from './components/pages/classic_broadband/thanks';

import TalkTo from './components/pages/talk_to';

import Layout from './components/app';
import PageNotFound from './components/pages/404';
import store from './store';
import { getAllProducts } from './actions'

class Root extends React.Component {

    render() {
		//store.dispatch(getAllProducts());
        return(		
        	<Provider store={store}>
				<BrowserRouter basename={'/'} >
					<ScrollContext>
						<Switch>    
						    <Route path={`${process.env.PUBLIC_URL}/login`} component={Login}/>
							<Route path={`${process.env.PUBLIC_URL}/register`} component={Register}/>
							<Route path={`${process.env.PUBLIC_URL}/otp/:id`} component={Otp}/>
							<Route path={`${process.env.PUBLIC_URL}/society`} component={Society}/>
							<Route path={`${process.env.PUBLIC_URL}/society_details/:id`} component={SocietyDetails}/>
							
							<Route path={`${process.env.PUBLIC_URL}/payment-history/:id/:unit_id`} component={PaymentHistory}/> 
							
							<Route path={`${process.env.PUBLIC_URL}/complaints/:id`} component={Complaints}/>
							<Route path={`${process.env.PUBLIC_URL}/notice-board/:id`} component={NoticeBoard}/>
							<Route path={`${process.env.PUBLIC_URL}/notice-details/:id`} component={NoticeDetails}/>
							<Route path={`${process.env.PUBLIC_URL}/parking-details/:id`} component={ParkingDetails}/>
							<Route path={`${process.env.PUBLIC_URL}/meetings/:id`} component={Meetings}/>
							<Route path={`${process.env.PUBLIC_URL}/meetings-details/:id`} component={MeetingsDetails}/>
							<Route path={`${process.env.PUBLIC_URL}/society-profile/:id`} component={SocietyProfile}/>
							<Route path={`${process.env.PUBLIC_URL}/bill/:id/:unit_id`} component={Bill}/>
							<Route path={`${process.env.PUBLIC_URL}/bill_details/:id/:bill_id`} component={BillDetails}/>
							<Route path={`${process.env.PUBLIC_URL}/add-complaint/:id`} component={AddComplaint}/>
							<Layout>
								<Route exact path={`${process.env.PUBLIC_URL}/`} component={Home}/>
								<Route path={`${process.env.PUBLIC_URL}/account`} component={Account}/>
								<Route path={`${process.env.PUBLIC_URL}/chat`} component={chat}/>
								
								<Route path={`${process.env.PUBLIC_URL}/cart`} component={Cart}/>
								<Route path={`${process.env.PUBLIC_URL}/my_profile`} component={MyProfile}/>
								<Route path={`${process.env.PUBLIC_URL}/notification`} component={Notification}/>
								<Route path={`${process.env.PUBLIC_URL}/search`} component={Search}/>
								<Route path={`${process.env.PUBLIC_URL}/location`} component={Location}/>
								<Route path={`${process.env.PUBLIC_URL}/comingsoon`} component={Comingsoon}/>
								<Route path={`${process.env.PUBLIC_URL}/classic_broadband`} component={ClassicBroadband}/>
								<Route path={`${process.env.PUBLIC_URL}/account_info`} component={AccountInfo}/>        
								<Route path={`${process.env.PUBLIC_URL}/renew_plan`} component={RenewPlan}/>
								<Route path={`${process.env.PUBLIC_URL}/classic_plans`} component={ClassicPlans}/>
								<Route path={`${process.env.PUBLIC_URL}/classic_profile`} component={ClassicProfile}/>
								<Route path={`${process.env.PUBLIC_URL}/plans_enquiry/:id`} component={EnquiryPlans}/>
								<Route path={`${process.env.PUBLIC_URL}/thanks/`} component={Thanks}/>
								<Route path={`${process.env.PUBLIC_URL}/pay_bills`} component={PayBills}/>
								<Route path={`${process.env.PUBLIC_URL}/submit_complaint`} component={SubmitComplaint}/>
								<Route path={`${process.env.PUBLIC_URL}/internet_plans`} component={InternetPlans}/>
								<Route path={`${process.env.PUBLIC_URL}/purchase_history`} component={PurchaseHistory}/>
								<Route path={`${process.env.PUBLIC_URL}/my_address`} component={MyAddress}/>
								<Route path={`${process.env.PUBLIC_URL}/edit_address/:id`} component={AddressEdit}/>
								<Route path={`${process.env.PUBLIC_URL}/my_orders`} component={MyOrders}/>
								<Route path={`${process.env.PUBLIC_URL}/my_order`} component={MyOrder}/>
								<Route path={`${process.env.PUBLIC_URL}/order_history/:id`} component={OrderHistory}/>
								<Route path={`${process.env.PUBLIC_URL}/order_food_online`} component={OrderFoodOnline}/>
								<Route path={`${process.env.PUBLIC_URL}/restaurant_details/:id`} component={RestaurantDetails}/>
								
								<Route path={`${process.env.PUBLIC_URL}/restaurant_order`} component={RestaurantOrder}/>

								<Route path={`${process.env.PUBLIC_URL}/buy_cars_bike`} component={BuyCarBike}/>
								<Route path={`${process.env.PUBLIC_URL}/car_details/:id`} component={DetailsCar}/>
								<Route path={`${process.env.PUBLIC_URL}/bike_gallery/:id`} component={BikeGallery}/>
								<Route path={`${process.env.PUBLIC_URL}/car_gallery/:id`} component={CarGallery}/>
								<Route path={`${process.env.PUBLIC_URL}/bike_details/:id`} component={DetailsBike}/>
								<Route path={`${process.env.PUBLIC_URL}/car_enquiry/:id`} component={EnquiryCar}/>
								<Route path={`${process.env.PUBLIC_URL}/bike_enquiry/:id`} component={EnquiryBike}/>

								<Route path={`${process.env.PUBLIC_URL}/manage_address`} component={ManageAddress}/>
								<Route path={`${process.env.PUBLIC_URL}/add_address`} component={AddressAdd}/>
								<Route path={`${process.env.PUBLIC_URL}/thank_you`} component={ThankYou}/>
								<Route path={`${process.env.PUBLIC_URL}/track_order`} component={TrackOrder}/>
								<Route path={`${process.env.PUBLIC_URL}/order_medicine`} component={OrderMedicine}/>
								<Route path={`${process.env.PUBLIC_URL}/order_medicine_chat/:id`} component={OrderMedicineChat}/>
								<Route path={`${process.env.PUBLIC_URL}/gym_fitness`} component={GymFitness}/>
								<Route path={`${process.env.PUBLIC_URL}/lab_test`} component={LabTest}/>
								<Route path={`${process.env.PUBLIC_URL}/all_test`} component={AllTest}/>
								<Route path={`${process.env.PUBLIC_URL}/health_package`} component={HealthPackage}/>
								<Route path={`${process.env.PUBLIC_URL}/upload_prescription`} component={UploadPrescription}/>
								<Route path={`${process.env.PUBLIC_URL}/labs_package_details/:id`} component={LabsPackageDetails}/>
								<Route path={`${process.env.PUBLIC_URL}/gallery`} component={Gallery}/>
								<Route path={`${process.env.PUBLIC_URL}/getUserId`} component={getUserId}/>

								<Route path={`${process.env.PUBLIC_URL}/physiotherapy`} component={Physiotherapy}/>
								<Route path={`${process.env.PUBLIC_URL}/physio_thanks`} component={PhysioThanks}/>

								<Route path={`${process.env.PUBLIC_URL}/talk_to`} component={TalkTo}/>
								<Route path={`${process.env.PUBLIC_URL}/feedback`} component={Feedback}/>
								<Route path={`${process.env.PUBLIC_URL}/feedback_thank`} component={feedbackThank}/>
								<Route path={`${process.env.PUBLIC_URL}/jewellery`} component={Jewellery}/>
								
								<Route path={`${process.env.PUBLIC_URL}/travel`} component={Travel}/>
								
								<Route path={`${process.env.PUBLIC_URL}/tours_travels`} component={ToursTravels}/>
								<Route path={`${process.env.PUBLIC_URL}/travels_details/:id`} component={TravelsDetails}/>
								<Route path={`${process.env.PUBLIC_URL}/package_details/:id`} component={PackageDetails}/>
								<Route path={`${process.env.PUBLIC_URL}/package_enquiry/:id`} component={PackageEnquiry}/>
								
								
								<Route path={`${process.env.PUBLIC_URL}/internet`} component={Internet}/>
								<Route path={`${process.env.PUBLIC_URL}/ads`} component={Ads}/>
								
								<Route path={`${process.env.PUBLIC_URL}/beauty_parlour`} component={BeautyParlour}/>
								<Route path={`${process.env.PUBLIC_URL}/order_details`} component={OrderDetails}/>
								
								<Route path={`${process.env.PUBLIC_URL}/dark_grey_collection`} component={DarkGreyCollection}/>
								<Route path={`${process.env.PUBLIC_URL}/appliance`} component={Appliance}/>
								<Route path={`${process.env.PUBLIC_URL}/clothes`} component={Clothes}/>
								<Route path={`${process.env.PUBLIC_URL}/property`} component={Property}/>
								
								
								<Route path={`${process.env.PUBLIC_URL}/veggies`} component={Veggies}/>
								<Route path={`${process.env.PUBLIC_URL}/veggies_order`} component={VeggiesOrder}/>
								<Route path={`${process.env.PUBLIC_URL}/veggies_address`} component={VeggiesAddress}/>
								<Route path={`${process.env.PUBLIC_URL}/veggies_track_order`} component={VeggiesTrackOrder}/>
								<Route path={`${process.env.PUBLIC_URL}/veggies_my_order`} component={VeggiesMyOrder}/>
								<Route path={`${process.env.PUBLIC_URL}/veggies_order_history/:id`} component={VeggiesOrderHistory}/>
								
								<Route path={`${process.env.PUBLIC_URL}/grocery`} component={Grocery}/>
								<Route path={`${process.env.PUBLIC_URL}/grocery_order`} component={GroceryOrder}/>
								<Route path={`${process.env.PUBLIC_URL}/grocery_address`} component={GroceryAddress}/>
								<Route path={`${process.env.PUBLIC_URL}/grocery_track_order`} component={GroceryTrackOrder}/>
								<Route path={`${process.env.PUBLIC_URL}/grocery_my_order`} component={GroceryMyOrder}/>
								<Route path={`${process.env.PUBLIC_URL}/grocery_order_history/:id`} component={GroceryOrderHistory}/>

							</Layout>
							<Route exact component={PageNotFound} />
						</Switch>	
					</ScrollContext>
				</BrowserRouter>
			</Provider>
    	);
    }
}

ReactDOM.render(<Root />, document.getElementById('root'));

//initializeFirebase();
