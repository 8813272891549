
import React, {Component} from 'react';
import { Link, NavLink} from 'react-router-dom';
import $ from 'jquery';
class AccountInfo extends Component {
componentDidMount() {
document.querySelector(".loader-wrapper").style = "display: block";
	setTimeout(function() {
    document.querySelector(".loader-wrapper").style = "display: none";
}, 900);

if (localStorage.getItem('userData')){
function date_format(date){
	var fromDate = new Date(date);
	return new Date(fromDate).toDateString("dd-MM-yyyy");
}
var storedArray = JSON.parse(localStorage.getItem("userData"));
document.getElementById("name").innerHTML = storedArray['data']['name'];
document.getElementById("account_status").innerHTML = storedArray['data']['account_status'];
document.getElementById("login_status").innerHTML = storedArray['data']['login_status'];
document.getElementById("package").innerHTML = storedArray['data']['package'];
document.getElementById("created_on").innerHTML = storedArray['data']['created_on'];

var fromDate = new Date(storedArray['data']['expiry_date']);
document.getElementById("expiry_date").innerHTML = date_format(storedArray['data']['expiry_date']);

var renewalDate = new Date(storedArray['data']['renewal_date']);
document.getElementById("renewal_date").innerHTML = date_format(storedArray['data']['renewal_date']);
document.getElementById("disable_time").innerHTML = storedArray['data']['disable_time'];

var plan_array = JSON.parse(localStorage.getItem("userData"));
document.getElementById("download_speed").innerHTML =  plan_array['plan']['download_speed'];
document.getElementById("upload_speed").innerHTML = plan_array['plan']['upload_speed'];
document.getElementById("plan_days").innerHTML = plan_array['plan']['plan_days']+' Days';



function parseDate(str) {
    var mdy = str.split('/');
    return new Date(mdy[2], mdy[0]-1, mdy[1]);
}
function datediff(first, second) {
    return Math.round((second-first)/(1000*60*60*24));
}
var date1, date2;
date1 = new Date();
date2 = new Date(renewalDate);
var res = Math.abs(date1 - date2) / 1000;
var days = Math.floor(res / 86400);
if(days>31){
	days=31;
}
document.getElementById("used_days").innerHTML = days+' Days';

}

const $ = window.$;  
const google = window.google;	


google.charts.load("current", {packages:["corechart"]});
      
     google.charts.setOnLoadCallback(drawChart2);
     function drawChart2() {	  
        var data2 = google.visualization.arrayToDataTable([
          ['Current', 'Cycle'],
          ['Usage',  60],
          ['Balance', 40]
        ]);
        var options = {
          pieHole: 0.8,
		  legend: 'none',
          'chartArea': {'width': '90%', 'height': '90%'},
		  backgroundColor: 'transparent',
        };
        var chart = new google.visualization.PieChart(document.getElementById('chart_days'));
        chart.draw(data2, options);
      }
}
constructor(props) {
        super(props);
	    if(!localStorage.getItem('userData')) {
		   this.props.history.push("login");
        }	
    }
render (){
return (
<div>
   <section className="authentication-page section-b-space margin-pd">
      <div className="_3lldG">
         <div className="_2u0oK _1iY8Y">
            <form>
               <div className="_3TzfO page_head">
                  <div className="_2QN4q">
                     <Link to={`${process.env.PUBLIC_URL}/classic_broadband`}>
                     <img src={`${process.env.PUBLIC_URL}/assets/images/icon/return_white.png`} alt="" /></Link>
                  </div>
                  <div className="_1leRb page_title">
                     Account Info
                  </div>
                  <div className="b5NJg"></div>
               </div>
               <input type="submit" hidden=""/>
            </form>
         </div>
         <div className="_3mMtt account_info">
           <div class="container">
                
                <h4 class="text-center sub-title">Status and other details about your account</h4>
                
                <div class="row">
					<div class="col-12 col-sm-12 col-md-12 col-lg-12">
						<div class="card rounded-0 border-0">
							<div class="card-body p-3 sub-title">
								<div class="icon">
										<img src={`${process.env.PUBLIC_URL}/assets/images/icon/user.png`} alt="" />
									</div>
									<div class="media-body">
										<p class="mb-0 bold" id="name"></p>
									</div>
							</div>
						</div>
					</div>
					<div class="col-6 col-sm-6 col-md-6 col-lg-6">
						<div class="card rounded-0 border-0">
							<div class="card-body p-3">
								<div class="media">
									<div class="media-body">
										<p class="mb-0 grey">Account Status</p>
										<div class="status bold" id="account_status"></div>
									</div>
								</div>
							</div>
						</div>
					</div>
					
					<div class="col-6 col-sm-6 col-md-6 col-lg-6">
						<div class="card rounded-0 border-0">
							<div class="card-body p-3">
								<div class="media">
									<div class="media-body">
										<p class="mb-0 grey">Login Status</p>
										<div class="status-red bold" id="login_status"></div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
                
			</div>
			
			<div class="container-fluid position-relative bg-purple">
				<div class="row">
					<div class="container">
						<div class="row page-title-row">
							<div class="col-12">
								<p class="text-blue account-usage">Account Usage In Current Cycle</p>
							</div>							
							<div class="col-12 col-sm-12 col-md-12 col-lg-12">
								<div id="chart_days"></div>
                                <div class="chart_days_label">
								<b class="chart-red" id="used_days"></b><br/>
								 Out of<br/>
								 <span id="plan_days"></span>
								</div>
							</div>
							
							
						</div>

					</div>
				</div>
			</div>
			
			<div class="container speed">                               
                <div class="row">					
					<div class="col-6">
						<div class="card rounded-0 border-0">
							<div class="card-body p-3">
								<div class="media">
									<div class="media-body">
										<p class="mb-0 grey"><i class="fa fa-download" aria-hidden="true"></i> <span id="download_speed"></span><br/> <span>Download Speed</span></p>
									</div>
								</div>
							</div>
						</div>
					</div>	
				    <div class="col-6">
						<div class="card rounded-0 border-0">
							<div class="card-body p-3">
								<div class="media">
									<div class="media-body">
										<p class="mb-0 grey"><i class="fa fa-upload" aria-hidden="true"></i> <span id="upload_speed"></span><br/> <span>Upload Speed</span></p>
									</div>
								</div>
							</div>
						</div>
					</div>	
					
				</div>
                
			</div>
			<div class="container-fluid position-relative bg-purple account_details">
				<div class="row">
					<div class="container">
						<div class="row page-title-row">
							<div class="col-12">
								<p class="text-blue account-usage">Account Details</p>
							</div>
							<table class="table ">
    <tbody>
      <tr>
        <td>Expiry Date</td>
        <td class="right" id="expiry_date"></td>
      </tr>
      <tr>
        <td>Last Renew Date</td>
        <td class="right" id="renewal_date"></td>
      </tr>
      <tr>
        <td>Plan Name</td>
        <td class="right" id="package"></td>
      </tr>
      <tr>
        <td>Creation Date</td>
        <td class="right" id="created_on"></td>
      </tr>
      <tr>
        <td>Disable Time</td>
        <td class="right" id="disable_time"></td>
      </tr>
    </tbody>
  </table>
							
						</div>
					</div>
				</div>
			</div>
			
         </div>
      </div>
   </section>
</div>
)
}
}
export default AccountInfo

