import React, { Component } from 'react';
import { Link, NavLink} from 'react-router-dom';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from 'react-loader-spinner';
import {getListData} from '../../../services/index';
import '../../common/restaurant.scss';
import {connect} from 'react-redux';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

class RestaurantDetails extends Component {
constructor(props) {
	super(props);
	this.state = {
        openCart: '',
        countItem: 0,
        countTotal: 0,
		ListData: [],
		qty: 0,
		openQuantity: false,
        quantity: 1,
        image: '',
        name: '',
		
	};
	this.goBack = this.goBack.bind(this);
	if (!localStorage.getItem('userData')) {
		this.props.history.push("login");
	}
}
componentDidMount() {
	document.getElementById("color").setAttribute("href", `#`);
	document.querySelector(".loader-wrapper").style = "display: block";
	setTimeout(function() {
    document.querySelector(".loader-wrapper").style = "display: none";
	}, 900);
	
	let c_userid = this.props.match.params.id;
		this.setState({
					c_userid: c_userid,
				})
	this.checkData();
}


checkData() {
	
	var storedArray = JSON.parse(localStorage.getItem("userData"));
	this.state.mobile=storedArray['data']['mobile'];
	var mobile = storedArray['data']['mobile'];
	var app = {
		c_userid : this.props.match.params.id
	}
	getListData('society/SocietyController/notice_list', app).then((result) => {
		let responseJson = result;
		if (responseJson.data) {
			if (result['status'] == 200) {
				this.setState({
					ListData: result['data'],
				})
			}
		}
	});
	
}
   

   
	
	goBack(){
		this.props.history.goBack();
	}
	
	

	
	
	
	
	
	
	
	

render() {


var lenght = this.state.ListData.length;

return (
<div >
   
   <div>
   <div className="_2u0oK _1iY8Y border-blue">
            <form>
               <div className="_3TzfO page_head">
                  <div className="_2QN4q" onClick={this.goBack}>
                    
                     <img src={`${process.env.PUBLIC_URL}/assets/images/icon/return_white.png`} alt="" />
                  </div>
                  <div className="_1leRb page_title">
                     Notice Board
                  </div>
                  <div className="b5NJg"></div>
               </div>
               <input type="submit" hidden=""/>
            </form>
         </div>
 <section className="pb-0 margin-pd no-pad restro mt-60 society-det">
	<div class="row marginlr mt-20" style={{background: "#f5f5f9"}}>	
		
		
								{this.state.ListData.map((list) => (	
									<div class="col-12 col-lg-12 col-xl-12 pr-2 mt-10">
										<div class="card gradient-primary">
											<Link to={`${process.env.PUBLIC_URL}/notice-details/${list.notice_id}`}>
												<div class="card-body">
													<div class="media">
														<div class="media-body">
															<div class="col-md-12 no-padd">
																
																
																<p class="mb-0 text-left soc-mt-0 font-s-16 font-w-550 mh-40 lh-20">{list.notice_title}</p>
																<p class="mt-5 mb-0 pt-0 text-left font-w-550"><span class="font-s-14">Date : {list.created_date}</span> <span class="float-right"><img src={`${process.env.PUBLIC_URL}/assets/images/icon/black-right-arrow.png`} alt="" class="arrow" /></span></p>
															</div>
															
														</div>
													</div>
												</div>
											</Link>
										</div>
									</div>
									))}
						
						
								{(() => {  
								if (lenght =='0') {
								return (
									<div class="col-12 col-lg-12 col-xl-12 mt-60 center">
										<img src={`${process.env.PUBLIC_URL}/assets/images/icon/noticeboard.png`} class="no-found-img" />
										<h4>No notice found !!</h4>
									</div>
										)
									}
								})()}	
		
		
		
	</div>
	
 </section>
 
 <div className={`float-cart show-cart society-cart`} id="float-cart">
	
	
</div>




</div>




</div>
)
}
}
	
export default RestaurantDetails;