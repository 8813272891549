import React, { Component } from 'react';
import { Link, NavLink} from 'react-router-dom';
import '../../common/restaurant.scss';
import {getListData} from '../../../services/index';
import $ from 'jquery';
import {Redirect} from 'react-router-dom';
import { Item} from "../../layouts/home/components";
import Carousel from "../../layouts/home/Carousel";
import Slider from 'react-slick';
  

class ClassicProfile extends Component {
	constructor(){
		var storedArray = JSON.parse(localStorage.getItem("userData"));
		super();
		this.state = {
			name: '',
			mobile: '',
			user_id: storedArray['data']['user_id'],
			
		};
		this.addadress = this.addadress.bind(this);
		this.onChange = this.onChange.bind(this);
		
		if(!localStorage.getItem('userData')) {
		   this.props.history.push("login");
        }	
		
	}
	
	onChange(e){
		this.setState({[e.target.name]:e.target.value});
	}
	
	addadress() {
		if(this.state.name || this.state.mobile){
			
			getListData('auth/travels_enquiry',this.state).then((result) => {
			if(result.status == 300){
				document.getElementById('alert').innerHTML='<div class="alert alert-warning" role="alert"><i class="fa fa-info-circle" aria-hidden="true"></i> Please insert all fields</div>';
			}
			else{
				document.getElementById('alert').innerHTML='';
				this.setState({
				redirectToReferrer: true
				});
			}
			
			});
		}
		if(this.state.name === '' && this.state.mobile === ''){
			document.getElementById('alert').innerHTML='<div class="alert alert-warning" role="alert"><i class="fa fa-info-circle" aria-hidden="true"></i> Please insert all fields </div>';
		}
		else{
			document.getElementById('alert').innerHTML='';
		}
	}
	componentDidMount() {
        document.getElementById("color").setAttribute("href", `#` );		
		document.querySelector(".loader-wrapper").style = "display: block";
		setTimeout(function() {
			document.querySelector(".loader-wrapper").style = "display: none";
		}, 900);
		
		

    }
	
	logout(){
	localStorage.setItem("userData",'');
	localStorage.clear();
	this.props.history.push("login");
	}

	render() {
	if (this.state.redirectToReferrer){
			return (<Redirect to={`${process.env.PUBLIC_URL}/physio_thanks`}/>)
		}
		return (
			<div>

                <section className="pb-0 margin-pd mt-45 restro">
					<div className="_2u0oK _1iY8Y">
						<form>
						   <div className="_3TzfO page_head">
							  <div className="_2QN4q">
								 <Link to={`${process.env.PUBLIC_URL}/`}>
								 <img src={`${process.env.PUBLIC_URL}/assets/images/icon/return_white.png`} alt="" /></Link>
							  </div>
							  <div className="_1leRb page_title">
								Ask The Experts Team
							  </div>
							  <div className="b5NJg"></div>
						   </div>
						   <input type="submit" hidden=""/>
						</form>
					 </div>
				    
                </section>
				
				 {/*collection banner*/}
                


				<section className="">
					<img src={`${process.env.PUBLIC_URL}/assets/images/expert1.jpg`} alt="" class="img-fluid blur-up lazyload bg-img" /><br/><br/>
					<img src={`${process.env.PUBLIC_URL}/assets/images/expert2.jpg`} alt="" class="img-fluid blur-up lazyload bg-img" /><br/><br/>
					<img src={`${process.env.PUBLIC_URL}/assets/images/expert3.jpg`} alt="" class="img-fluid blur-up lazyload bg-img" />
                </section>
				
			</div>
			
			)

	}
}

export default ClassicProfile;
