import React, { Component } from 'react';
import { Link, NavLink} from 'react-router-dom';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from 'react-loader-spinner';
import {getListData} from '../../../services/index';
import '../../common/restaurant.scss';
import {connect} from 'react-redux';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css


class RestaurantDetails extends Component {
constructor(props) {
	super(props);
	this.state = {
        openCart: '',
        countItem: 0,
        countTotal: 0,
		ListData: [],
		qty: 0,
		openQuantity: false,
        quantity: 1,
        image: '',
		
		
		
			
			
	};
	if (!localStorage.getItem('userData')) {
		this.props.history.push("login");
	}
}
componentDidMount() {
	document.getElementById("color").setAttribute("href", `#`);
	document.querySelector(".loader-wrapper").style = "display: block";
	setTimeout(function() {
    document.querySelector(".loader-wrapper").style = "display: none";
}, 900);
this.getData();
}

minusQty = (id) => {
        if(this.state.quantity > 1) {
            this.setState({quantity: this.state.quantity - 1});
			this.minus_cart(id);
        }else{
            console.log('removefromcart');			
            this.setState({openQuantity:false});		
			this.minus_cart(id);
        }
    }

    plusQty = (id) => {
        this.setState({quantity: this.state.quantity+1});
		this.plus_cart(id);
    }

    openQuantity = (id) => {
        this.setState({openQuantity:true});
		this.add_cart(id);
    }

getData = () => {
		var storedArray = JSON.parse(localStorage.getItem("userData"));
		this.state.user_id=storedArray['data']['user_id'];
		getListData('labs/all_test', this.state).then((result) => {
		let responseJson = result;
		if (responseJson.data) {
			if (result['count'] > 0) {
				this.setState({
					ListData: result['data']
				})
			}
		}
		});
    }
	
add_cart(id) {
		var storedArray = JSON.parse(localStorage.getItem("userData"));
        var item_id = document.getElementById("item_id"+id).value;
        var item_price = document.getElementById("item_price"+id).value;
        var item_qty = document.getElementById("item_qty"+id).value;
		var app = {
			user_id: storedArray['data']['user_id'],
			item_id: item_id,
			item_qty: item_qty,
			item_price: item_price
		}
		getListData('labs/add_cart_test', app).then((result) => {
			this.getData();
			if (result.data.length>0) {
				for(var i=0;i<result.data.length;i++)
				{
					document.getElementById("float-cart").style = 'display: block';
					if(result.data[i].count>0)
					{
						document.getElementById("float-cart").style = 'display: block';
					}
					else
					{
						document.getElementById("float-cart").style = 'display: none';
					}
					this.state.countItem=result.data[i].count;
					this.state.countTotal=result.data[i].total;
					document.getElementById("count_item").innerHTML = result.data[i].count+' Items';
					document.getElementById("count_total").innerHTML = 'Rs. '+result.data[i].total;
				}				
				document.getElementById("chk_"+item_id).classList.add("open");
			}
			else{
				document.getElementById("count_item").innerHTML = '0 Items';
				document.getElementById("count_total").innerHTML = 'Rs. 0';
				document.getElementById("float-cart").style = 'display: none';
			}
		});
		this.getData();
	}

minus_cart(id) {
		var storedArray = JSON.parse(localStorage.getItem("userData"));
			
        var item_id = document.getElementById("item_id"+id).value;
        var item_price = document.getElementById("item_price"+id).value;
        var item_qty = document.getElementById("item_qty"+id).value;
		var app = {
			user_id: storedArray['data']['user_id'],
			item_id: item_id,
			item_qty: parseInt(item_qty)-1,
			item_price: item_price
		}
		getListData('labs/cart_update_test', app).then((result) => {
			this.getData();
			if (result.data.length>0) {
				for(var i=0;i<result.data.length;i++)
				{
					document.getElementById("float-cart").style = 'display: block';
					if(result.data[i].count>0)
					{
						document.getElementById("float-cart").style = 'display: block';
					}
					else
					{
						document.getElementById("float-cart").style = 'display: none';
					}
					this.state.countItem=result.data[i].count;
					this.state.countTotal=result.data[i].total;
					document.getElementById("count_item").innerHTML = result.data[i].count+' Items';
					document.getElementById("count_total").innerHTML = 'Rs. '+result.data[i].total;
				}
			}
			else{
				document.getElementById("count_item").innerHTML = '0 Items';
				document.getElementById("count_total").innerHTML = 'Rs. 0';
				document.getElementById("float-cart").style = 'display: none';
			}
		});
		this.getData();
	}	

checkQuantity = (test_name,test_desc,test_price) => {
       confirmAlert({
		customUI: ({ onClose }) => {
    return (
      <div className='react-confirm-alert-body'>
        <h1><b>{test_name}</b> <b className="fl-right"><i class="fa fa-inr" aria-hidden="true"></i>{test_price}</b></h1>
        <p class="color-black"> <div
			  dangerouslySetInnerHTML={{
				__html: test_desc
			  }}></div>{}</p>
		
		<div className="react-confirm-alert-button-group">
        <button onClick={onClose}>Ok</button>
		</div>
      </div>
    );
  }
    });
	}
	

render() {

return (
<div>

{this.state.ListData.map((list) => (
   <div>
   <section className="pb-0 margin-pd mt-45 restro">
      <div className="_2u0oK _1iY8Y">
         <div className="_3TzfO page_head">
            <div className="_2QN4q">
               <Link to={`${process.env.PUBLIC_URL}/lab_test`}>
               <img src={`${process.env.PUBLIC_URL}/assets/images/icon/return_white.png`} alt="" /></Link>
            </div>
            <div className="_1leRb page_title res-title">
               Neulife Healthcare Center
            </div>
            <div className="b5NJg"></div>
         </div>
      </div>
     

</section>

<section className="pb-0 margin-pd restro details-pad-top-n cart-bottom">

   <div className="res-details-div details-padding" role="button" tabIndex="0">
      <div className="res-details-info">
	  
	  
		{list.data.map(data => 
	     <div>
			<div class="product-box">
			 <div class="img-wrapper">
				<div class="details-dish-con addtocart_btn border-bottom">
				   <div class="details-dish-title details-color max-width">
				   <img className="order-img" src={`${process.env.PUBLIC_URL}/assets/images/icon/all_test.png`} alt="" />
				   {data.name}</div>
				   <div class="details-dish-title details-dish-price"><i class="fa fa-inr" aria-hidden="true"></i>{data.price}</div>
				   
				   <div>	
				   <span class="details-span" onClick={this.checkQuantity.bind(this,data.name,data.test_requirement,data.price)}> View Details</span>
						<div>
										  {(() => {
											if (list.status =='1') {
											return (
												 list.is_cart>0?
													  <div>
													  <button className="details-add-btn add_cart"  onClick={this.openQuantity.bind(this,data.id)}> Add</button>
													  </div>
													  :
													  <div>
													  <button className="details-add-btn add_cart"  onClick={this.openQuantity.bind(this,data.id)}> Add</button>
													  </div>
													  
												)
											}
										})()}
										<div id={`chk_${data.id}`} className={`qty-box cart_qty b-15 ${data.item_is_flag>0?'open':''} `}>
											<input type="hidden" id={`item_id${data.id}`} value={data.id}/>
											  <input type="hidden" id={`item_price${data.id}`} value={data.price}/>
											  <input type="hidden" id={`item_qty${data.id}`} value={data.item_qty} readOnly={true} className="form-control input-number" />
											   <span className="details-add-btn add_cart t-15" onClick={this.minusQty.bind(this,data.id)} > Remove</span>
											
										</div>
							    </div>
					</div>
				   <div>
						
					</div>	
					
				</div>
			 </div>
		  </div>
	    </div>
		)}
		
   
   
   
	
      </div>
   </div>
</section>



<div className={`float-cart ${list.total_flag>0?'show-cart':''}`} id="float-cart">
<Link className="text-white" to={`${process.env.PUBLIC_URL}/`}>
<span id="count_item" className="count_item">{list.cart_count} items</span>
<span>&nbsp;|&nbsp;</span>
<span id="count_total" className="count_total">Rs. {list.cart_total}</span>
<span class="pull-right">View Cart <i class="si si-basket"></i></span>
<canvas height="0" width="0" class="canvas"></canvas></Link>
</div>




</div>

))}


</div>
)
}
}
	
export default RestaurantDetails;