import React, {Component} from 'react';
import {Redirect} from 'react-router-dom';
import { Link, NavLink} from 'react-router-dom';
import '../common/login.scss';
import {LoginAuth} from '../../services/index';
import $ from 'jquery';

class Login extends Component {
	
    componentDidMount() {
		document.querySelector(".loader-wrapper").style = "display: none";
		
	$('.float-label .form-control[placeholder]').each(function () {
		if ($(this).attr('placeholder').length > 0) { $(this).addClass('active');}
	});
	$('.float-label .form-control[value]').each(function () {
		if ($(this).val.length > 0) { $(this).addClass('active');}
	});
	$('.float-label .form-control').on('blur', function () {
		if ($(this).val().length > 0) { $(this).addClass('active');} else {
			if ($(this)[0].hasAttribute('placeholder') === true) {
				if ($(this).attr('placeholder').length > 0) { $(this).addClass('active');} 
				else { $(this).removeClass('active');}
			} else { $(this).removeClass('active');}
		}
	});
	}
	
    constructor(){
		super();
		this.state = {
			username: '',
			password: '',
			token: '',
			redirectToReferrer: false
		};
		this.login = this.login.bind(this);
		this.onChange = this.onChange.bind(this);
	}

	login() {
		if((this.state.username && this.state.password)){
			LoginAuth('auth/login',this.state).then((result) => {
			if(result.status==400){
				document.getElementById('alert').innerHTML='<div class="alert alert-warning" role="alert"><i class="fa fa-info-circle" aria-hidden="true"></i> Invalid Username or Password</div>';
			}
			else{
				document.getElementById('alert').innerHTML='';
			}
			let responseJson = result;
				if(responseJson.data){					
					localStorage.setItem('userData',JSON.stringify(responseJson));
					this.setState({redirectToReferrer: true});
				}
			});
		}
		if(this.state.username=='' && this.state.password==''){
			document.getElementById('alert').innerHTML='<div class="alert alert-warning" role="alert"><i class="fa fa-info-circle" aria-hidden="true"></i> Please enter username & password</div>';
		}
		else{
			document.getElementById('alert').innerHTML='';
		}
	}

	onChange(e){
		this.setState({[e.target.name]:e.target.value});
	}

    render (){
		
		if (this.state.redirectToReferrer || localStorage.getItem('userData')){
			return (<Redirect to={'/'}/>)
		}
 
        return (
            <div>              
                <div className="limiter">
					<div className="container-login100">
						<div className="wrap-login100">
							<div className="login-fm">
								<form className="login100-form validate-form">
									<span className="login100-form-logo"></span>
									<span className="login100-form-title p-t-27">
										Log in
										<p>Enter Your Internet Login Details</p>
									</span>
									<div id="alert" className="m-b-10"></div>								
									<div className="form-group float-label validate-input" data-validate="Enter username">
										<input type="text" className="form-control" name="username" autoComplete="off" onChange={this.onChange}/>
										<label><i className="fa fa-user" aria-hidden="true"></i> Username</label>
									</div>
									<div className="form-group float-label" data-validate="Enter password">
										<input type="password" className="form-control"  name="password" autoComplete="off" onChange={this.onChange} />
										<label><i className="fa fa-lock" aria-hidden="true"></i> Password</label>
									</div>	
									<div className="container-login100-form-btn">			
										<input type="hidden" name="token" id="token-input" value='null' onChange={this.onChange}/>
										<input type="button"  className="login100-form-btn" value="Login" onClick={this.login}/>
									</div>								
								</form>
							</div>	
							<div class="width hidden">
								<Link to={`${process.env.PUBLIC_URL}/register`}>
									<button class="register-btn">Not a Classicnet User ?</button>
								</Link>
							</div>
						</div>
					</div>
				</div>
            </div>
        )
    }
}

export default Login


