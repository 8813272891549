import React, { Component } from 'react';
import { Link, NavLink} from 'react-router-dom';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from 'react-loader-spinner';
import {getListData} from '../../../services/index';
import '../../common/restaurant.scss';
import {connect} from 'react-redux';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

class RestaurantDetails extends Component {
constructor(props) {
	super(props);
	this.state = {
        openCart: '',
        countItem: 0,
        countTotal: 0,
		currentData: [],
		qty: 0,
		openQuantity: false,
        quantity: 1,
        image: '',
        name: '',
		
	};
	this.goBack = this.goBack.bind(this);
	if (!localStorage.getItem('userData')) {
		this.props.history.push("login");
	}
}
componentDidMount() {
	document.getElementById("color").setAttribute("href", `#`);
	document.querySelector(".loader-wrapper").style = "display: block";
	setTimeout(function() {
    document.querySelector(".loader-wrapper").style = "display: none";
	}, 900);
	
	let id =this.props.match.params.id;
	this.setState({
					id: id,
				})
	
	var storedArray = JSON.parse(localStorage.getItem("userData"));
	this.state.mobile=storedArray['data']['mobile'];
	this.checkData();
	
	
}


checkData() {
	
	var storedArray = JSON.parse(localStorage.getItem("userData"));
	this.state.mobile=storedArray['data']['mobile'];
	var mobile = storedArray['data']['mobile'];
	var app = {
		id : this.props.match.params.id,
		unit_id : this.props.match.params.unit_id
	}
	getListData('society/SocietyController/payment_history', app).then((result) => {
		let responseJson = result;
		if (responseJson.data) {
			if (result['status'] == 200) {
				this.setState({
					currentData: result['data']
				})
			}
		}
	});
}
   

   
	
	
	
	goBack=()=>{
		this.props.history.goBack();
	}
	
	

render() {


return (
<div >
   
   <div>
   <div className="_2u0oK _1iY8Y border-blue">
            <form>
               <div className="_3TzfO page_head">
                  <div className="_2QN4q" onClick={this.goBack}>
                     <img src={`${process.env.PUBLIC_URL}/assets/images/icon/return_white.png`} alt="" />
                  </div>
                  <div className="_1leRb page_title">
                     Payment History
                  </div>
                  <div className="b5NJg"></div>
               </div>
               <input type="submit" hidden=""/>
            </form>
         </div>
 <section className="pb-0 margin-pd no-pad restro mt-60 society-det">
	<div class="row marginlr mt-20" style={{background: "#f5f5f9",paddingLeft:'10px',paddingRight:'6px'}}>	
		
		{(() => {
			if (this.state.currentData.length>0) {
				return (
					<>
						{this.state.currentData.map((current) => (
										<div class="card gradient-primary" style={{width: '100%',marginBottom:'10px'}}>
										    <Link to={`${process.env.PUBLIC_URL}/bill_details/${this.state.id}/${current.bill_id}`}>
											  <div class="card-body">
												 <div class="bill_tab">
													<div class="media-body">
													   <div class="col-md-9 no-padd">
														  <p class="mb-0 text-left soc-mt-0 font-s-16 font-w-550">{current.current_month}</p>
														  
														 
							{(() => {
								if (current.is_paid>0) {
									return (
										<>
											 <p class="mb-0 text-left" style={{color: '#048404 !important'}}>Paid On: {current.paid_on}</p> 
										</>
									)
								}
								else{
									return (
										<>
											 <p class="mb-0 text-left">Due date: {current.due_date}</p>
										</>
									)
								}
							})()}
														  
													   </div>
													   <div class="col-md-3 no-padd">
													   <b style={{color: '#3e3e98'}}><i className="fa fa-inr" aria-hidden="true"></i> {current.total}/- </b><br/>
														<Link to={`${process.env.PUBLIC_URL}/bill_details/${this.state.id}/${current.bill_id}`} style={{marginTop: '0px'}} class="chat_order_btn"> 
															View 
														</Link>
														</div>
													</div>
												 </div>
											  </div>
										   </Link>
										</div>
									))}
					</>
				)
			}
			else{
				return (
					<>
						 <div class="col-12 col-lg-12 col-xl-12 mt-60 center">
		<img src={`${process.env.PUBLIC_URL}/assets/images/icon/payment-method.png`} class="no-found-img" />
		<h4>No payment history found !!</h4>
	</div>
					</>
				)
			}
		})()}
		
		
		
		
		
		
	
						
						
		
		
		
	</div>
	
 </section>
 
 <div className={`float-cart show-cart society-cart`} id="float-cart">
	
	
</div>




</div>




</div>
)
}
}
	
export default RestaurantDetails;