import React, { Component } from 'react';
import { Link, NavLink} from 'react-router-dom';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from 'react-loader-spinner';
import {getListData} from '../../../services/index';
import '../../common/restaurant.scss';
import {connect} from 'react-redux';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

class RestaurantDetails extends Component {
constructor(props) {
	super(props);
	this.state = {
        openCart: '',
        countItem: 0,
        countTotal: 0,
		ListData: [],
		qty: 0,
		openQuantity: false,
        quantity: 1,
        image: ''
	};
	this.add_cart = this.add_cart.bind(this);
	this.plus_cart = this.plus_cart.bind(this);
	this.minus_cart = this.minus_cart.bind(this);
	if (!localStorage.getItem('userData')) {
		this.props.history.push("login");
	}
}
componentDidMount() {
	document.getElementById("color").setAttribute("href", `#`);
	document.querySelector(".loader-wrapper").style = "display: block";
	setTimeout(function() {
    document.querySelector(".loader-wrapper").style = "display: none";
}, 900);
	var acc = document.getElementsByClassName("accordion");
	var i;
	var storedArray = JSON.parse(localStorage.getItem("userData"));
	let restaurant_id = this.props.match.params.id;
	this.state.restaurant_id = restaurant_id;
	this.state.user_id=storedArray['data']['user_id'];
	var user_id = storedArray['data']['user_id'];
	window.showAndroidToast(user_id);
	getListData('restaurant/restaurant_details', this.state).then((result) => {
		let responseJson = result;
		if (responseJson.data) {
			if (result['count'] > 0) {
				this.setState({
					ListData: result['data']
				})
			}
		}
	});
	this.getData();
	//setInterval(this.getData, 5000);
}

    getData = () => {
		var storedArray = JSON.parse(localStorage.getItem("userData"));
		let restaurant_id = this.props.match.params.id;
        this.state.restaurant_id = restaurant_id;
		this.state.user_id=storedArray['data']['user_id'];
		getListData('restaurant/restaurant_details', this.state).then((result) => {
		let responseJson = result;
		if (responseJson.data) {
			if (result['count'] > 0) {
				this.setState({
					ListData: result['data']
				})
			}
		}
		});
    }

    minusQty = (id) => {
        if(this.state.quantity > 1) {
            this.setState({quantity: this.state.quantity - 1});
			this.minus_cart(id);
        }else{
            console.log('removefromcart');			
            this.setState({openQuantity:false});		
			this.minus_cart(id);
        }
    }

    plusQty = (id) => {
        this.setState({quantity: this.state.quantity+1});
		this.plus_cart(id);
    }

    openQuantity = (id) => {
        this.setState({openQuantity:true});
		this.add_cart(id);
    }
	
	checkQuantity = (id,restaurant_name,old_restaurant_name) => {
       confirmAlert({
		title: 'Replace cart item?',
		message: 'Your cart contains dishes from '+old_restaurant_name+'. Do you want to discard the selection and add dishes from '+restaurant_name+'?',
		buttons: [
        {
			label: 'Yes',
			onClick: () => {		
				var storedArray = JSON.parse(localStorage.getItem("userData"));
				this.state.user_id = storedArray['data']['user_id'];
				var app = {
					user_id: storedArray['data']['user_id']
				}
				getListData('restaurant/cart_clear', app).then((result) => {
					this.openQuantity(id);
				});
			}
        },
        {
          label: 'No',
          onClick: () => {}
        }
      ]
    });
	}
	

	checkDelivery = (id,restaurant_name,old_restaurant_name) => {
		confirmAlert({
		 title: 'Oops!!! Delivery Closed',
		 message: 'The restaurant delivery is closed..',
		 buttons: [
		 {
			 label: 'Done',
			 onClick: () => {}
		 }
	   ]
	 });
	 }
	
	minus_cart(id) {
		var storedArray = JSON.parse(localStorage.getItem("userData"));
		let restaurant_id = localStorage.getItem("restaurant_id");	
		this.state.restaurant_id = restaurant_id;		
        var item_id = document.getElementById("item_id"+id).value;
        var item_price = document.getElementById("item_price"+id).value;
        var item_qty = document.getElementById("item_qty"+id).value;
		var app = {
			restaurant_id: restaurant_id,
			user_id: storedArray['data']['user_id'],
			item_id: item_id,
			item_qty: parseInt(item_qty)-1,
			item_price: item_price
		}
		getListData('restaurant/cart_update', app).then((result) => {
			this.getData();
			if (result.data.length>0) {
				for(var i=0;i<result.data.length;i++)
				{
					document.getElementById("float-cart").style = 'display: block';
					if(result.data[i].count>0)
					{
						document.getElementById("float-cart").style = 'display: block';
					}
					else
					{
						document.getElementById("float-cart").style = 'display: none';
					}
					this.state.countItem=result.data[i].count;
					this.state.countTotal=result.data[i].total;
					document.getElementById("count_item").innerHTML = result.data[i].count+' Items';
					document.getElementById("count_total").innerHTML = 'Rs. '+result.data[i].total;
				}
			}
			else{
				document.getElementById("count_item").innerHTML = '0 Items';
				document.getElementById("count_total").innerHTML = 'Rs. 0';
				document.getElementById("float-cart").style = 'display: none';
			}
		});
		this.getData();
	}
	
	plus_cart(id) {
		var storedArray = JSON.parse(localStorage.getItem("userData"));
		let restaurant_id = localStorage.getItem("restaurant_id");	
		this.state.restaurant_id = restaurant_id;		
        var item_id = document.getElementById("item_id"+id).value;
        var item_price = document.getElementById("item_price"+id).value;
        var item_qty = document.getElementById("item_qty"+id).value;
		var app = {
			restaurant_id: restaurant_id,
			user_id: storedArray['data']['user_id'],
			item_id: item_id,
			item_qty: parseInt(item_qty)+1,
			item_price: item_price
		}
		getListData('restaurant/cart_update', app).then((result) => {
			this.getData();
			if (result.data.length>0) {
				for(var i=0;i<result.data.length;i++)
				{
					document.getElementById("float-cart").style = 'display: block';
					if(result.data[i].count>0)
					{
						document.getElementById("float-cart").style = 'display: block';
					}
					else
					{
						document.getElementById("float-cart").style = 'display: none';
					}
					this.state.countItem=result.data[i].count;
					this.state.countTotal=result.data[i].total;
					document.getElementById("count_item").innerHTML = result.data[i].count+' Items';
					document.getElementById("count_total").innerHTML = 'Rs. '+result.data[i].total;
				}
			}
			else{
				document.getElementById("count_item").innerHTML = '0 Items';
				document.getElementById("count_total").innerHTML = 'Rs. 0';
				document.getElementById("float-cart").style = 'display: none';
			}
		});
		this.getData();
	}
	
	add_cart(id) {
		var storedArray = JSON.parse(localStorage.getItem("userData"));
		let restaurant_id = localStorage.getItem("restaurant_id");	
		this.state.restaurant_id = restaurant_id;		
        var item_id = document.getElementById("item_id"+id).value;
        var item_price = document.getElementById("item_price"+id).value;
        var item_qty = document.getElementById("item_qty"+id).value;
		var app = {
			restaurant_id: restaurant_id,
			user_id: storedArray['data']['user_id'],
			item_id: item_id,
			item_qty: item_qty,
			item_price: item_price
		}
		getListData('restaurant/add_cart', app).then((result) => {
			this.getData();
			if (result.data.length>0) {
				for(var i=0;i<result.data.length;i++)
				{
					document.getElementById("float-cart").style = 'display: block';
					if(result.data[i].count>0)
					{
						document.getElementById("float-cart").style = 'display: block';
					}
					else
					{
						document.getElementById("float-cart").style = 'display: none';
					}
					this.state.countItem=result.data[i].count;
					this.state.countTotal=result.data[i].total;
					document.getElementById("count_item").innerHTML = result.data[i].count+' Items';
					document.getElementById("count_total").innerHTML = 'Rs. '+result.data[i].total;
				}				
				document.getElementById("chk_"+item_id).classList.add("open");
			}
			else{
				document.getElementById("count_item").innerHTML = '0 Items';
				document.getElementById("count_total").innerHTML = 'Rs. 0';
				document.getElementById("float-cart").style = 'display: none';
			}
		});
		this.getData();
	}
	

render() {
const {menu_list, type} = this.props;

localStorage.setItem("restaurant_id",'');
localStorage.setItem('restaurant_id',this.state.restaurant_id);

return (
<div>
   {this.state.ListData.map((list) => (
   <div>
   <section className="pb-0 margin-pd mt-45 restro">
      <div className="_2u0oK _1iY8Y">
         <div className="_3TzfO page_head">
            <div className="_2QN4q">
               <Link to={`${process.env.PUBLIC_URL}/order_food_online`}>
               <img src={`${process.env.PUBLIC_URL}/assets/images/icon/return_white.png`} alt="" /></Link>
            </div>
            <div className="_1leRb page_title res-title">
               {list.restaurant_name}
            </div>
            <div className="b5NJg"></div>
         </div>
      </div>
      <div className="res-bg" style={{backgroundImage: "url(http://classicxtra.com/images/download.png)"}}>
      <img src={`${list.image}`} alt=""/>
</div>
<div className="res-details-div res-round res-head" role="button" tabIndex="0">
<div className="res-details-info">
<div className="_14v1V center"><div className="nowrap details-title-col">{list.restaurant_name}</div></div>
<div className="_3oKxo center width ">{list.tag}</div>
<div className="details-border"></div>

{(() => {
	if (list.discount !='') {
	   return (
<div>	   
<div className="order_details col-md-4 center details-border details-pad-bot no-pad details-title-col"><i className="fa fa-motorcycle" aria-hidden="true"></i> {list.delivery_time}  <span className="block">Delivery Time</span></div>
<div className="order_details col-md-4 center details-border details-pad-bot no-pad details-title-col"><i className="fa fa-inr" aria-hidden="true"></i>{list.min_order} <span className="block">Minimum Order</span></div>
<div className="order_details col-md-4 center details-border details-pad-bot no-pad details-title-col">{list.discount}% Off<span className="block">Flat Discount</span></div>
</div>
			
		  )
	   }else if (list.discount == '0') {
	   return (
		<div>
		   <div className="order_details col-md-6 center details-border details-pad-bot no-pad details-title-col"><i className="fa fa-motorcycle" aria-hidden="true"></i> {list.delivery_time}  <span className="block">Delivery Time</span></div>
<div className="order_details col-md-6 center details-border details-pad-bot no-pad details-title-col"><i className="fa fa-inr" aria-hidden="true"></i>{list.min_order} <span className="block">Minimum Order</span></div>				
</div>
		  )
	   }else if (list.discount == '') {
	   return (
		<div>
		   <div className="order_details col-md-6 center details-border details-pad-bot no-pad details-title-col"><i className="fa fa-motorcycle" aria-hidden="true"></i> {list.delivery_time}  <span className="block">Delivery Time</span></div>
<div className="order_details col-md-6 center details-border details-pad-bot no-pad details-title-col"><i className="fa fa-inr" aria-hidden="true"></i>{list.min_order} <span className="block">Minimum Order</span></div>				
</div>
		  )
	   }      
	   
	   

	})()}	
</div>
</div>
</section>

{this.state.ListData.map((list) => (
<section className="pb-0 margin-pd restro details-pad-top-n cart-bottom">
{list.data.map(data => 
   <div className="res-details-div details-padding" role="button" tabIndex="0">
      <div className="res-details-info">
	  
	
            <ul class="collapse-list">
               <li>
                  <input class="collapse-open" type="checkbox" id="collapse-1" id={`collapse-${data.menu_id}`}/>
                  <label class="collapse-btn" for={`collapse-${data.menu_id}`}><span className="block details-color">{data.menu_name}</span>
                  <span className="block details-fl-left" >{data.total_items} items</span></label>
                  <div class="collapse-painel">
                     <div class="collapse-inner">
				
						{data.menu_list.map((product) =>
							<div>
                                <div className="product-box">
                <div className="img-wrapper">
				
                    <div className="details-dish-con addtocart_btn">                           
						   <div className="details-dish-title details-color max-width"><img className="order-img" src={`${process.env.PUBLIC_URL}/assets/images/restaurants/${product.item_type_image}`} alt="" />{product.name}</div>
						   
                           <div className="details-dish-title details-dish-price"><i className="fa fa-inr" aria-hidden="true"></i>{product.price}</div>
						  
						  
						   {(() => {
								if (list.delivery_status =='Open') {
								return (
									 list.is_cart>0?
										  <div>
										  <button className="details-add-btn add_cart"  onClick={this.checkQuantity.bind(this,product.id,list.restaurant_name,list.old_restaurant_name)}> Add</button>
										  </div>
										  :
										  <div>
										  <button className="details-add-btn add_cart"  onClick={this.openQuantity.bind(this,product.id)}> Add</button>
										  </div>
										  
									)
								}
								if (list.delivery_status =='Closed') {
								return (
										  <div>
										  <button className="details-add-btn add_cart"  onClick={this.checkDelivery.bind(this,product.id)}> Add</button>
										  </div>
									)
								}
								
							})()}


						  						   
						<div id={`chk_${product.id}`} className={`qty-box cart_qty ${product.item_is_flag>0?'open':''} `}>
                            <div className="input-group">
                              <span className="input-group-prepend">
                                <button type="button" className="btn quantity-left-minus" onClick={this.minusQty.bind(this,product.id)} data-type="minus" data-field="">
                                 <i className="fa fa-minus"></i>
                                </button>
                              </span>
							  <input type="hidden" id={`item_id${product.id}`} value={product.id}/>
							  <input type="hidden" id={`item_price${product.id}`} value={product.price}/>
                              <input type="number" id={`item_qty${product.id}`} value={product.item_qty} readOnly={true} className="form-control input-number" />
                                <span className="input-group-prepend">
                                <button type="button" className="btn quantity-right-plus" onClick={this.plusQty.bind(this,product.id)} data-type="plus" data-field="">
                                <i className="fa fa-plus"></i>
                                </button>
                               </span>
                            </div>
                        </div>
                    </div>
                  
                   
                </div>
            </div>
                            </div>	
						)}
                     </div>
                  </div>
               </li>
            </ul>
      </div>
   </div>
    )}
</section>
))}	


<div className={`float-cart ${list.total_flag>0?'show-cart':''}`} id="float-cart">
<Link className="text-white" to={`${process.env.PUBLIC_URL}/restaurant_order/${this.state.restaurant_id}`}>
<span id="count_item" className="count_item">{list.cart_count} items</span>
<span>&nbsp;|&nbsp;</span>
<span id="count_total" className="count_total">Rs. {list.cart_total}</span>
<span class="pull-right">View Cart <i class="si si-basket"></i></span>
<canvas height="0" width="0" class="canvas"></canvas></Link>

</div>

</div>

))}	


</div>
)
}
}
	
export default RestaurantDetails;