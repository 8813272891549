import React, { Component } from 'react';
import { Link, NavLink} from 'react-router-dom';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from 'react-loader-spinner';
import {getListData} from '../../../services/index';
import '../../common/restaurant.scss';
import {connect} from 'react-redux';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

class RestaurantDetails extends Component {
constructor(props) {
	super(props);
	this.state = {
        openCart: '',
        countItem: 0,
        countTotal: 0,
		ListData: [],
		qty: 0,
		openQuantity: false,
        quantity: 1,
        image: '',
        name: '',
		
	};
	
	if (!localStorage.getItem('userData')) {
		this.props.history.push("login");
	}
}
componentDidMount() {
	document.getElementById("color").setAttribute("href", `#`);
	document.querySelector(".loader-wrapper").style = "display: block";
	setTimeout(function() {
    document.querySelector(".loader-wrapper").style = "display: none";
	}, 900);
	
	
	let c_userid = this.props.match.params.id;
		this.setState({
					c_userid: c_userid,
				})
	this.checkData();
	
	
}


checkData() {
	
	var storedArray = JSON.parse(localStorage.getItem("userData"));
	this.state.mobile=storedArray['data']['mobile'];
	var mobile = storedArray['data']['mobile'];
	var app = {
		c_userid : this.props.match.params.id
	}
	getListData('society/SocietyController/parking_list', app).then((result) => {
		let responseJson = result;
		if (responseJson.data) {
			if (result['status'] == 200) {
				this.setState({
					ListData: result['data'],
				})
			}
		}
	});
	
	
}
   

   
	
	
	

	
	
	
	
	
	
	
	

render() {


var lenght = this.state.ListData.length;

return (
<div >
   
   <div>
   <div className="_2u0oK _1iY8Y border-blue">
            <form>
               <div className="_3TzfO page_head">
                  <div className="_2QN4q">
                     <Link to={`${process.env.PUBLIC_URL}/society_details/${this.state.c_userid}`}>
                     <img src={`${process.env.PUBLIC_URL}/assets/images/icon/return_white.png`} alt="" /></Link>
                  </div>
                  <div className="_1leRb page_title">
                     Parking Details
                  </div>
                  <div className="b5NJg"></div>
               </div>
               <input type="submit" hidden=""/>
            </form>
         </div>
 <section className="pb-0 margin-pd no-pad restro mt-60 society-det">
	<div class="" style={{background: "#f5f5f9"}}>	
					{this.state.ListData.map((list) => (	
						<div class="row marginlr">
						
						
						{(() => {  
								if (list.bike != 0) {
								return (
										<div class="col-6 col-lg-6 col-xl-6 pr-2 mt-5">
											<div class="card gradient-primary">
													<div class="card-body">
														<div class="media">
															<div class="media-body">
																<div class="dashboard-img">
																	<img src={`${process.env.PUBLIC_URL}/assets/images/icon/bike_parking.png`} alt="" />
																</div>
																<p class="mb-0 soc-mt-0 font-s-12 font-w-500">{list.bike_count} Bike</p>
																<p class="mb-0 font-w-550">Rs. {list.bike}/<span class="small">pm</span></p>
																<p class="mb-0 soc-mt-0 font-s-12 font-w-500">Bike Parking</p>
															</div>
														</div>
													</div>
											</div>
										</div>
										)
								}
							})()}
						
						
						
						{(() => {  
								if (list.car != 0) {
								return (
										<div class="col-6 col-lg-6 col-xl-6 pr-2 pl-0 mt-5">
											<div class="card gradient-primary">
													<div class="card-body">
														<div class="media">
															<div class="media-body">
																<div class="dashboard-img">
																	<img src={`${process.env.PUBLIC_URL}/assets/images/icon/car_parking.png`} alt="" />
																</div>
																<p class="mb-0 soc-mt-0 font-s-12 font-w-500">{list.car_count} Car</p>
																<p class="mb-0 font-w-550">Rs.{list.car}/<span class="small">pm</span></p>
																<p class="mb-0 soc-mt-0 font-s-12 font-w-500">Car Parking</p>
																
															</div>
														</div>
													</div>
											</div>
										</div>	
										)
								}
							})()}
						
						
						{(() => {  
								if (list.stilt != 0) {
								return (
										<div class="col-6 col-lg-6 col-xl-6 pr-2 mt-5">
											<div class="card gradient-primary">
													<div class="card-body">
														<div class="media">
															<div class="media-body">
																<div class="dashboard-img">
																	<img src={`${process.env.PUBLIC_URL}/assets/images/icon/parking.png`} alt="" />
																</div>
																<p class="mb-0 soc-mt-0 font-s-12 font-w-500">{list.stilt_count} Stilt</p>
																<p class="mb-0 font-w-550">Rs.{list.stilt}/<span class="small">pm</span> </p>
																<p class="mb-0 soc-mt-0 font-s-12 font-w-500">Stilt Parking</p>
															</div>
														</div>
													</div>
											</div>
										</div>
										)
									}
								})()}
						
						
						</div>
						))}
						
						{(() => {  
								if (lenght =='0') {
								return (
									<div class="col-12 col-lg-12 col-xl-12 mt-60 center">
										<img src={`${process.env.PUBLIC_URL}/assets/images/icon/parking.png`} class="no-found-img" />
										<h4>No parking found !!</h4>
									</div>
										)
									}
								})()}
		
						
		
		
		
	</div>
	
 </section>
 
 <div className={`float-cart show-cart society-cart`} id="float-cart">
	
	
</div>




</div>




</div>
)
}
}
	
export default RestaurantDetails;