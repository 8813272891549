import React, {Component} from 'react';
import { Link, NavLink} from 'react-router-dom';
import {getData} from '../../services/index';
import Slider from 'react-slick';

class Search extends Component {
componentDidMount() {
		setTimeout(function() {
            document.querySelector(".loader-wrapper").style = "display: none";
        }, 2000);
        getData('auth/add_banner_list', this.state).then((result) => {
			let responseJson = result;
			if (responseJson.data) {
				this.setState({
					ListData2: result['data']
				})
			}
		});
    }
    constructor (props) {
        super (props)
		this.state = {	
            ListData2: []	
		}    		
    }
	
	
    render (){
	   
        return (
            <div>
                <div className="_2u0oK _1iY8Y">
            <form>
               <div className="_3TzfO page_head">
                  <div className="_2QN4q">
                     <Link to={`${process.env.PUBLIC_URL}/`}>
                     <img src={`${process.env.PUBLIC_URL}/assets/images/icon/return_white.png`} alt="" /></Link>
                  </div>
                  <div className="_1leRb page_title">
                  Talk To Changez Multani
                  </div>
                  <div className="b5NJg"></div>
               </div>
               <input type="submit" hidden=""/>
            </form>
         </div>

         
                <section className="section-b-space margin-pd">
                            <div class="mt-50 mb-0 advertise mr-10">
									<Slider  className="slide-1 home-slider mt-10">
											{this.state.ListData2.map((banner) =>
												<div>
													<div className="home text-center" style={{backgroundImage: `url(${banner.image})`}}>
														<div className="container">
															<div className="row">
																<div className="col">
																	<div className="slider-contain">
																	
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											)} 
									</Slider>
								</div>
					<div className="coming-soon">
						<img src={`${process.env.PUBLIC_URL}/assets/images/coming-soon.png`} alt=""/>
					</div>
                </section>
            </div>
        )
    }
}

export default Search