import React, { Component } from 'react';
import { Link, NavLink} from 'react-router-dom';
import '../../common/restaurant.scss';
import {getListData} from '../../../services/index';
import $ from 'jquery';
import {Redirect} from 'react-router-dom';
import { Item} from "../../layouts/home/components";
import Carousel from "../../layouts/home/Carousel";
import Slider from 'react-slick';


class ClassicProfile extends Component {
	constructor(){
		var storedArray = JSON.parse(localStorage.getItem("userData"));
		super();
		this.state = {
			name: '',
			mobile: '',
			user_id: storedArray['data']['user_id'],
			
		};
		this.addadress = this.addadress.bind(this);
		this.onChange = this.onChange.bind(this);
		
		if(!localStorage.getItem('userData')) {
		   this.props.history.push("login");
        }	
		
	}
	
	onChange(e){
		this.setState({[e.target.name]:e.target.value});
	}
	
	addadress() {
		if(this.state.name || this.state.mobile){
			
			getListData('auth/jewellry_enquiry',this.state).then((result) => {
			if(result.status == 300){
				document.getElementById('alert').innerHTML='<div class="alert alert-warning" role="alert"><i class="fa fa-info-circle" aria-hidden="true"></i> Please insert all fields</div>';
			}
			else{
				document.getElementById('alert').innerHTML='';
				this.setState({redirectToReferrer: true});
			}
			
			});
		}
		if(this.state.name === '' && this.state.mobile === ''){
			document.getElementById('alert').innerHTML='<div class="alert alert-warning" role="alert"><i class="fa fa-info-circle" aria-hidden="true"></i> Please insert all fields </div>';
		}
		else{
			document.getElementById('alert').innerHTML='';
		}
	}
	componentDidMount() {
        document.getElementById("color").setAttribute("href", `#` );		
		document.querySelector(".loader-wrapper").style = "display: block";
		setTimeout(function() {
			document.querySelector(".loader-wrapper").style = "display: none";
		}, 900);
		
		if (localStorage.getItem('userData')){
			var storedArray = JSON.parse(localStorage.getItem("userData"));
			document.getElementById("name").value = storedArray['data']['name'];
			document.getElementById("mobile").value = storedArray['data']['mobile'];
			
			
			document.getElementById("name").classList.add("active");
			document.getElementById("mobile").classList.add("active");
			
			this.setState({
				name: storedArray['data']['name'] ,
				mobile : storedArray['data']['mobile']
				});
		}

    }
	
	logout(){
	localStorage.setItem("userData",'');
	localStorage.clear();
	this.props.history.push("login");
	}

	render() {
	if (this.state.redirectToReferrer){
			return (<Redirect to={`${process.env.PUBLIC_URL}/physio_thanks`}/>)
		}
		return (
			<div>

                <section className="pb-0 margin-pd mt-45 restro">
					<div className="_2u0oK _1iY8Y">
						<form>
						   <div className="_3TzfO page_head">
							  <div className="_2QN4q">
								 <Link to={`${process.env.PUBLIC_URL}/`}>
								 <img src={`${process.env.PUBLIC_URL}/assets/images/icon/return_white.png`} alt="" /></Link>
							  </div>
							  <div className="_1leRb page_title">
								Sajana Jewellers
							  </div>
							  <div className="b5NJg"></div>
						   </div>
						   <input type="submit" hidden=""/>
						</form>
					 </div>
				    
                </section>
				
				 {/*collection banner*/}
                <section className="pb-0 margin-pd restro details-pad-top-n">
				    
                    <div className="add-details gym-pad-bot classicxtra" role="button" tabIndex="0">
						<div className="res-details-info col-md-12">
							<div class="collection-banner p-left text-left">
								<img src={`${process.env.PUBLIC_URL}/assets/images/jewellers.jpg`} alt="" class="img-fluid blur-up lazyload bg-img" />
								<div class="absolute-contain">
									
									<h3>Sajana Jewellers</h3>
								</div>
							</div>
							
						</div>
					</div>
                </section>


				<section className="pb-0 margin-pd mt-10 restro details-pad-top-n">
					<div className="add-details details-padding" role="button" tabIndex="0">
						<div className="res-details-info col-md-12">
							<form className="login100-form validate-form mt-10">
											<div className="form-group-1 float-label-1 validate-input" data-validate="Enter user_name">
												<input type="text" className="form-control-1" name="name" id="name" autoComplete="off" onChange={this.onChange}/>
												<label className="color lab-top">Name</label>
											</div>
											<div className="form-group-1 float-label-1 validate-input" data-validate="Enter mobile">
												<input type="text" className="form-control-1" name="mobile" id="mobile" autoComplete="off" onChange={this.onChange}/>
												<label className="color lab-top"> Mobile No.</label>
											</div>
											
												
											<div id="alert" className="m-b-10"></div>	
											
											<div className="col-md-12 center mb-20">
													<input type="button"  className="login100-form-btn" value="Send Enquiry" onClick={this.addadress}/>
											</div>
										</form>
							
							
						</div>
					</div>
                </section>
				
			</div>
			
			)

	}
}

export default ClassicProfile;
