import React, { Component } from "react";
import { Link, NavLink } from "react-router-dom";
class Index extends Component {
	constructor(props) {
		super(props);
		this.state = {
			username:''
		};
	}
  
	componentDidMount() {
		document.querySelector(".loader-wrapper").style = "display: block";
		setTimeout(function () {
			document.querySelector(".loader-wrapper").style = "display: none";
		}, 900);
		if (localStorage.getItem('userData')) {
			var storedArray = JSON.parse(localStorage.getItem("userData"));
			var username = storedArray['data']['username'];
			this.setState({
				username: username
			})
		}
	}

	whatsapp_redirect=()=>{
		var username=this.state.username;
		window.open(
			"https://api.whatsapp.com/send?phone=912226780649&text=Username: "+username+", Submit Complaints",
			"_blank",
		);
	}

	payment_redirect=()=>{
		var username=this.state.username;
		window.open(
			"https://api.whatsapp.com/send?phone=912226780649&text=Username: "+username+", Need UPI or QR code to pay bill",
			"_blank",
		);
	}

	whatsapp_redirect_1=()=>{
		var username=this.state.username;
		window.open(
			"https://api.whatsapp.com/send?phone=912226795389&text=Username: "+username+", Submit Complaints",
			"_blank",
		);
	}
	
	render() {
		var storedArray = JSON.parse(localStorage.getItem("userData"));
		var apps = storedArray["data"]["online_payment"];

    return (
      <div>
        <section className="authentication-page section-b-space margin-pd">
          <div className="_3lldG">
            <div className="_2u0oK _1iY8Y">
              <form>
                <div className="_3TzfO page_head">
                  <div className="_2QN4q">
                    <Link to={`${process.env.PUBLIC_URL}/`}>
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/images/icon/return_white.png`}
                        alt=""
                      />
                    </Link>
                  </div>
                  <div className="_1leRb page_title">Classic Broadband</div>
                  <div className="b5NJg"></div>
                </div>
                <input type="submit" hidden="" />
              </form>
            </div>

            <div className="_3mMtt dashboard">
              <div class="row">
                <div class="col-6 col-lg-4 col-xl-3 pr-2">
                  <div class="card mb-3 border-0 rounded-0 gradient-primary">
                    <Link to={`${process.env.PUBLIC_URL}/account_info`}>
                      <div class="card-body">
                        <div class="media">
                          <div class="media-body">
                            <div class="dashboard-img">
                              <img
                                src={`${process.env.PUBLIC_URL}/assets/images/icon/account_info.png`}
                                alt=""
                              />
                            </div>
                            <p class="mb-0">Account Info</p>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>

                <div class="col-6 col-lg-4 col-xl-3 pl-2">
                  <div class="card mb-3 border-0 rounded-0 gradient-primary">
                    <Link to={`${process.env.PUBLIC_URL}/renew_plan`}>
                      <div class="card-body">
                        <div class="media">
                          <div class="media-body">
                            <div class="dashboard-img">
                              <img
                                src={`${process.env.PUBLIC_URL}/assets/images/icon/renew.png`}
                                alt=""
                              />
                            </div>
                            <p class="mb-0">Renew Plan</p>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>

                <div class="col-6 col-lg-4 col-xl-3 pr-2">
                  <div
                    class="card mb-3 border-0 rounded-0 gradient-primary"
                    onClick={this.whatsapp_redirect}
                  >
                    <div class="card-body">
                      <div class="media">
                        <div class="media-body">
                          <div class="dashboard-img">
                            <img
                              src={`${process.env.PUBLIC_URL}/assets/images/icon/no-wifi.png`}
                              alt=""
                            />
                          </div>
                          <p class="mb-0">Submit Complaint</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-6 col-lg-4 col-xl-3 pl-2">
                  <div class="card mb-3 border-0 rounded-0 gradient-primary">
                    <Link to={`${process.env.PUBLIC_URL}/purchase_history`}>
                      <div class="card-body">
                        <div class="media">
                          <div class="media-body">
                            <div class="dashboard-img">
                              <img
                                src={`${process.env.PUBLIC_URL}/assets/images/icon/receipt.png`}
                                alt=""
                              />
                            </div>
                            <p class="mb-0">Payment History</p>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>

                <div class="col-6 col-lg-4 col-xl-3 pr-2">
					<div
					  class="card mb-3 border-0 rounded-0 gradient-primary"
					  onClick={this.payment_redirect}
					>
					  <div class="card-body">
						<div class="media">
						  <div class="media-body">
							<div class="dashboard-img">
							  <img
								src={`${process.env.PUBLIC_URL}/assets/images/icon/paybill.png`}
								alt=""
							  />
							</div>
							<p class="mb-0">Pay Bill</p>
						  </div>
						</div>
					  </div>
					</div>
				  </div>

                <div class="col-12 col-lg-12 col-xl-12 pl-2 ">
                  <div
                    className="_3C_gI gradient-primary no-margin"
                    role="button"
                    tabIndex="0"
                    onClick={this.whatsapp_redirect_1}
                  >
                    <div className="Cxph3">
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/images/icon/tv.png`}
                        alt=""
                      />
                    </div>
                    <div className="NXGft">
                      <div className="_14v1V">Classic Cable Tv Complaint</div>
                      <div className="_3oKxo">
                        Submit Your Cable Tv Complaint Here
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
export default Index;
