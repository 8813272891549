import React from "react";
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import Lightbox from "react-image-lightbox"; 
import { Link, NavLink} from 'react-router-dom';
import '../../common/gallery.scss';
import {getData,getListData} from '../../../services/index';

class BikeGallery extends React.Component {

    componentDidMount() {
        document.getElementById("color").setAttribute("href", `#` );		
		document.querySelector(".loader-wrapper").style = "display: block";
	setTimeout(function() {
    document.querySelector(".loader-wrapper").style = "display: none";
    }, 900);
	var storedArray  = JSON.parse(localStorage.getItem("userData"));
	this.state.user_id = storedArray['data']['user_id'];
	this.state.id = this.props.match.params.id;
	this.state.type = 'car';	
	var app = {
		user_id : this.state.user_id ,
		id : this.state.id,
		type : 'car'
	}
	
	getListData('Buycar/car_bike_details',app).then((result) => {
	let responseJson = result;
	if(responseJson.data){
	   if (result['count'] > 0) {
		  this.setState({
			 ListData: result['data']
		  })
	   }
	 
	 this.setState({
			 images: result['data'][0]['images']
		  })	
	}
	});

	 
    }

state = {
  photoIndex: 0,
  isOpen: false,
  images: [],
  ListData : []
  
}

renderImages = () => {
  let photoIndex = -1;
  const { images } = this.state;

return images.map(imageSrc => {
  photoIndex++;
  const privateKey = photoIndex;
  return (
    <MDBCol md="4 no-pad" key={photoIndex}>
      <figure>
        <img src={imageSrc} alt="Gallery" className="img-fluid" onClick={()=>
        this.setState({ photoIndex: privateKey, isOpen: true })
        }
        />
      </figure>
    </MDBCol>
    );
  })
}

render() {
const { photoIndex, isOpen, images } = this.state;
  return (

        
      <MDBContainer className="gal-pad mt-5" >
          <div className="12" id={`${this.state.photo}`}>
            <section className="pb-0 margin-pd mt-45 restro">
            <div className="_2u0oK _1iY8Y">
            <Link to={`${process.env.PUBLIC_URL}/buy_cars_bike`}>
                <div className="_3TzfO page_head">
                    <div className="_2QN4q">
                       
                        <img src={`${process.env.PUBLIC_URL}/assets/images/icon/return_white.png`} alt="" />
                    </div>
					{this.state.ListData.map((list) => (
                    <div className="_1leRb page_title">
                        {list.title}
                    </div>
					))}
                    <div className="b5NJg"></div>
                </div>
                </Link>
            </div>
        </section>
        </div>
        <div className="mdb-lightbox no-margin">
          <MDBRow>
            {this.renderImages()}
          </MDBRow>
        </div>
        {isOpen && (
        <Lightbox
          mainSrc={images[photoIndex]}
          nextSrc={images[(photoIndex + 1) % images.length]}
          prevSrc={images[(photoIndex + images.length - 1) % images.length]}
          imageTitle={photoIndex + 1 + "/" + images.length}
          onCloseRequest={() => this.setState({ isOpen: false })}
          onMovePrevRequest={() =>
            this.setState({
              photoIndex: (photoIndex + images.length - 1) % images.length
            })
          }
          onMoveNextRequest={() =>
            this.setState({
              photoIndex: (photoIndex + 1) % images.length
            })
            }
          />
        )}
      </MDBContainer>
    );
  }
}

export default BikeGallery;