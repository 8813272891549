import React, { Component } from 'react';
import {Redirect} from 'react-router-dom';
import { Link, NavLink} from 'react-router-dom';
import {getListData} from '../../../services/index';
import $ from 'jquery';

class address extends Component {

	componentDidMount() {
		document.querySelector(".loader-wrapper").style = "display: block";
	setTimeout(function() {
		document.querySelector(".loader-wrapper").style = "display: none";
    }, 900);
		
	$('.float-label-1 .form-control-1[placeholder]').each(function () {
		if ($(this).attr('placeholder').length > 0) { $(this).addClass('active');}
	});
	$('.float-label-1 .form-control-1[value]').each(function () {
		if ($(this).val.length > 0) { $(this).addClass('active');}
	});
	$('.float-label-1 .form-control-1').on('blur', function () {
		if ($(this).val().length > 0) { $(this).addClass('active');} else {
			if ($(this)[0].hasAttribute('placeholder') === true) {
				if ($(this).attr('placeholder').length > 0) { $(this).addClass('active');} 
				else { $(this).removeClass('active');}
			} else { $(this).removeClass('active');}
		}
	});
	if (localStorage.getItem('userData')){
		var storedArray = JSON.parse(localStorage.getItem("userData"));
	}
		const google = window.google;	
		//const $ = window.$;  
        initialize();
        function initialize() {
          var input = document.getElementById('searchTextField');
          var autocomplete = new google.maps.places.Autocomplete(input);
            google.maps.event.addListener(autocomplete, 'place_changed', function () {
                var place = autocomplete.getPlace();
                var latitude = place.geometry.location.lat();
                var longitude = place.geometry.location.lng();
                document.getElementById('cityLat').value = place.geometry.location.lat();
                document.getElementById('cityLng').value = place.geometry.location.lng();
				
            });
        }
        google.maps.event.addDomListener(window, 'load', initialize);
		
	}
	constructor(){
		super();
		var storedArray = JSON.parse(localStorage.getItem("userData"));
		this.state = {
			flat_no: '',
			ad_location: '',
			land_mark: '',
			tag: '',
			cityLat: '',
			cityLng: '',
			user_id: storedArray['data']['user_id'],
			
		};
		this.addadress = this.addadress.bind(this);
		this.onChange = this.onChange.bind(this);
		this.handleChange = this.handleChange.bind(this);
	}
	handleChange(event) {
		
	  this.setState({
		tag: event.target.value
	  });
	}
	
	addadress() {
		if(this.state.flat_no || this.state.ad_location || this.state.land_mark){	
			this.state.cityLat = 	document.getElementById('cityLat').value;	
			this.state.cityLng = 	document.getElementById('cityLng').value;
			getListData('address/add_address',this.state).then((result) => {
			if(result.status==400){
				document.getElementById('alert').innerHTML='<div class="alert alert-warning" role="alert"><i class="fa fa-info-circle" aria-hidden="true"></i> Please insert all fields</div>';
			}
			else{
				document.getElementById('alert').innerHTML='';
				this.setState({redirectToReferrer: true});
			}
			});
		}
		if(this.state.flat_no=='' || this.state.ad_location=='' || this.state.land_mark==''){
			document.getElementById('alert').innerHTML='<div class="alert alert-warning" role="alert"><i class="fa fa-info-circle" aria-hidden="true"></i> Please insert all fields </div>';
		}
		else{
			document.getElementById('alert').innerHTML='';
		}
	}
	onChange(e){
		
		this.setState({[e.target.name]:e.target.value});
	}
	
    
	

	render() {
	if (this.state.redirectToReferrer){
			return (<Redirect to={'/my_address'}/>)
		}
		return (
			<div>
                
				
 
                <section className="pb-0 margin-pd mt-45 restro">
					<div className="_2u0oK _1iY8Y">
						
						   <div className="_3TzfO page_head">
							  <div className="_2QN4q">
								 <Link to={`${process.env.PUBLIC_URL}/my_address`}>
								 <img src={`${process.env.PUBLIC_URL}/assets/images/icon/return_white.png`} alt="" /></Link>
							  </div>
							  <div className="_1leRb page_title">
								Add New Address
							  </div>
							  <div className="b5NJg"></div>
						   </div>
					 </div>
                </section>
				
		
                <section className="pb-0 margin-pd restro details-pad-top-n mgt-60">
				    <div className="res-details-div" role="button" tabIndex="0">
						<div className="res-details-info">
							<div className="details-dish-con">
								<div className="col-md-12">
									<div className="wrap-login200">
										 <form className="login100-form validate-form">
											<div className="form-group-1 float-label-1 validate-input" data-validate="Enter location">				
												<input type="text" className="form-control-1" id="searchTextField" name="ad_location" autoComplete="off" onChange={this.onChange} placeholder=""/>
												<label className="color"> <i class="fa fa-map-marker" aria-hidden="true"></i> Location on google map</label>
												<input type="hidden" id="cityLat" name="cityLat" />
												<input type="hidden" id="cityLng" name="cityLng" />
											</div>
											<div className="form-group-1 float-label-1 validate-input" data-validate="Enter flat_no">
												<input type="text" className="form-control-1" name="flat_no" autoComplete="off" onChange={this.onChange}/>
												<label className="color"> House / Flat No.</label>
											</div>
											<div className="form-group-1 float-label-1 validate-input" data-validate="Enter land_mark">
												<input type="text" className="form-control-1" name="land_mark" autoComplete="off" onChange={this.onChange}/>
												<label className="color"> Landmark</label>
											</div>
											<div className="tag">
											<label className="color"> Tag </label>	<br/>
												<label className="mar-right color"><input type="radio" name="tag"  value="Home" checked={this.state.tag === "Home"} onChange={this.handleChange}/> Home</label>
												<label className="mar-right color"><input type="radio" name="tag" value="Work" checked={this.state.tag === "Work"} onChange={this.handleChange}/> Work</label>
												<label className="mar-right color"><input type="radio" name="tag" value="Other" checked={this.state.tag === "Other"} onChange={this.handleChange}/> Other</label>
											</div>
											
											
											<div id="alert" className="m-b-10"></div>
											
											
											
											
											
								<div className="container-login100-form-btn">				
									<input type="button"  className="login100-form-btn" value="Save" onClick={this.addadress}/>
								</div>								
							</form>
									</div>
								</div>		
							</div>
						</div>
					</div>
                </section>
				
				
				
				


 
			</div>
			
			)

	}
}

export default address;
