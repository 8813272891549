import React, { Component } from 'react';
import { Link, NavLink} from 'react-router-dom';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from 'react-loader-spinner';
import {getListData} from '../../../services/index';
import '../../common/restaurant.scss';
import {connect} from 'react-redux';
import { confirmAlert } from 'react-confirm-alert'; // Import
import { Collapse } from 'antd';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import ReadMoreAndLess from 'react-read-more-less';

const { Panel } = Collapse;

function callback(key) {
  console.log(key);
}

class RestaurantDetails extends Component {
constructor(props) {
	super(props);
	this.state = {
        openCart: '',
        countItem: 0,
        countTotal: 0,
		ListData: [],
		qty: 0,
		openQuantity: false,
        quantity: 1,
        image: '',
        name: '',
		
	};
	this.goBack = this.goBack.bind(this);
	if (!localStorage.getItem('userData')) {
		this.props.history.push("login");
	}
}
componentDidMount() {
	document.getElementById("color").setAttribute("href", `#`);
	document.querySelector(".loader-wrapper").style = "display: block";
	setTimeout(function() {
    document.querySelector(".loader-wrapper").style = "display: none";
	}, 900);
	
	let id =this.props.match.params.id;
	this.setState({
					id: id,
				})
	
	var storedArray = JSON.parse(localStorage.getItem("userData"));
	
	let c_userid = this.props.match.params.id;
		this.setState({
					c_userid: c_userid,
				})
	this.checkData();
	
	
}


checkData() {
	
	var storedArray = JSON.parse(localStorage.getItem("userData"));
	this.state.mobile=storedArray['data']['mobile'];
	var mobile = storedArray['data']['mobile'];
	var app = {
		c_userid : this.props.match.params.id
	}
	getListData('society/SocietyController/complaints_list', app).then((result) => {
		let responseJson = result;
		if (responseJson.data) {
			if (result['status'] == 200) {
				this.setState({
					ListData: result['data'],
				})
			}
		}
	});
	
}
   

   
	goBack(){
		this.props.history.goBack();
	}
	
	

	
	
	
	
	
	
	
	

render() {


var lenght = this.state.ListData.length;

return (
<div >
   
   <div>
   <div className="_2u0oK _1iY8Y border-blue">
            <form>
               <div className="_3TzfO page_head">
                  <div className="_2QN4q" onClick={this.goBack}>
                    
                     <img src={`${process.env.PUBLIC_URL}/assets/images/icon/return_white.png`} alt="" />
                  </div>
                  <div className="_1leRb page_title">
                     Complaints
                  </div>
                  <div className="b5NJg"></div>
               </div>
               <input type="submit" hidden=""/>
            </form>
         </div>
 <section className="pb-0 margin-pd no-pad restro mt-60 society-det">
	<div class="row marginlr mt-20 " style={{background: "#f5f5f9"}}>	
		
						
						{this.state.ListData.map((list) => (	
						<div class="col-12 col-lg-12 col-xl-12 pr-2 mt-10">
							<div class="card gradient-primary">
								
									<div class="card-body">
										<div class="media">
											<div class="media-body">
												<div class="col-md-12 no-padd">
													
													<p class="mb-5 pt-0 text-left font-w-550"><span class="font-s-14">{list.added_date}</span><span class="font-s-14 float-right">{list.added_time}</span></p>
													<ReadMoreAndLess
														ref={list.full_description}
														className="read-more-content"
														charLimit={37}
														readMoreText={<img src={`${process.env.PUBLIC_URL}/assets/images/icon/black-right-arrow.png`} alt="" class="arrow" />}
														readLessText={<img src={`${process.env.PUBLIC_URL}/assets/images/icon/up-arrow.png`} alt="" class="arrow" />}
													>
														{list.full_description}
													</ReadMoreAndLess>
													
												</div>
												
											</div>
										</div>
									</div>
								
							</div>
						</div>
						))}
						
						{(() => {  
								if (lenght =='0') {
								return (
									<div class="col-12 col-lg-12 col-xl-12 mt-60 center">
										<img src={`${process.env.PUBLIC_URL}/assets/images/icon/bad-review.png`} class="no-found-img" />
										<h4>No complaints raised !!</h4>
									</div>
										)
									}
								})()}
						
						 <Link to={`${process.env.PUBLIC_URL}/add-complaint/${this.state.id}`} class="w-100">
						<div class="col-12 col-lg-12 col-xl-12 mt-20 center">
							<button class="add-button">Raise A Complaint</button>
						</div>
						</Link>
						
		
		
		
	</div>
	
 </section>
 
 <div className={`float-cart show-cart society-cart`} id="float-cart">
	
	
</div>




</div>




</div>
)
}
}
	
export default RestaurantDetails;