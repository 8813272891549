import React, { Component } from 'react';
import { Link, NavLink} from 'react-router-dom';
import '../../common/restaurant.scss';
import {getListData} from '../../../services/index';
import $ from 'jquery';
import {Redirect} from 'react-router-dom';
import { Item} from "../../layouts/home/components";
import Carousel from "../../layouts/home/Carousel";
import Slider from 'react-slick';


class Home extends Component {
	constructor(){
		super();
		var storedArray = JSON.parse(localStorage.getItem("userData"));
		this.state = {
			user_name: '',
			mobile: '',
			user_id: storedArray['data']['user_id'],
			
		};
		this.addadress = this.addadress.bind(this);
		this.onChange = this.onChange.bind(this);
		this.handleChange = this.handleChange.bind(this);
	}
	handleChange(event) {
	  this.setState({
		package: event.target.value
	  });
	}
	onChange(e){
		this.setState({[e.target.name]:e.target.value});
	}
	addadress() {
		if(this.state.user_name || this.state.mobile){
			
			getListData('auth/physio_enquiry',this.state).then((result) => {
			if(result.status == 300){
				document.getElementById('alert').innerHTML='<div class="alert alert-warning" role="alert"><i class="fa fa-info-circle" aria-hidden="true"></i> Please insert all fields</div>';
			}
			else{
				document.getElementById('alert').innerHTML='';
				this.setState({redirectToReferrer: true});
			}
			
			});
		}
		if(this.state.user_name === '' && this.state.mobile === ''){
			document.getElementById('alert').innerHTML='<div class="alert alert-warning" role="alert"><i class="fa fa-info-circle" aria-hidden="true"></i> Please insert all fields </div>';
		}
		else{
			document.getElementById('alert').innerHTML='';
		}
	}
	componentDidMount() {
        document.getElementById("color").setAttribute("href", `#` );		
		document.querySelector(".loader-wrapper").style = "display: block";
	setTimeout(function() {
    document.querySelector(".loader-wrapper").style = "display: none";
}, 900);
		
		
			
			if (localStorage.getItem('userData')){
				var storedArray = JSON.parse(localStorage.getItem("userData"));
				document.getElementById("user_name").value = storedArray['data']['name'];
				document.getElementById("mobile").value = storedArray['data']['mobile'];
				
				this.setState({user_name: storedArray['data']['name'],mobile : storedArray['data']['mobile']});
				
				document.getElementById("user_name").classList.add("active");
				document.getElementById("mobile").classList.add("active");
				
				
			}

			
			
		
    }

	render() {
	if (this.state.redirectToReferrer){
			return (<Redirect to={`${process.env.PUBLIC_URL}/physio_thanks`}/>)
		}
		const settings = {
			dots: true,
			infinite: true,
			speed: 300,
			slidesToShow: 1,
			centerMode: true,
			variableWidth: true
		  };	
		return (
			<div>
                
				
                 
                {/*Home Section End*/}

                {/*collection banner*/}
                <section className="pb-0 margin-pd mt-45 restro">
					<div className="_2u0oK _1iY8Y">
						<form>
						   <div className="_3TzfO page_head">
							  <div className="_2QN4q">
								 <Link to={`${process.env.PUBLIC_URL}/`}>
								 <img src={`${process.env.PUBLIC_URL}/assets/images/icon/return_white.png`} alt="" /></Link>
							  </div>
							  <div className="_1leRb page_title">
                                Physiotherapy At Home
							  </div>
							  <div className="b5NJg"></div>
						   </div>
						   <input type="submit" hidden=""/>
						</form>
					 </div>
				    
                </section>
				
				 {/*collection banner*/}
                <section className="pb-0 no-pad phy-mar">
							<div class="collection-banner physio1">
								<img src={`${process.env.PUBLIC_URL}/assets/images/gym/images.jpg`} alt="" class="img-fluid " />
								<div class="physio-title center col-md-12">
                                    Physiotherapy at Home
                                    <div class="physio-subtitle">Whether it's a lingering back pain, a frozen shoulder or a sports injury that you're looking to recover from, you can now get treated in the comfort of your home, by certified, specialised and experienced physiotherapists.</div>
                                </div>
                                <div class="absolute-contain physio">
									
                                    <img src={`${process.env.PUBLIC_URL}/assets/images/icon/house.png`} alt="" class="img-fluid " />
                                    <h3>Home Visit</h3>
									<h4 class="physio-span-title">STARTING AT @ ₹600/-</h4>

                                    <form className="login100-form validate-form mt-10">
											<div className="form-group-1 float-label-1 validate-input" data-validate="Enter user_name">
												<input type="text" className="form-control-1" name="user_name" id="user_name" autoComplete="off" onChange={this.onChange}/>
												<label className="color lab-top"> User Name</label>
											</div>
											<div className="form-group-1 float-label-1 validate-input" data-validate="Enter mobile">
												<input type="text" className="form-control-1" name="mobile" id="mobile"  autoComplete="off" onChange={this.onChange}/>
												<label className="color lab-top"> Mobile No.</label>
											</div>
											
												
											<div id="alert" className="m-b-10"></div>	
											
											<div className="col-md-12 center mb-20">
													<input type="button"  className="login100-form-btn" value="Book Appointment" onClick={this.addadress}/>
											</div>
										</form>
							
						</div>
					</div>
                </section>
				

 
			</div>
			
			)

	}
}

export default Home;
