import React, {Component} from 'react';
import { Link, NavLink} from 'react-router-dom';
import Breadcrumb from "../common/breadcrumb";
import $ from 'jquery';
import {LoginAuth} from '../../services/index';
import {Redirect} from 'react-router-dom';

class Otp extends Component {

    constructor (props) {
		super();
		this.state = {
			otp: '',
			user_id:'',
			token: '',
			redirectToReferrer: false
		};
		this.login = this.login.bind(this);
		this.onChange = this.onChange.bind(this);

	}
	
	login() {
		if((this.state.otp)){
			let mobile = this.props.match.params.id;
			this.state.mobile = mobile;

			LoginAuth('auth/check_otp',this.state).then((result) => {
			if(result.status==400){
				document.getElementById('alert').innerHTML='<div class="alert alert-warning" role="alert"><i class="fa fa-info-circle" aria-hidden="true"></i> Incorect OTP </div>';
			}
			else{
				document.getElementById('alert').innerHTML='';
				let responseJson = result;
						if(responseJson.data){					
							localStorage.setItem('userData',JSON.stringify(responseJson));
							this.setState({redirectToReferrer: true});
						}
			}	
			
				
			});
		}
		if(this.state.otp==''){
			document.getElementById('alert').innerHTML='<div class="alert alert-warning" role="alert"><i class="fa fa-info-circle" aria-hidden="true"></i> Please enter OTP</div>';
		}
		else{
			document.getElementById('alert').innerHTML='';
		}
	}

	onChange(e){
		this.setState({[e.target.name]:e.target.value});
	}

	componentDidMount() {
		document.querySelector(".loader-wrapper").style = "display: none";
		
	$('.float-label .form-control[placeholder]').each(function () {
		if ($(this).attr('placeholder').length > 0) { $(this).addClass('active');}
	});
	$('.float-label .form-control[value]').each(function () {
		if ($(this).val.length > 0) { $(this).addClass('active');}
	});
	$('.float-label .form-control').on('blur', function () {
		if ($(this).val().length > 0) { $(this).addClass('active');} else {
			if ($(this)[0].hasAttribute('placeholder') === true) {
				if ($(this).attr('placeholder').length > 0) { $(this).addClass('active');} 
				else { $(this).removeClass('active');}
			} else { $(this).removeClass('active');}
		}
	});

	let mobile = this.props.match.params.id;
	this.state.mobile = mobile;
	document.getElementById("number").innerHTML = this.state.mobile;


	}
    render (){

		if (this.state.redirectToReferrer){
			return (<Redirect to={'/'}/>)
		}
        return (
            <div>
                
                
                
                {/*Regsiter section*/}
					<div className="_2u0oK _1iY8Y">
						<form>
						   <div className="_3TzfO page_head">
							  <div className="_2QN4q">
								 <Link to={`${process.env.PUBLIC_URL}/register`}>
								 <img src={`${process.env.PUBLIC_URL}/assets/images/icon/return_white.png`} alt="" /></Link>
							  </div>
							  <div className="_1leRb page_title">
								 Enter OTP
							  </div>
							  <div className="b5NJg"></div>
						   </div>
						   <input type="submit" hidden=""/>
						</form>
					 </div>
                    <div className="limiter">
					<div className="container-login100">
					
					
						<div className="wrap-login100">
						<div className="login-fm">
							<form className="login100-form validate-form">
								<span className="login100-form-logo"> </span>
								<div className="otp-send">			
									Enter OTP send to
									<span class="block" ><strong id="number"></strong>
									 <Link to={`${process.env.PUBLIC_URL}/register`}><span class="float-right">change</span></Link>
									</span>
									
									
								</div>
								<div id="alert" className="m-b-10"></div>
								<div className="form-group float-label validate-input" data-validate="Enter username">
									
									<input type="number" pattern="[0-9]*" className="form-control" name="otp" autoComplete="off" onChange={this.onChange}/>
									<label><i className="fa fa-user" aria-hidden="true"></i> Enter Otp</label>
								</div>
								<div className="otp-resend">			
									Resend OTP
								</div>
								
								<div className="container-login100-form-btn">			
									<input type="hidden" name="token" id="token-input" value='null' onChange={this.onChange}/>
									<input type="button"  className="login100-form-btn" value="Login" onClick={this.login}/>
								</div>	
								
							</form>
							
							</div>
						</div>
						
					</div>
					
				</div>
               

            </div>
        )
    }
}

export default Otp