import React, { Component } from 'react';
import { Link, NavLink} from 'react-router-dom';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from 'react-loader-spinner';
import {getListData} from '../../../services/index';
import '../../common/restaurant.scss';
import {connect} from 'react-redux';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

class RestaurantDetails extends Component {
constructor(props) {
	super(props);
	this.state = {
		id:0,
        openCart: '',
        countItem: 0,
        countTotal: 0,
		currentData: [],
		previousData: [],
		qty: 0,
		openQuantity: false,
        quantity: 1,
        image: '',
        name: '',
		
	};
	this.goBack = this.goBack.bind(this);
	if (!localStorage.getItem('userData')) {
		this.props.history.push("login");
	}
}
componentDidMount() {
	document.getElementById("color").setAttribute("href", `#`);
	document.querySelector(".loader-wrapper").style = "display: block";
	setTimeout(function() {
    document.querySelector(".loader-wrapper").style = "display: none";
	}, 900);
	
	let id =this.props.match.params.id;
	this.setState({
		id: id,
	})
	
	var storedArray = JSON.parse(localStorage.getItem("userData"));
	
	this.state.mobile=storedArray['data']['mobile'];
	this.checkData();
	
	
}


	checkData() {
		var storedArray = JSON.parse(localStorage.getItem("userData"));
		this.state.mobile=storedArray['data']['mobile'];
		var mobile = storedArray['data']['mobile'];
		var app = {
			id : this.props.match.params.id,
			unit_id : this.props.match.params.unit_id
		}
		getListData('society/SocietyController/bill_list', app).then((result) => {
			let responseJson = result;
			if (responseJson.data) {
				if (result['status'] == 200) {
					this.setState({
						currentData: result['data'],
						previousData: result['previous_data'],
					})
				}
			}
		});
	}
   

goBack(){
		this.props.history.goBack();
	}	
	
	


render() {
return (

   

    <div>
		<div className="_2u0oK _1iY8Y border-blue">
			<form>
			   <div className="_3TzfO page_head">
				  <div className="_2QN4q" onClick={this.goBack}>
					
					 <img src={`${process.env.PUBLIC_URL}/assets/images/icon/return_white.png`} alt="" />
				  </div>
				  <div className="_1leRb page_title">
					 Maintenance bill
				  </div>
				  <div className="b5NJg"></div>
			   </div>
			   <input type="submit" hidden=""/>
			</form>
		</div>	
		 
		<section className="renew-tab tab-product mt-20 purchase-tab">
			<div className="row">
				<div className="col-sm-12 col-lg-12" style={{padding: '0px'}}>
					<Tabs className="tab-content nav-material">
						<TabList className="nav nav-tabs nav-material">
							<Tab className="nav-item">
								<span className="nav-link active">
									<i className="icofont icofont-ui-home"></i>Current Bill</span>
								<div className="material-border"></div>
							</Tab>
							<Tab className="nav-item">
								<span className="nav-link" ><i className="icofont icofont-man-in-glasses"></i>Previous Bill</span>
								<div className="material-border"></div>
							</Tab>
						</TabList>

						<TabPanel className="tab-pane fade mt-4 show active">
							<section className="renew-tab tab-product m-0 bg-purple tickets pt-50s">
								<div className="row">
									<div className="col-sm-12 col-lg-12" style={{padding: '20px 30px'}}>

									{this.state.currentData.map((current) => (
										<div class="card gradient-primary">
										    <Link to={`${process.env.PUBLIC_URL}/bill_details/${this.state.id}/${current.bill_id}`}>
											  <div class="card-body">
												 <div class="bill_tab">
													<div class="media-body">
													   <div class="col-md-9 no-padd">
														  <p class="mb-0 text-left soc-mt-0 font-s-16 font-w-550">{current.current_month}</p>
														  
														 
							{(() => {
								if (current.is_paid>0) {
									return (
										<>
											 <p class="mb-0 text-left" style={{color: '#048404 !important'}}>Paid On: {current.paid_on}</p> 
										</>
									)
								}
								else{
									return (
										<>
											 <p class="mb-0 text-left">Due date: {current.due_date}</p>
										</>
									)
								}
							})()}
														  
													   </div>
													   <div class="col-md-3 no-padd">
													   <b style={{color: '#3e3e98'}}><i className="fa fa-inr" aria-hidden="true"></i> {current.total}/- </b><br/>
														<Link to={`${process.env.PUBLIC_URL}/bill_details/${this.state.id}/${current.bill_id}`} style={{marginTop: '0px'}} class="chat_order_btn"> 
															View 
														</Link>
														</div>
													</div>
												 </div>
											  </div>
										   </Link>
										</div>
									))}

									</div>
								</div>
							</section>
						</TabPanel>

						<TabPanel className="renew-tab2">
							<section className="renew-tab tab-product m-0 bg-purple tickets ticket2 pt-50s">
								<div className="row">
									<div className="col-sm-12 col-lg-12" style={{padding: '20px 30px'}}>

										
										
									
							{(() => {
								if (this.state.previousData.length>0) {
									return (
										<>
											{this.state.previousData.map((current) => (
												<div class="card gradient-primary  mb-15">
													
													<Link to={`${process.env.PUBLIC_URL}/bill_details/${this.state.id}/${current.bill_id}`}>
													  <div class="card-body">
														 <div class="bill_tab">
															<div class="media-body">
															   <div class="col-md-9 no-padd">
																  <p class="mb-0 text-left soc-mt-0 font-s-16 font-w-550">{current.current_month}</p>
																 
{(() => {
								if (current.is_paid>0) {
									return (
										<>
											 <p class="mb-0 text-left" style={{color: '#048404 !important'}}>Paid On: {current.paid_on}</p> 
										</>
									)
								}
								else{
									return (
										<>
											 <p class="mb-0 text-left">Due date: {current.due_date}</p>
										</>
									)
								}
							})()}



																 </div>
															   <div class="col-md-3 no-padd">
															   <b style={{color: '#3e3e98'}}><i className="fa fa-inr" aria-hidden="true"></i> {current.total}/- </b><br/>
																<Link to={`${process.env.PUBLIC_URL}/bill_details/${this.state.id}/${current.bill_id}`} style={{marginTop: '0px'}} class="chat_order_btn"> 
																	View
																</Link>
																</div>
															</div>
														 </div>
													  </div>
												   </Link>
												</div>
											))}
										</>
									)
								}
								else{
									return (
										<>
											<div class="card gradient-primary mb-15 no_previous">
												No Previous Bill Found!
											</div>
										</>
									)
								}
							})()}
									
									

									</div>
								</div>
							</section>
						</TabPanel>

					</Tabs>
				</div>
			</div>
        </section>
		 
		

	
</div>
)
}
}
	
export default RestaurantDetails;