import React, { Component } from 'react';
import { Link, NavLink} from 'react-router-dom';
import Slider from 'react-slick';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from 'react-loader-spinner';
import '../../common/restaurant.scss';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import {getData,getListData} from '../../../services/index';
import Carousel, { Modal, ModalGateway } from 'react-images';


const images = [{ src: 'https://classicxtra.com/bucket/cars-bike/5dd1329b42e7220191117114427.jpeg', src: 'https://classicxtra.com/bucket/cars-bike/5dd1329b42e7220191117114427.jpeg' }];


class Home extends Component {
constructor(props) {
super(props);
this.state = {
ListData: [],
type : '',
user_id: '',
modalIsOpen: false
};
if(!localStorage.getItem('userData')) {
this.props.history.push("login");
}
}

toggleModal = () => {
    this.setState(state => ({ modalIsOpen: !state.modalIsOpen }));
  }

componentDidMount() {
document.getElementById("color").setAttribute("href", `#` );		
setTimeout(function() {
document.querySelector(".loader-wrapper").style = "display: none";
}, 2000);
var storedArray  = JSON.parse(localStorage.getItem("userData"));
this.state.user_id = storedArray['data']['user_id'];
this.state.id = this.props.match.params.id;
this.state.type = 'bike';	
getListData('Buycar/car_bike_details',this.state).then((result) => {
let responseJson = result;
if(responseJson.data){
   if (result['count'] > 0) {
      this.setState({
         ListData: result['data']
      })
   }
}
});

}
onChange(e){
this.setState({[e.target.name]:e.target.value});
}
render() {
const { modalIsOpen } = this.state;
return (
   
<div>
{this.state.ListData.map((list) => (
   <section className="pb-0 margin-pd restro renew-tab tab-product mt-20 purchase-tab">
      <div className="_2u0oK _1iY8Y">
         <div className="_3TzfO page_head">
            <div className="_2QN4q">
               <Link to={`${process.env.PUBLIC_URL}/buy_cars_bike`}>
               <img src={`${process.env.PUBLIC_URL}/assets/images/icon/return_white.png`} alt="" /></Link>
            </div>

            <div className="_1leRb page_title res-title nowrap">
               {list.title}
            </div>
            <div className="b5NJg"></div>
         </div>
      </div>
      <div class="">
      <div class="mt-0 bg-white ">
      <Slider  className="slide-1 home-slider m-t-20 mt-0">
                     
                        <div>
                            <div className="home text-center" style={{backgroundImage: `url(${list.image2})`}}>
                                <div className="container">
                                    <div className="row">
                                        <div className="col">
                                            <div className="slider-contain">
                                               
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
					
                   
            </Slider>

        </div>
					<Link to={`/bike_gallery/${list.id}`}>
					<div class="bg-white mb-10">
                        <div class="bike-p-5">
                        <span class="bill-detail-text m-0 car-title bt">View More Images</span>
                        
                        </div>
                    </div>
					</Link>

                    <div class="bg-white mb-10">
                        <div class="p-15">
                        <h2 class="bill-detail-text m-0 car-title">{list.title}</h2>
                        <h2 class="bill-detail-text m-0 car-ampount"><b> <i className="fa fa-inr" aria-hidden="true"></i>{list.rate}</b></h2>
                        </div>
                    </div>

         <div class="bg-white mb-10 mt-10">
            <div class="p-15">
               <h2 class="bill-detail-text m-0"><b>Details</b></h2>
               <div class="display-flex">
                  <div class="flex-auto col-md-6 no-pad">Brand</div>
                  <div class="flex-auto col-md-6 no-pad"> <b>{list.brand}</b></div>
               </div>
               <div>
                  <div class="display-flex">
                     <div class="flex-auto col-md-6 no-pad">Model</div>
                     <div class="flex-auto col-md-6 no-pad"><b>{list.model}</b></div>
                  </div>
                  <div class="display-flex">
                     <div class="flex-auto col-md-6 no-pad">Year</div>
                     <div class="flex-auto col-md-6 no-pad"><b> {list.year}</b></div>
                  </div>
                  <div class="display-flex">
                     <div class="flex-auto col-md-6 no-pad">KM Deiven</div>
                     <div class="flex-auto col-md-6 no-pad"> <b>{list.km_driven} </b></div>
                  </div>
               </div>
            </div>
         </div>
         <div class="bg-white mb-110 mt-10">
            <div class="p-15">
               <h2 class="bill-detail-text m-0 h-15"><b>Description</b></h2>
               <div class="flex-auto">
               {list.description} 
               </div>
            </div>
         </div>
         <div className={`float-cart center ${this.state.openCart}`} id="float-cart" style={{display: "block"}}>
         <Link className="text-white" to={`${process.env.PUBLIC_URL}/bike_enquiry/${list.id}`}>
                  <span class="">Send Enquiry <i class="si si-basket"></i></span>
                  <canvas height="0" width="0" class="canvas"></canvas></Link>
         </div>
      </div>
   </section>
   ))}
</div>
	
)
}
}
export default Home;