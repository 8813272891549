import React, { Component } from 'react';
import { Link, NavLink} from 'react-router-dom';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from 'react-loader-spinner';
import {getListData} from '../../../services/index';
import '../../common/restaurant.scss';
import {connect} from 'react-redux';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

class RestaurantDetails extends Component {
constructor(props) {
	super(props);
	this.state = {
		id:0,
        openCart: '',
        countItem: 0,
        countTotal: 0,
		ListData: [],
		qty: 0,
		openQuantity: false,
        quantity: 1,
        image: '',
        name: '',
		unit_id:'0'
		
	};
	
	if (!localStorage.getItem('userData')) {
		this.props.history.push("login");
	}
}
componentDidMount() {
	document.getElementById("color").setAttribute("href", `#`);
	document.querySelector(".loader-wrapper").style = "display: block";
	setTimeout(function() {
    document.querySelector(".loader-wrapper").style = "display: none";
	}, 900);
	
	let id =this.props.match.params.id;
	this.setState({
					id: id,
				})
	
	var storedArray = JSON.parse(localStorage.getItem("userData"));
	
	this.state.mobile=storedArray['data']['mobile'];
	this.checkData();
	
	
}


checkData() {
	
	var storedArray = JSON.parse(localStorage.getItem("userData"));
	this.state.mobile=storedArray['data']['mobile'];
	var mobile = storedArray['data']['mobile'];
	var app = {
		id : this.props.match.params.id,
		bill_id:this.props.match.params.bill_id 
	}
	getListData('society/SocietyController/bill_details', app).then((result) => {
		let responseJson = result;
		if (responseJson.data) {
			if (result['status'] == 200) {
				this.setState({
					ListData: result['data'],
					unit_id:result['data'][0]['unit_id']
				})
			}
		}
	});
	
}

	goBack=()=>{
		this.props.history.goBack();
	}
   

render() {


var lenght = this.state.ListData.length;

return (
<div>
   
    <div>
		<div className="_2u0oK _1iY8Y border-blue">
			<form>
			   <div className="_3TzfO page_head">
				  <div className="_2QN4q" onClick={this.goBack}>
                     <img src={`${process.env.PUBLIC_URL}/assets/images/icon/return_white.png`} alt="" />
                  </div>
				  <div className="_1leRb page_title">
					 Maintenance bill
				  </div>
				  <div className="b5NJg"></div>
			   </div>
			   <input type="submit" hidden=""/>
			</form>
		</div>	
		 
		<section className="renew-tab tab-product mt-20 purchase-tab">
			<div className="row">
				<div className="col-sm-12 col-lg-12" style={{padding: '0px'}}>
					<div className="tab-content nav-material">
						

						<div className="tab-pane fade mt-4 show active bills">
							<section className="renew-tab tab-product m-0 bg-purple pt-18s">
								<div className="row">
									<div className="col-sm-12 col-lg-12">
{this.state.ListData.map((current) => (
										<div class="card rounded-0 border-0 text-center pricing mb-3">
											<div class="card-body">
												<h4 class="plan-title">Bill for period : {current.current_month}</h4>
												<table class="table  mb-0 table-plan">
													<tbody>
														<tr>
															<th style={{width: '100%'}}>Maintenance Charge</th>
															<td><i className="fa fa-inr" aria-hidden="true"></i> {current.maintenance_charge}</td>
														</tr>
														<tr>
															<th>Car Parking</th>
															<td><i className="fa fa-inr" aria-hidden="true"></i> {current.car_charge}</td>
														</tr>
														<tr>
															<th>Bike Parking</th>
															<td><i className="fa fa-inr" aria-hidden="true"></i> {current.bike_charge}</td>
														</tr>
														<tr>
															<th>Stilt Parking</th>
															<td><i className="fa fa-inr" aria-hidden="true"></i> {current.stilt_charge}</td>
														</tr>
													</tbody>
													<tfoot>
														<tr>
															<th>Total</th>
															<td><b><i className="fa fa-inr" aria-hidden="true"></i> {current.total}</b></td>
														</tr>
													</tfoot>
												</table>
											</div>
										</div>
										))}

									</div>
								</div>
							</section>
						</div>


					</div>
				</div>
			</div>
        </section>
		 
		<section className="pb-0 margin-pd no-pad restro mt-60 society-det hide">
			<div class="row marginlr mt-20" style={{background: "#f5f5f9"}}>
				<div class="col-12 col-lg-12 col-xl-12 mt-60 center">
					<img src={`${process.env.PUBLIC_URL}/assets/images/icon/invoices.png`} class="no-found-img" />
					<h4>No bill found !!</h4>
				</div>	
			</div>
		</section>
	</div>
</div>
)
}
}
	
export default RestaurantDetails;