import React, { Component } from 'react';
import { Link, NavLink} from 'react-router-dom';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from 'react-loader-spinner';
import '../../common/restaurant.scss';
import {getData,getListData} from '../../../services/index';
import Slider from 'react-slick';

class Home extends Component {
	constructor(props) {
        super(props);
		this.state = {
			ListData: [],
			ListData1: [],
			tag : '',
			user_id: ''
		};
		if(!localStorage.getItem('userData')) {
		   this.props.history.push("login");
        }
		if(!localStorage.getItem('user_lat')) {
		   localStorage.setItem('search_redirect', 'order_food_online');
		   this.props.history.push("search");
        }		
    }

    componentDidMount() {
		localStorage.setItem('search_redirect', 'order_food_online');
		this.getDefaultaddress();
        document.getElementById("color").setAttribute("href", `#` );		
		document.querySelector(".loader-wrapper").style = "display: block";
		setTimeout(function() {
		document.querySelector(".loader-wrapper").style = "display: none";
		}, 900);
		var storedArray  = JSON.parse(localStorage.getItem("userData"));
		this.state.user_id = storedArray['data']['user_id'];
		var user_id = storedArray['data']['user_id'];
		window.showAndroidToast(user_id);		
		var user_lat = localStorage.getItem("user_lat");
		if(user_lat>0){
			this.state.lat = localStorage.getItem("user_lat");
			this.state.long = localStorage.getItem("user_lng");
		}
		else{	
			this.state.lat = '19.142843';
			this.state.long = '72.84326399999999';	
		}		
		getListData('restaurant/restaurant_list',this.state).then((result) => {
		let responseJson = result;
			if(responseJson.data){
			    if(result['count']>0){
					this.setState({ 
						ListData: result['data']
						
					})				
					//document.getElementById("loader").style = "display: none";
				}else{
					//document.getElementById("loader").style = "display: none";
					document.getElementById('alert').innerHTML='<div class="_2czoF"><div class="_1KevM empty-rest"><img src="assets/images/icon/food.png" alt="" /></div><div class="_1KevM empty-rest">No Restaurant Found in this location!</div></div>';
					
				}
			}
		});
		this.state.tag = 'retaurant'

		getListData('auth/add_banner_list', this.state).then((result) => {
			let responseJson = result;
			if (responseJson.data) {
				this.setState({
					ListData1: result['data']
				})
			}
		});
		this.getDefaultaddress();
    }
	
		
	getDefaultaddress(){
		if (localStorage.getItem('user_lat')>0) {
			this.setState({ 
				user_location_name : localStorage.getItem("user_location_name"),
				user_location_address : localStorage.getItem("user_location_address")
			});			
		}
		else{
			if (localStorage.getItem('userData')) {
			var storedArray = JSON.parse(localStorage.getItem("userData"));
			this.state.user_id = storedArray['data']['user_id'];
			getListData('address/default_address', this.state).then((result) => {
			let responseJson = result;
			if (responseJson.data) {
				if (result['count'] > 0) {
					localStorage.setItem('user_lat', result['data'][0]['map_lat']);
					localStorage.setItem('user_lng', result['data'][0]['map_long']);
					localStorage.setItem('user_location_name', result['data'][0]['location']);
					localStorage.setItem('user_location_address', result['data'][0]['flat_no']);		
					this.setState({ 
						user_location_name : result['data'][0]['location'],
						user_location_address : result['data'][0]['flat_no'],
						
					});
				}
				else{
					this.setState({ 
						user_location_name : 'Set Location',
						user_location_address : 'set your current location'
					});
				}
			}
			});
			}			
		}
	}
	
	
	search() {
			getData('auth/search',this.state).then((result) => {
			if(result.status==300){
				document.getElementById('search').innerHTML='<div class="alert alert-warning" role="alert">Sorry no restaurant match</div>';
			}
			else{
				document.getElementById('search').innerHTML='';
			}
			});
	}
	
	onChange(e){
		this.setState({[e.target.name]:e.target.value});
	}

	render() {


		return (
			<div>
<header id="sticky" className="sticky margin-pd">
					<Link to={`${process.env.PUBLIC_URL}/`} class="_2QN4q">
					<img src={`${process.env.PUBLIC_URL}/assets/images/icon/return_white.png`} alt="" />
					</Link>
					<Link to={`${process.env.PUBLIC_URL}/search`}>
						<div className="_top_location">
							<div className="first_line"><span className="nowrap">{this.state.user_location_name} </span><span className="location-arrow"><i className="fa fa-angle-down" aria-hidden="true"></i></span></div>
							<div className="second_line">{this.state.user_location_address}</div>
							</div>
					</Link>
				</header>

		 <div class="mt-60 advertise">
            <Slider  className="slide-1 home-slider m-t-20">
                     {this.state.ListData1.map((banner) =>
                        <div>
                            <div className="home text-center" style={{backgroundImage: `url(${banner.image})`}}>
                                <div className="container">
                                    <div className="row">
                                        <div className="col">
                                            <div className="slider-contain">
                                               
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                     )} 
            </Slider>
         </div>
  
                <section className="pb-0 margin-pd mt-0  no-pad restro">
				<div id="alert" className="m-b-10"></div>
					{this.state.ListData.map((list) => (
				   (() => {
				    var img = list.image ;
				  
					if (list.delivery_status =='1') {
					return (
						 <Link to={`${process.env.PUBLIC_URL}/restaurant_details/${list.id}`}>
							<div className="res-div" role="button" tabIndex="0">
								<div className="res-img">

									

									<div className="" >
									 <img src={`${list.image}`} alt="" className="bgimg1" /> 
									
									{(() => {
										if (list.discount !='0') {
										return (
											<div className="img1-padd">
												<div className="order_offer" >Flat {list.discount}% Off</div>
											</div>
												
											)
										}else if(list.discount == '0' && list.discount == ''){
											return (
												<div className="img1-padd">
													
												</div>
												)
										}
										
										

										})()}

									</div>
									
								</div>	
								<div className="res-info">
									<div className="_14v1V"><div className="nowrap">{list.restaurant_name}</div></div>
									<div className="_3oKxo">{list.tag}</div>
									<div className="_3oKxo"><i className="fa fa-motorcycle" aria-hidden="true"></i> {list.delivery_time}</div>
									<div className="order_details"><i className="fa fa-inr" aria-hidden="true"></i>{list.min_order} min order</div>
									<div className={`order_details del-close del-${list.status_col}`} >Delivery {list.time_status}</div>
								</div>					
							</div>
							
							</Link>	
							
						)
					}
				})()
					))}
					
                </section>

</div>
 
			
			)

	}
}

export default Home;
