import React, { Component } from 'react';
import { Link, NavLink} from 'react-router-dom';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from 'react-loader-spinner';
import {getListData} from '../../../services/index';
import $ from 'jquery'; 
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Widget, addResponseMessage, addLinkSnippet, addUserMessage,renderCustomComponent } from 'react-chat-widget';
import { withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import 'react-chat-widget/lib/styles.css';
import axios from 'axios';
import '../../common/medicine.scss';



class order_medicine extends Component {
	constructor(props) {
        super(props);
		this.state = {
			ListData: [],
			autofocus: false,
			file:null,
			imgSrc:null,
			openimgSrc:null,
			visible: false
		};
        this.onSubmit = this.onSubmit.bind(this);
        this.onChange = this.onChange.bind(this);
        this.uploadFile = this.uploadFile.bind(this);
		if(!localStorage.getItem('userData')) {
		   this.props.history.push("login");
        }
    }
	
	showModal = (img) => {
		this.setState({
			openimgSrc:img
		});
		var modal = document.getElementById("myModal");
		modal.style.display = "block";
	};
	handleCancel = e => {
		var modal = document.getElementById("myModal");
		modal.style.display = "none";
		this.setState({
			openimgSrc:null
		});		
	};

	async onSubmit(e){
		if(this.state.file!=null){
			document.getElementById("uploading").style = "display: block";
			e.preventDefault();
			let res = await this.uploadFile(this.state.file);	
			if(this.state.file){
				var links = res.data.url;
				renderCustomComponent(this.FileLoader,{type:'image',file:links},true);
				var time = res.data.created_at;
				renderCustomComponent(this.RightTimeLoader,{time:time},true);
			}
			document.getElementById('file_upload').value='';
			document.getElementById("uploading").style = "display: none";
			this.setState({
				imgSrc:null
			});
		}
    }
	
    onChange(e) {		
		document.getElementById("uploading").style = "display: block";
        this.setState({file:e.target.files[0]});
		this.setState({
			imgSrc: URL.createObjectURL(e.target.files[0])
		});		
		setTimeout(function(){ 
			$('.rcw-send').eq(0).click();
		}, 2000);
    }
	
	UPLOAD_ENDPOINT = 'https://liveapi.classicxtra.com/chat/image_upload';
    async uploadFile(file){
		var storedArray = JSON.parse(localStorage.getItem("userData"));	
		var to_id = this.props.match.params.id;
		var from_id = storedArray['data']['user_id'];		
        const formData = new FormData();
        formData.append('avatar',file);
        formData.append('to_id',to_id);
        formData.append('from_id',from_id);
        formData.append('type','user');
        return  await axios.post(this.UPLOAD_ENDPOINT, formData,{
            headers: {
                'content-type': 'multipart/form-data'
            }
        });		
    }

    componentDidMount() {
		window.AskCamera();
		document.querySelector('.rcw-launcher').click();
        document.getElementById("color").setAttribute("href", `#` );		
		document.querySelector(".loader-wrapper").style = "display: block";
		setTimeout(function() {
			document.querySelector(".loader-wrapper").style = "display: none";
		}, 900);		
		var storedArray  = JSON.parse(localStorage.getItem("userData"));
		this.state.user_id = storedArray['data']['user_id'];
		var user_id = storedArray['data']['user_id'];
		window.zoom();		
		window.showAndroidToast(user_id);
		this.getData();
		setInterval(this.checkData, 5000);
		
    }
	checkData = () => {		
		if (localStorage.getItem('userData')){
			var storedArray = JSON.parse(localStorage.getItem("userData"));	
			var to_id = this.props.match.params.id;
			var last_id = document.getElementById("last_id").value;
			var app = {
				to_id: to_id,
				from_id: storedArray['data']['user_id'],
				last_id:last_id,
				type:'user'
			}
			getListData('chat/chat_user_new_chat', app).then((result) => {
				let responseJson = result;
				if (responseJson.data) {
					if (result.data.length > 0) {
						for(var i=0;i<result.data.length;i++){
							if(result.data[i].type=='pharmacy'){
								if(result.data[i].file!=''){
									var links = result.data[i].file;
									renderCustomComponent(this.FromFileLoader,{type:'image',file:links},true);
									var time = result.data[i].created_at;
									renderCustomComponent(this.LeftTimeLoader,{time:time},true);
								}
								else{
									addResponseMessage(result.data[i].message);
									var time = result.data[i].created_at;
									renderCustomComponent(this.LeftTimeLoader,{time:time},true);
								}
							}
							document.getElementById("last_id").value=result.data[i].id;
						}
					}
					document.getElementById("from_name").innerHTML=result.name;
				}
			});
		}
    }
	getData = () => {
		if (localStorage.getItem('userData')){
		var storedArray = JSON.parse(localStorage.getItem("userData"));	
		var to_id = this.props.match.params.id;
		var app = {
			to_id: to_id,
			from_id: storedArray['data']['user_id'],
			type:'user'
		}
		getListData('chat/chat_user_details', app).then((result) => {
			let responseJson = result;
			if (responseJson.data) {
				if (result.data.length > 0) {
					for(var i=0;i<result.data.length;i++){
						if(result.data[i].type=='pharmacy'){
							if(result.data[i].file!=''){
								var links = result.data[i].file;
								renderCustomComponent(this.FromFileLoader,{type:'image',file:links},true);
								var time = result.data[i].created_at;
								renderCustomComponent(this.LeftTimeLoader,{time:time},true);
							}
							else{
								addResponseMessage(result.data[i].message);
								var time = result.data[i].created_at;
								renderCustomComponent(this.LeftTimeLoader,{time:time},true);
							}
						}
						if(result.data[i].type=='user'){
							if(result.data[i].file!=''){
								var links = result.data[i].file;
								renderCustomComponent(this.FileLoader,{type:'image',file:links},true);
								var time = result.data[i].created_at;
								renderCustomComponent(this.RightTimeLoader,{time:time},true);
							}
							else{
								addUserMessage(result.data[i].message);
								var time = result.data[i].created_at;
								renderCustomComponent(this.RightTimeLoader,{time:time},true);
							}
							
						}						
						document.getElementById("last_id").value=result.data[i].id;
					}
				}
				document.getElementById("from_name").innerHTML=result.name;
			}
			
		});
		}

    }
	
	LeftTimeLoader = (props: Object) => {
    return (
      <div className="width100">
		<div class="rcw-left-time">
			{props.time} <i class="fa fa-check" aria-hidden="true"></i>
		</div>
		
	  </div>
    );
	};
	
	RightTimeLoader = (props: Object) => {
    return (
      <div className="width100">
		<div class="rcw-right-time">
			 {props.time} <i class="fa fa-check" aria-hidden="true"></i>
		</div>
		
	  </div>
    );
	};
	
	FileLoader = (props: Object) => {
    return (
      <div className="rcw-client pres-img">
		<div class="rcw-snippet">
			<div class="rcw-snippet-details">
				<div class="rcw-link">
					<img src={`${props.file}`} onClick={this.showModal.bind(this,props.file)} className="chat-file s" />
				</div>
			</div>
		</div>
		
	  </div>
    );
	};
	
	FromFileLoader = (props: Object) => {
    return (
      <div className="rcw-response pres-img">
		<div class="rcw-snippet">
			<div class="rcw-snippet-details">
				<div class="rcw-link">
					<img src={`${props.file}`} onClick={this.showModal.bind(this,props.file)} className="chat-file s" />
				</div>
			</div>
		</div>
		
	  </div>
    );
	};
	
	handleNewUserMessage = (newMessage) => {
		if (localStorage.getItem('userData')){
			var storedArray = JSON.parse(localStorage.getItem("userData"));	
			var to_id = this.props.match.params.id;
			var app = {
				to_id: to_id,
				from_id: storedArray['data']['user_id'],
				message:newMessage,
				type:'user'
			}
			getListData('chat/add_chat', app).then((result) => {
				if (result.status == 200) {
					var time = result.data[0].created_at;
					renderCustomComponent(this.RightTimeLoader,{time:time},true);
				}
			});
		}
	}

render() {
	const ColorLinearProgress = withStyles({
		colorPrimary: {
			backgroundColor: '#b2dfdb',
		},
		barColorPrimary: {
			backgroundColor: '#00695c',
		},
	})(LinearProgress);
		
	return (
		<div class="chat_box">
		<div className="_2u0oK _1iY8Y">
            <form>
               <div className="_3TzfO page_head">
                  <div className="_2QN4q">
                     <Link to={`${process.env.PUBLIC_URL}/order_medicine`}>
                     <img src={`${process.env.PUBLIC_URL}/assets/images/icon/return_white.png`} alt="" /></Link>
                  </div>
                  <div className="_1leRb page_title">
                    Order Medicines
                  </div>
                  <div className="b5NJg"></div>
               </div>
               <input type="submit" hidden=""/>
            </form>
         </div>
		 <ul class="nav nav-tabs nav-material from_name">
                  <li>
                    <div id="from_name"></div>
                  </li>
               </ul>
			   
			      <input type="hidden" id="last_id"/>
					<form onSubmit={ this.onSubmit }>
						<div className="row">
								<Widget handleNewUserMessage={this.handleNewUserMessage} title="" subtitle="" autofocus={this.state.autofocus} /> 
								
								<div className="uploading" id="uploading">
<div class="ant-upload-list ant-upload-list-picture">
   <div class="">
      <span>
         <div class="ant-upload-list-item ant-upload-list-item-done ant-upload-list-item-list-type-picture">
            <div>
               <div class="ant-upload-list-item-info">
                  <span>
                     <a class="ant-upload-list-item-thumbnail" href="https://atasouthport.com/wp-content/uploads/2017/04/default-image.jpg" target="_blank" rel="noopener noreferrer">
						<img src={this.state.imgSrc} class="ant-upload-list-item-image"/>
					 </a>
					 <span class="ant-upload-list-item-name ant-upload-list-item-name-icon-count-2"><ColorLinearProgress /></span>
                  </span>
               </div>
            </div>
         </div>
      </span>
   </div>
</div>
								</div>
								<div className="file_attach">	
								
									<label for="file_upload"><i class="fa fa-camera" aria-hidden="true"></i></label>								
									<input accept="image/*" capture="camera" id="file_upload" name="file_upload" type="file" onChange={this.onChange}/>
								</div>
							
						</div>
                 </form>

		
<div id="myModal" class="modal">
  <span class="close" onClick={this.handleCancel}>&times;</span>
  <div id="container">
    <div class="zoom">
      <img class="media modal-content" src={this.state.openimgSrc} />
    </div>  
  </div>
</div>
			</div>
			)
	}
}

export default order_medicine;
