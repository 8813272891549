import React, { Component } from 'react';
import {Redirect} from 'react-router-dom';
import {Helmet} from 'react-helmet'
import Slider from 'react-slick';
import {Link} from 'react-router-dom';
import {getListData} from '../../../services/index';

class Account extends Component {
	
	constructor(props) {
        super(props);
		this.state = {
        id: '',
        ListData:[],
	};
	    if(!localStorage.getItem('userData')) {
		   this.props.history.push("login");
        }	
        
		this.goBack = this.goBack.bind(this);
    }
	
    componentDidMount() {
		document.querySelector(".loader-wrapper").style = "display: block";
		setTimeout(function() {
            document.querySelector(".loader-wrapper").style = "display: none";
        }, 900);
		
		let id = this.props.match.params.id;
		this.setState({
					id: id,
				})
		this.checkData();		
		
    }
	
	checkData() {
	
		let id =this.props.match.params.id;
		var app = {
			id : id
		}
		getListData('society/SocietyController/my_profile', app).then((result) => {
			let responseJson = result;
			if (responseJson.data) {
				if (result['status'] == 200) {
					this.setState({
						ListData: result['data'],
						name: result['data'][0]['name'],
					})
				}
			}
		});
		
	}
	
	
		
	goBack(){
		
	}
	
    
    render (){
		
		
        return (
		
		 this.state.ListData.map((list) => (	
		 
            <div>
			<div className="_2u0oK _1iY8Y">
            <form>
            <Link to={`${process.env.PUBLIC_URL}/society_details/${this.state.id}`}>
               <div className="_3TzfO page_head">
                  <div className="_2QN4q">
				  <span id="loader"></span>
                     <div >
                     <img src={`${process.env.PUBLIC_URL}/assets/images/icon/return_white.png`} onClick={this.goBack} alt="" /></div>
                  </div>
                  <div className="_1leRb page_title">
                   My Profile
                  </div>
                  <div className="b5NJg"></div>
               </div>
               <input type="submit" hidden=""/>
              </Link> 
            </form>
         </div>
			
			<div class="_3AFC5">
   <div class="_1bhn9 margin-pd">


            
					<div>
                     <div class="_2czoF society-profile">
						<div class="col-md-12 center">
							<img src={`${process.env.PUBLIC_URL}/assets/images/icon/user.png`} class="no-found-img" />
						</div>
                        <div class="col-md-12 center">
                           <h3>{list.name}</h3>
						   <h4>{list.society_unit}</h4>
                        </div>
                        
                     </div>
                  </div>
						
            

      <div>
         <div class="NeDfT">
            <div class="_3tVyU" id="classic">
               <div class="_39p-f _3lD0G">
                  <div class="_1Xqx7">
					
					<div class="_32sRE">
					 
                        <div class="_2AdD- _2jjDQ">
                           <div class="_2gQbw">
                              <div class="col-md-12 no-padd">
                                 <h4>Email</h4>
								 <h5>{list.email}</h5>
                              </div>
                           </div>
                           <span class="icon-downArrow _1gsD2"></span>
                        </div> 
						
                     </div>
					<div class="_32sRE">
					 
                        <div class="_2AdD- _2jjDQ">
                           <div class="_2gQbw">
                              <div class="col-md-12 no-padd">
                                 <h4>Mobile </h4>
								 <h5>{list.contact}</h5>
                              </div>
                           </div>
                           <span class="icon-downArrow _1gsD2"></span>
                        </div> 
						
                     </div>					 
					
                     <div class="_32sRE">
					 
                        <div class="_2AdD- _2jjDQ">
                           <div class="_2gQbw">
                              <div class="col-md-12 no-padd">
                                 <h4>Present Address</h4>
								 <h5>{list.address}</h5>
                              </div>
                           </div>
                           <span class="icon-downArrow _1gsD2"></span>
                        </div> 
						
                     </div>


                     <div class="_32sRE">
					 
                        <div class="_2AdD- _2jjDQ">
                           <div class="_2gQbw">
                              <div class="col-md-12 no-padd">
                                 <h4>Floor</h4>
								 <h5>{list.floor_no}</h5>
                              </div>
                           </div>
                           <span class="icon-downArrow _1gsD2"></span>
                        </div> 
						
                     </div>
                    


                    
                  </div>
               </div>
            </div>
         </div>
      </div> 
   </div>
   
</div>

            </div>
			))
        )
    }
}

export default Account