import React, { Component } from 'react';
import { Link, NavLink} from 'react-router-dom';
import '../../common/restaurant.scss';
import { connect } from 'react-redux'
import {getListData} from '../../../services/index';
import $ from 'jquery';


class cartComponent extends Component {
    constructor(props) {
        super(props);
		this.state = {
			ListData: [],
			CartData: [],
			itemData: []
		};
		this.goBack = this.goBack.bind(this);
		this.plus_cart = this.plus_cart.bind(this);
		this.minus_cart = this.minus_cart.bind(this);
    }
    handleChange(event) {
		this.setState({
			payment_method: event.target.value
		});
	}
    componentDidMount() {
        document.getElementById("color").setAttribute("href", `#`);
        document.querySelector(".loader-wrapper").style = "display: block";
		setTimeout(function() {
			document.querySelector(".loader-wrapper").style = "display: none";
		}, 900);

        $('.input-group').on('click', '.button-plus', function(e) {
           
        });
        $('.input-group').on('click', '.button-minus', function(e) {
            
        });
		
		this.getData();
	
    }
	
	 getData = () => {
		let restaurant_id = localStorage.getItem("restaurant_id");	
		let cartItems ='0';	
		var storedArray = JSON.parse(localStorage.getItem("userData"));
		this.state.restaurant_id = restaurant_id;		
		var app = {
			restaurant_id: restaurant_id,
			user_id: storedArray['data']['user_id']
		}
		getListData('restaurant/cart_list', app).then((result) => {
			let responseJson = result;
			if (responseJson.data) {
				if (result.data.length > 0) {
					this.setState({
						cartItems:result['count'],
						CartData: result['data'],						
					    itemData: result['data'][0]['items']
					})
				}
				else{
					this.setState({
						cartItems:result['count']
					})
				}
			}
			
		});
    }

    minusQty = (id) => {
        if(this.state.quantity > 1) {
            this.setState({quantity: this.state.quantity - 1});
			this.minus_cart(id);
        }else{
            console.log('removefromcart');			
            this.setState({openQuantity:false});		
			this.minus_cart(id);
        }
    }

    plusQty = (id) => {
        this.setState({quantity: this.state.quantity+1});
		this.plus_cart(id);
    }

    openQuantity = (id) => {
        this.setState({openQuantity:true});
		this.add_cart(id);
    }
	
	minus_cart(id) {
		var storedArray = JSON.parse(localStorage.getItem("userData"));
		let restaurant_id = localStorage.getItem("restaurant_id");	
		this.state.restaurant_id = restaurant_id;		
        var item_id = document.getElementById("item_id"+id).value;
        var item_price = document.getElementById("item_price"+id).value;
        var item_qty = document.getElementById("item_qty"+id).value;
		var app = {
			restaurant_id: restaurant_id,
			user_id: storedArray['data']['user_id'],
			item_id: item_id,
			item_qty: parseInt(item_qty)-1,
			item_price: item_price
		}
		getListData('restaurant/cart_update', app).then((result) => {
			this.getData();
		});
		this.getData();
	}
	
	plus_cart(id) {
		var storedArray = JSON.parse(localStorage.getItem("userData"));
		let restaurant_id = localStorage.getItem("restaurant_id");	
		this.state.restaurant_id = restaurant_id;		
        var item_id = document.getElementById("item_id"+id).value;
        var item_price = document.getElementById("item_price"+id).value;
        var item_qty = document.getElementById("item_qty"+id).value;
		var app = {
			restaurant_id: restaurant_id,
			user_id: storedArray['data']['user_id'],
			item_id: item_id,
			item_qty: parseInt(item_qty)+1,
			item_price: item_price
		}
		getListData('restaurant/cart_update', app).then((result) => {
			this.getData();
		});
		this.getData();
	}
	
	
	goBack(){
		this.props.history.goBack();
	}

render() {

var restaurant_id = localStorage.getItem("restaurant_id");
return (
<div>
   <section className="pb-0 margin-pd mt-45 restro cart-head">
      <div className="_2u0oK _1iY8Y">
         <form>
            <div className="_3TzfO page_head">
               <div className="_2QN4q">
                  
                  
                  <Link to={`${process.env.PUBLIC_URL}/restaurant_details/${restaurant_id}`}>
                     <img src={`${process.env.PUBLIC_URL}/assets/images/icon/return_white.png`} alt="" />
                  </Link>   
                  
               </div>
               <div className="_1leRb page_title">
                  Your Cart
               </div>
               <div className="b5NJg"></div>
            </div>
            <input type="submit" hidden=""/>
         </form>
      </div>
   </section>
   
   
   {this.state.cartItems>0 ?
   <div>
   <div class="no-margin-10">
      <div class="bg-white">
         <div class="pt-50 res-img-padding">
            
			{this.state.CartData.map((list) => (	
			<div>
            <div class="block-content block-content-full">
               <img src={`${list.image}`} class="restaurant-image"/>
            </div>
            <div class="block-content block-content-full restaurant-info">
               <div class="font-w600 mb-5 res-cart-title">{list.restaurant_name} </div>
               <div class="text-muted truncate-text">{list.tag}</div>
            </div></div>
             )
            )}
         </div>
      </div>
      <div class="block-content block-content-full bg-white pt-50 pb-0">
		 {this.state.itemData.map((item) => (	
         <div>
            
            <div class="cart-item-meta pt-15 pb-15">
               <div class="cart-item-name"><img className="order-img2" src={`${process.env.PUBLIC_URL}/assets/images/restaurants/${item.item_type_image}`} alt="" /> {item.item_name}</div>
               <div className="cart-box ">
                  <div className="qty-box">
                     <div className="input-group">
                        <span className="input-group-prepend">
							<button type="button" className="btn quantity-left-minus"  onClick={this.minusQty.bind(this,item.item_id)}>
								<i className="fa fa fa-minus"></i>
							</button>
                        </span>
						<input type="hidden" id={`item_id${item.item_id}`} value={item.item_id}/>
						<input type="hidden" id={`item_price${item.item_id}`} value={item.item_price}/>
                        <input type="text"   id={`item_qty${item.item_id}`} name="quantity" value={item.item_qty} readOnly={true} className="form-control input-number" />
                        <span className="input-group-prepend">
							<button className="btn quantity-right-plus" onClick={this.plusQty.bind(this,item.item_id)} data-type="plus" >
								<i className="fa fa-plus"></i>
							</button>
                        </span>
                     </div>
                  </div>
               </div>
               <div class="cart-item-price">
				    
			   <i className="fa fa-inr" aria-hidden="true"></i>  {item.item_total}</div>
            </div>
         </div>
         ))}
      </div>

   </div>
   <div class="no-margin-10">
   
      <div class="bg-white bill-details mb-20">
         <div class="p-15">
            <h2 class="bill-detail-text m-0">Bill Details</h2>
           
			{this.state.CartData.map((list) => (
			<div>
			 <div class="display-flex">
               <div class="flex-auto">Item Total</div>
               <div class="flex-auto text-right"><i className="fa fa-inr" aria-hidden="true"></i> {list.total}</div>
            </div>
            <div class="display-flex">
               <div class="flex-auto">Total Discount</div>
               <div class="flex-auto text-right">- <i className="fa fa-inr" aria-hidden="true"></i> {list.discount}</div>
            </div>
            <hr/>
            <div class="display-flex">
               <div class="flex-auto">Delivery Charges</div>
               <div class="flex-auto text-right">+ <i className="fa fa-inr" aria-hidden="true"></i> {list.delivery_fee}</div>
            </div>
            <hr/>
            <div class="display-flex">
               <div class="flex-auto font-w700">To Pay</div>
               <div class="flex-auto text-right font-w700"><i className="fa fa-inr" aria-hidden="true"></i> {list.grand_total}</div>
            </div>
			</div>
			))}
         </div>
      </div>
	  <div class="bg-white mb-20 mt-20">
               <div class="p-15">
               <h2 class="bill-detail-text m-0 h-15">Payment Method</h2> 
                  <div class="flex-auto">
                     <label class="no-margin">
                        <input class="mr-2" type="radio" name="payment_method" value="cash_on_delivery" checked={true} onChange={this.handleChange}/>
                          Cash On Delivery
                     </label>
                  </div>
               </div>
      </div>
   </div>
   <div class="no-margin-10">
     <Link to={`${process.env.PUBLIC_URL}/manage_address/${restaurant_id}`}>
   <div className="order-btn">
      <button className="order-button">Select Address</button>
   </div>
   </Link>
   </div>
   </div>
   :
    <section className="cart-section">
        <div className="col-sm-12 empty-cart-cls text-center">
            <img className="img-fluid mb-4 empty-cart" src={`${process.env.PUBLIC_URL}/assets/images/icon/empty_cart.png`} alt="" />
        </div>
    </section>
}
  
   
</div>
)
}
}

export default cartComponent;