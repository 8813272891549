import React, { Component } from 'react';
import { Link, NavLink} from 'react-router-dom';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from 'react-loader-spinner';
import {getListData} from '../../../services/index';
import $ from 'jquery';
import { connect } from 'react-redux'
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import '../../common/restaurant.scss';

class Address extends Component {
	constructor(props) {
		super(props);
		this.state = {
			ListData: [],
			address_id: '',
			CartData: [],
			itemData: [],
			btnvalue : '0',
		};
		if (!localStorage.getItem('userData')) {
			this.props.history.push("login");
		}
		this.add_order = this.add_order.bind(this);
		this.handleChange = this.handleChange.bind(this);
	}

	componentDidMount() {
		let restaurant_id = localStorage.getItem("restaurant_id");	
		this.state.restaurant_id = restaurant_id;
		document.getElementById("color").setAttribute("href", `#`);
		document.querySelector(".loader-wrapper").style = "display: block";
		setTimeout(function() {
			document.querySelector(".loader-wrapper").style = "display: none";
		}, 900);
		var storedArray = JSON.parse(localStorage.getItem("userData"));
		this.state.user_id = storedArray['data']['user_id'];;
		getListData('address/address_list', this.state).then((result) => {
			let responseJson = result;
			if (responseJson.data) {
				if (result['count'] > 0) {
					this.setState({
						ListData: result['data']
					})
					//document.getElementById("loader").style = "display: none";
					document.getElementById("place_order").style = "display: initial";
				}else{
					//document.getElementById("loader").style = "display: none";
					document.getElementById("place_order").style = "display: none";
					document.getElementById('alert').innerHTML='<div class="_2czoF"><div class="_1KevM empty-rest">No Address!</div></div>';
				}
			}
		});

		let cartItems ='0';	
		var storedArray = JSON.parse(localStorage.getItem("userData"));
		this.state.restaurant_id = restaurant_id;		
		var app = {
			restaurant_id: restaurant_id,
			user_id: storedArray['data']['user_id']
		}
		getListData('restaurant/cart_list', app).then((result) => {
			let responseJson = result;
			if (responseJson.data) {
				if (result.data.length > 0) {
					this.setState({
						CartData: result['data'],
						cartItems:result['count'],						
					    itemData: result['data'][0]['items']
					})
				}
				else{
					window.location = '/'
				}
			}
			
		});

	
	}
	
	submit = (event) => {
    confirmAlert({
      title: 'Are you sure, you want to delete?',
      message: '',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
		
		//event.preventDefault();
		var storedArray = JSON.parse(localStorage.getItem("userData"));
		this.state.user_id = storedArray['data']['user_id'];
		this.state.address_id = event;
		var app = {
			user_id: storedArray['data']['user_id'],
			address_id: event,
		}
		getListData('address/delete_address', app).then((result) => {
			let responseJson = result;
			if (responseJson.data) {
				if (result['count'] > 0) {
					window.location = '/manage_address'
				}
			}
		});


	}
        },
        {
          label: 'No',
          onClick: () => {}
        }
      ]
    });
  };
	
	
	handledelete(event) {
		
		//event.preventDefault();
		var storedArray = JSON.parse(localStorage.getItem("userData"));
		this.state.user_id = storedArray['data']['user_id'];
		this.state.address_id = event;
		var app = {
			user_id: storedArray['data']['user_id'],
			address_id: event,
		}
		getListData('address/delete_address', app).then((result) => {
			let responseJson = result;
			if (responseJson.data) {
				if (result['count'] > 0) {
					window.location = '/manage_address'
				}
			}
		});


	}

	handleChange(event) {
		this.setState({
			address_id: event.target.value
		});
	}

	add_order() {
	
		//this.props.onButtonClick(this.state.btnvalue);
		this.setState({ btnvalue: '' });
		
		var storedArray = JSON.parse(localStorage.getItem("userData"));

		let restaurant_id = localStorage.getItem("restaurant_id");	
		this.state.restaurant_id = restaurant_id;
		var address_id = this.state.address_id;
        var item_id = document.getElementsByClassName("item_id");
        var item_name = document.getElementsByClassName("item_name");
        var item_price = document.getElementsByClassName("item_price");
        var item_qty = document.getElementsByClassName("item_qty");
		var item_id_array = '';
		var item_name_array = '';
		var item_price_array = '';
		var item_qty_array = '';
		for(var i=0; i<item_id.length; i++) {
			item_id_array += item_id[i].value+',';
		}
		for(var i=0; i<item_name.length; i++) {
			item_name_array += item_name[i].value+',';
		}
		for(var i=0; i<item_price.length; i++) {
			item_price_array += item_price[i].value+',';
		}
		for(var i=0; i<item_qty.length; i++) {
			item_qty_array += item_qty[i].value+',';
		}
		var no_of_items=item_id.length;
		var delivery_fee=document.getElementById("delivery_fee").value;
		var discount=document.getElementById("discount").value;
		var app = {
			restaurant_id: restaurant_id,
			user_id: storedArray['data']['user_id'],
			delivery_fee: delivery_fee,
			discount: discount,
			customer_name: storedArray['data']['name'],
			phone: storedArray['data']['mobile'],
			address_id: address_id,
			payment_type: 'cash',
			no_of_items: '1',
			transaction_id: Date.now(),
			menu_id: '0',
			item_id: item_id_array,
			item_name: item_name_array,
			item_qty: item_qty_array,
			item_cost: item_price_array
		}
		if(address_id!=''){
		getListData('restaurant/order_add', app).then((result) => {
		if (result.status == 200) {
			var order_id=result.data['order_id'];
			localStorage.setItem("order_id",'');
			localStorage.setItem('order_id',order_id);
			window.location = '/track_order'
		}
		});
		}
		else{
			confirmAlert({
			title: 'Please select delivery address',
			message: '',
			buttons: [
			{
				label: 'Ok',
				onClick: () => {
	
				}
			}
			]
		});
	}
	}
	
	render() {

		return (
			<div>
                <div>
				{this.state.itemData.map((item, index) => {
                return (
				<div key={index}>
					<input type="hidden" class="item_name" name="item_name[]" value={item.item_name} />
					<input type="hidden" class="item_id" name="item_id[]" value={item.item_id} />
					<input type="hidden" class="item_price" name="item_price[]" value={item.item_price} />
					<input type="hidden" class="item_qty" name="item_qty[]" value={item.item_qty} />
				</div>
				)
				})}
			
			{this.state.CartData.map((list) => (
			<div>
			<input type="hidden" id="discount" name="discount" value={list.discount} />
			<input type="hidden" id="delivery_fee" name="delivery_fee" value={list.delivery_fee} />
			</div>
			 )
            )}
				</div>
                <section className="pb-0 margin-pd mt-45 restro">
					<div className="_2u0oK _1iY8Y">
						<form>
						   <div className="_3TzfO page_head">
							  <div className="_2QN4q">
								 <Link to={`${process.env.PUBLIC_URL}/restaurant_order`}>
								 <img src={`${process.env.PUBLIC_URL}/assets/images/icon/return_white.png`} alt="" /></Link>
							  </div>
							  <div className="_1leRb page_title">
								 Select Address
							  </div>
							  <div className="b5NJg"></div>
						   </div>
						   <input type="submit" hidden=""/>
						</form>
					 </div>
				    <div className="add-back">
						<div className="_14v1V center">
								<div className="nowrap add-title left">Saved Address</div>
						</div>
					</div>
					<div id="alert" className="m-b-10"></div>
                </section>
				
				 {/*collection banner*/}
                <section className="pb-0 margin-pd restro details-pad-top-n">
					{this.state.ListData.map((list) => (
                    <div className="add-details details-padding" role="button" tabIndex="0">
					<label className="container" for={list.id}>
						<div className="res-details-info col-md-12">
							<div className="col-md-1 no-pad details-title radio">							
							<input type="radio" name="address_id" value={list.id} id={list.id}  checked={this.state.address_id === list.id} onChange={this.handleChange}  />
							<span className="checkmark"></span>							
							</div>
							<div className="col-md-11 no-pad">
								<div className="_14v1V">
									<div className="nowrap details-title">{list.tag}</div>
								</div>
								<div className="details-dish-con">
									<div className="">
										<div className="details-dish-title details-color">{list.flat_no}, {list.location}</div>
										<div className="details-dish-title details-color">{list.land_mark}</div>
		
										 <Link to={`${process.env.PUBLIC_URL}/edit_address/${list.id}`}><div className="col-md-3 no-pad add-edit bold"> Edit</div></Link>
										<div className="col-md-3 no-pad add-edit bold" onClick={this.submit.bind(this,list.id)}> Delete</div>
									</div>
								  </div>
							</div>	  
						</div>
						</label>
					</div>
					))}
					
                </section>
				 <section className="pb-0 margin-pd restro details-pad-top-n">
					<div className="order-btn">
						<Link to={`${process.env.PUBLIC_URL}/add_address`}>
							<button className="add-button">Add new Address</button>
						</Link>
					</div>
					<div className="order-btn">
				
						<button className="order-button" id="place_order" onClick={this.add_order}  disabled={!this.state.btnvalue}>
							<span className="order-conf2">Place & Confirm Order</span>
						</button >
			
						
					</div>
                </section>
				


 
			</div>
			
			)

	}
}

export default Address;

