import React, { Component } from 'react';

class Home extends Component {
	constructor(props) {
		super(props);
		if (!localStorage.getItem('userData')) {
			this.props.history.push("login");
		}
	}
	componentDidMount() {
		setTimeout(function () {
			document.querySelector(".loader-wrapper").style = "display: none";
		}, 2000);
		
		if (localStorage.getItem('userData')) {
			
			var storedArray  = JSON.parse(localStorage.getItem("userData"));
			this.state.user_id = storedArray['data']['user_id'];
			
		
		}

	}


render() {
return (
	<div>
  
	</div>
)
}
}
export default Home;